<template>
    <div class="flex flex-row">
        <Sidebar />
        <div>
            <Navbar />
            <div class="main-page">
                <div class="flex flex-row">
                    <div
                        v-for="(item, index) in projectItems"
                        :key="index"
                        class="mr-3"
                        v-on:click="makeActive(item.key)"
                    >
                        <div
                            class="flex flex-row mb-0 page-title title-proj"
                            :class="{
                                'typeProject-active':
                                    showComponent === item.key,
                            }"
                        >
                            {{ $t(item.label) }}
                            <div class="box-count">
                                {{ item.counter }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between gap-1 py-4">
                    <div class="w-full">
                        <div v-show="active('projects')" v-if="!uploading">
                            <AllProjects />
                        </div>
                        <div
                            class="w-full h-[60vh] flex items-center justify-center"
                            v-if="uploading"
                        >
                            <Spinner />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalErrorPtr />
        <ConfirmDelete />
    </div>
</template>
<script>
import { store } from "@/store";
import Sidebar from "../Sidebar.vue";
import Navbar from "../Navbar.vue";

import Icon from "@/containers/UI/Icon.vue";

import Spinner from "@/containers/UI/Spinner.vue";
import AllProjects from "@/components/pathProjects/projects/ListProjects.vue";
import AllProposals from "@/components/pathProjects/proposal_submitted/ListProposals.vue";
import AllChangeRequests from "@/components/pathProjects/change_requests/AllChangeRequests.vue";

import ModalErrorPtr from "@/components/modal/ModalErrorPtr.vue";
import ConfirmDelete from "@/components/modal/ConfirmDelete.vue";

export default {
    components: {
        Sidebar,
        Navbar,
        Icon,
        Spinner,
        AllProjects,
        AllProposals,
        AllChangeRequests,
        ModalErrorPtr,
        ConfirmDelete,
    },
    data() {
        return {
            uploading: false,
            store,
            showComponent: "projects",

            projectItems: [
                {
                    key: "projects",
                    label: "projects",
                    counter: 0,
                },
            ],
        };
    },
    created() {
        store.position = "projects";

        this.emitter.on("counter projects", (val) => {
            this.projectItems[0].counter = val;
        });
        this.emitter.on("counter proposals", (val) => {
            this.projectItems[1].counter = val;
        });
        this.emitter.on("counter changeReq", (val) => {
            this.projectItems[2].counter = val;
        });
    },
    methods: {
        active: function (val) {
            return this.showComponent === val;
        },
        makeActive: function (val) {
            this.showComponent = val;
        },
    },
};
</script>
