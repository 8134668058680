<template>
    <div>
        <div v-show="active('CurrentBudget')">
            <CurrentBudget :expenses="this.expenses" />
        </div>
        <!-- <div v-show="active('executing_team')">
            <ExecutingTeam :info="project.team" />
        </div> -->
    </div>
</template>
<script>
import CurrentBudget from "./budgetBtn/CurrentBudget.vue";

import ExecutingTeam from "../compProject/ExecutingTeam.vue";
export default {
    props: {
        expenses: Array,
    },
    components: {
        CurrentBudget,
        ExecutingTeam,
    },
    data() {
        return {
            showComponent: "CurrentBudget",
        };
    },
    methods: {
        active: function (val) {
            return this.showComponent === val;
        },
        makeActive: function (val) {
            this.showComponent = val;
        },
    },
};
</script>
