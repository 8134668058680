<template>
    <div class="flex flex-row">
        <Sidebar />
        <div>
            <div class="over-page">
                <Navbar />
            </div>
            <div class="overflow-auto main-page">
                <div class="content-page">
                    <div class="flex flex-col">
                        <div class="flex flex-row items-center">
                            <div class="page-title">
                                {{ $t("register_ptr") }}
                            </div>
                        </div>
                        <div class="flex flex-row justify-between my-3 mb-6">
                            <label for="file" class="my-1 btn-default"
                                >{{ $t("file_upload") }}
                                <input
                                    type="file"
                                    id="file"
                                    ref="fileInput"
                                    class="hidden"
                                    @change="handleFileChange"
                                    accept=".xls, .xlsx"
                                />
                            </label>
                            <div class="flex flex-row items-center">
                                <div class="my-1 btn-default">
                                    {{ $t("cancel") }}
                                </div>
                                <div
                                    class="my-1 btn-default btn-master"
                                    @click="saveDocument"
                                >
                                    {{ $t("save") }}
                                </div>
                            </div>
                        </div>
                        <div v-if="!uploading">
                            <PtrForm :key="componentKey" />
                        </div>
                        <div
                            class="w-full h-[60vh] flex items-center justify-center"
                            v-if="uploading"
                        >
                            <Spinner />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddFilePtr />
    </div>
</template>
<script>
import { store } from "@/store";
import Sidebar from "@/containers/Sidebar.vue";
import Navbar from "@/containers/Navbar.vue";
import Spinner from "@/containers/UI/Spinner.vue";

import AddFilePtr from "@/components/modal/AddFilePtr.vue";

import PtrForm from "./ptrForm/mainPtr.vue";
import axios from "axios";

export default {
    components: { Sidebar, Navbar, PtrForm, Spinner, AddFilePtr },
    data() {
        return {
            uploading: false,
            store,
            authHeader: store.getAuthHeader(),
            ptrDatas: [],
            componentKey: 0,
        };
    },
    created() {
        store.position = "register_ptr";
    },
    methods: {
        async handleFileChange(event) {
            this.uploading = true;

            const authHeader = store.getAuthHeader();

            if (event.target.files.length > 1) {
                this.$toast.warning("Only one file can be uploaded at a time.");
                return;
            }

            const file = event.target.files[0];
            const formData = new FormData();

            formData.append("file", file);
            let res;
            try {
                res = await axios.post(
                    `${store.BACKEND_URL}/api/projects/upload/`,
                    formData,
                    { withCredentials: false, headers: authHeader }
                );
            } catch (e) {
                this.$toast.error("Something went wrong uploading file.");
                console.error(e);
                return;
            }

            if (res.status == 201) {
                this.getProjectPartial(res.data.id);
            }

            if (res.status != 201) {
                this.$toast.error("Something went wrong uploading file.");
                return;
            }
        },
        async getProjectPartial(id) {
            try {
                const authHeader = store.getAuthHeader();

                const res = await axios.get(
                    `${store.BACKEND_URL}/api/projects/${id}/`,
                    { withCredentials: false, headers: authHeader }
                );
                if (res.status != 200) {
                    this.$toast.error(
                        "Something went wrong retrieving project information."
                    );
                    return;
                }
                localStorage.setItem("ptrDatas", JSON.stringify(res.data));
                this.reloadComponent();
            } catch (err) {
                console.log(err);
            }
        },
        reloadComponent() {
            this.componentKey += 1;
            this.uploading = false;
        },
        async saveDocument() {
            const ptrDocument = JSON.parse(localStorage.getItem("ptrDatas"));
            if (!ptrDocument) {
                this.$toast.warning("No document to save.");
                return;
            }

            this.uploading = true;
            try {
                const response = await axios.post(
                    `${store.BACKEND_URL}/api/projects/partial/`,
                    ptrDocument,
                    {
                        withCredentials: false,
                        headers: this.authHeader,
                    }
                );

                if (response.status === 201) {
                    this.$toast.success("Documento salvo com sucesso.");
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
