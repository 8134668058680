<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{ $t("expenses_with_consumable_materials") }}
            </div>
            <div v-for="(item, index) in materialExpenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("material_to_be_acquired") }}
                            <sup>max 80 {{ $t("characters") }}</sup></label
                        >
                        <input
                            type="text"
                            class="sty-input"
                            maxlength="80"
                            v-model="item.material"
                        />
                        <small
                            >{{ store.remainingCharacters(item.material, 80) }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("origin")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.origin"
                        >
                            <option value="Nacional">
                                {{ $t("national") }}
                            </option>
                            <option value="Importado">
                                {{ $t("imported") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.value"
                            @input="onInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup class="normal-case"
                                >300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="deleteMaterialExpenses(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="my-4" @click="addMaterial">
                <div class="w-48 btn-default">{{ $t("add_expense") }}</div>
            </div>
            <div class="flex flex-col">
                <label for="total" class="label-ptr">{{
                    $t("total_value_in_expenses_with_consumable_materials")
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    disabled
                    v-model="totalValue"
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";
export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            materialExpenses: [
                {
                    company_name: "",
                    material: "",
                    origin: "",
                    justification: "",
                    value: null,
                },
            ],
            totalValue: "",

            loading: false,
        };
    },

    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (data != null && data.hasOwnProperty("consumables_expenses")) {
            this.materialExpenses = data.consumables_expenses.map(
                (expense) => ({
                    ...expense,
                    origin: this.store.formatText(expense.origin),
                    value: this.store.formatMoney(expense.value),
                })
            );
            this.updateTotalValue();
        }
    },
    methods: {
        addMaterial() {
            this.materialExpenses.push({
                company_name: "",
                material: "",
                origin: "",
                justification: "",
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;
            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};

            parsedData.consumables_expenses = this.materialExpenses.map(
                (act) => {
                    return {
                        ...act,
                        value: this.store.formatValueToSave(act.value),
                    };
                }
            );
            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },

        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.materialExpenses[index]) {
                this.materialExpenses[index].value = formattedValue;
            }
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.materialExpenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        deleteMaterialExpenses(index) {
            this.materialExpenses.splice(index, 1);
            this.updateTotalValue();
        },
    },
};
</script>
