<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-bg" v-if="openModal">
            <div class="card-modal-error fadeIn">
                <div class="flex justify-end">
                    <div @click="closeModal">
                        <Icon type="x-circle" class="w-6 h-6 cursor-pointer" />
                    </div>
                </div>
                <div class="my-1 separete"></div>
                <div
                    class="mt-4 mb-8 flex justify-center flex-col items-center"
                >
                    <Icon type="info" class="w-12 h-12 text-red-500" />
                    <div class="font-medium text-2xl my-2">
                        {{ $t("an_error_occurred_while_adding_the_project") }}
                    </div>
                </div>
                <div
                    v-if="info != 'string'"
                    class="my-4"
                    style="
                        flex: 1;
                        overflow-y: auto;
                        width: 100%;
                        max-height: 80vh;
                    "
                >
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Local
                                </th>
                                <th
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Mensagem
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr
                                v-for="error in errors"
                                :key="error.sheet + error.msg"
                            >
                                <td
                                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                >
                                    {{ error.sheet }}
                                </td>
                                <td
                                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                >
                                    {{ error.msg }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <div class="my-4 text-center">
                        {{ errors }}
                    </div>
                </div>
                <div class="flex justify-end mt-4">
                    <div
                        class="btn-default btn-clear mx-0"
                        @click="openModal = false"
                    >
                        {{ $t("close") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
export default {
    components: {
        Icon,
    },
    data() {
        return {
            openModal: false,
            description: "",
            maxLength: 100,
            file: null,
            errors: [],
        };
    },
    computed: {
        remainingCharacters() {
            return this.maxLength - this.description.length;
        },
    },
    created() {
        this.emitter.on("openModalError", (val) => {
            this.info = "";

            this.openModal = val[0];
            if (typeof val[1] === "string") {
                this.info = "string";
                this.errors = val[1];
            } else {
                this.errors = val[1];
            }
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        handleFileUpload(event) {
            this.file = event.target.files[0];
        },
        addFile() {
            const document = {
                document: this.file,
                description: this.description,
            };

            this.emitter.emit("sendDocumentPtr", document);

            this.description = "";
            this.info = "";
            this.openModal = false;
        },
    },
};
</script>
<style>
.card-modal-error {
    border: 1px solid red;
}

.txt-error {
    color: rgb(122, 12, 12);
    font-weight: bold;
}

.txt-message-error {
    font-size: 26px;
    font-weight: bold;
    color: rgb(122, 12, 12);
}

.txt-reason {
    font-size: 24px;
    font-weight: 600;
    color: rgb(122, 12, 12);
}

.txt-possible {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
    color: rgb(0, 0, 0);
}
</style>
