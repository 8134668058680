<template>
    <div>
        <div class="mb-8">
            <div class="flex flex-row justify-between">
                <div class="my-2 font-bold">
                    {{ $t("consolidated_budget") }}
                </div>
            </div>
        </div>
        <div class="bg-header-tbl">
            <div class="flex flex-row justify-between">
                <div class="w-2/3">{{ $t("category") }}</div>
                <div class="flex flex-row w-1/5">
                    <div class="w-1/2">{{ $t("quantity") }}</div>
                    <div class="w-1/2 mr-4">{{ $t("value") }}</div>
                </div>
            </div>
        </div>
        <div class="border-tbl font-normal mb-4 cursor-default"
            @click="item.amount > 0 && (item.isActive = !item.isActive)" :class="{ 'cursor-pointer': item.amount > 0 }"
            v-for="(item, index) in currentExpenses" :key="index">
            <div class="flex flex-row justify-between p-2.5 header-option"
                :class="{ 'header-option-selected': item.isActive }">
                <div class="w-2/3">{{ $t(item.categoryName) }}</div>
                <div class="flex flex-row w-1/5">
                    <div class="w-1/2">{{ item.amount }}</div>
                    <div class="w-1/2">{{ store.formatMoney(item.value) }}</div>
                    <div class="flex items-center">
                        <Icon :type="item.isActive ? 'arrow-up' : 'arrow-down'" :class="item.amount > 0
                            ? 'w-4 h-4 cursor-pointer'
                            : 'mr-5'
                            " />
                    </div>
                </div>
            </div>
            <table class="min-w-full" v-if="item.isActive" :colspan="expensesRequested.length">
                <thead class="bg-header-option uppercase">
                    <tr>
                        <th class="w-32 font-normal">
                            {{ $t("institution_company") }}
                        </th>
                        <th class="w-24 font-normal">{{ $t("type") }}</th>
                        <th class="w-32 font-normal">{{ $t("origin") }}</th>
                        <th class="w-36 font-normal">
                            {{ $t("description") }}
                        </th>
                        <th class="w-[19%] font-normal mb-2">
                            {{ $t("justification") }}
                        </th>
                        <th class="w-48 font-normal">
                            {{ $t("unit_price") }} (R$)
                        </th>
                        <th class="w-32 font-normal">{{ $t("quantity") }}</th>
                        <th class="w-32 font-normal">
                            {{ $t("total_value") }} (R$)
                        </th>
                    </tr>
                </thead>
                <tbody class="text-sm divide-y divide-gray-300">
                    <tr v-for="(it, index) in item.items" :key="index" class="">
                        <td class="py-3">{{ it.company_name }}</td>
                        <td class="py-3">{{ it.material }}</td>
                        <td class="py-3">{{ it.origin }}</td>
                        <td class="py-3">{{ it.description }}</td>
                        <td class="py-3">{{ it.justification }}</td>
                        <td class="py-3">{{ store.formatMoney(it.value) }}</td>
                        <td class="py-3">{{ it.quantity }}</td>
                        <td class="py-3">
                            {{ store.formatMoney(it.value * 1) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        Icon,
    },
    props: {
        info: Array,
        expenses: Object,
    },
    data() {
        return {
            store,
            expensesRequested: [],
            currentExpenses: [],

            expandedInfo: null,
            openModal: true,
            categorySelected: [],
            totalValue: "",
            valueTotalCurrent: "",
            allGategories: "",
        };
    },
    async created() {
        this.handleCurrentExpenses();

        this.updateTotalValue();
        this.updateTotalValueCurrent();
    },

    computed: {
        calculaTotal() {
            return this.expensesRequested.reduce(
                (total, ds) => total + parseFloat(ds.value),
                0
            );
        },
        calculaTotalCorrent() {
            return this.currentExpenses.reduce(
                (total, ds) => total + parseFloat(ds.value),
                0
            );
        },
        calculateGrandTotal() {
            const totalGeral = this.calculaTotal + this.calculaTotalCorrent;
            return totalGeral;
        },
    },
    methods: {
        isCapital(category) {
            return false;
        },
        handleCurrentExpenses() {
            const categoryToNameMap = {
                service_expenses: "description",
                prototype_expenses: "description",
                construction_and_installation_expenses: "description",
                costs_expenses: "description",
                importation_expenses: "description",
                supply_capacity_expenses: "description",
                equipment_expenses: "equipment",
                rights_and_properties_expenses: "type",
                scholarship_expenses: "type",
                taxes_expenses: "tax",
                allowance_expenses: "activity",
                tests_expenses: "activity",
                specific_expenses: "expense",
            };

            const filteredCategories = store.expensesCategories.filter(
                (category) => {
                    return !this.isCapital(category);
                }
            );

            let data = {};
            for (const category of filteredCategories) {
                let totalValue = 0;

                for (const expense of this.expenses[category]) {
                    totalValue += expense.value;
                }
                this.expenses[category].forEach((element) => {
                    element["description"] =
                        element[categoryToNameMap[category]];
                });

                data[category] = {
                    categoryName: category,
                    items: this.expenses[category],
                    value: totalValue,
                    amount: this.expenses[category].length,
                    quantities: [],
                };
            }

            this.currentExpenses = Object.values(data);
        },
        getRow(dsId) {
            for (const expense of this.currentExpenses) {
                if (expense.id === dsId.id) {
                    expense.isActive = !expense.isActive;
                } else {
                    expense.isActive = false;
                }
            }
        },
        getRowExpenses(dsId) {
            for (const expense of this.expensesRequested) {
                if (expense.id === dsId.id) {
                    expense.isActive = !expense.isActive;
                } else {
                    expense.isActive = false;
                }
            }
        },
        openRegisterPTR() {
            this.$router.push("/form");
        },
        formatCurrency(value) {
            if (!value) return "";
            value = value.replace(/\D/g, "");
            let numberValue = parseInt(value) / 100;
            return numberValue.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },
        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.expensesRequested[0]) {
                this.expensesRequested[0].quantities[index].value =
                    formattedValue;
            }
            this.updateTotalValue();
        },
        updateTotalValue() {
            if (this.expensesRequested[0]) {
                let total = this.expensesRequested[0].quantities.reduce(
                    (sum, item) => {
                        return sum + this.store.parseCurrency(item.value);
                    },
                    0
                );

                this.totalValue = this.store.formatCurrency(total.toString());
            }
        },
        onInputCurrent(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.currentExpenses[0]) {
                this.currentExpenses[0].quantities[index].value =
                    formattedValue;
            }
            this.updateTotalValueCurrent();
        },
        updateTotalValueCurrent() {
            let total = this.currentExpenses[0].quantities.reduce(
                (sum, item) => {
                    return sum + this.store.parseCurrency(item.value);
                },
                0
            );
            this.valueTotalCurrent = this.store.formatCurrency(
                total.toString()
            );
        },
    },
};
</script>
<style>
.size-qtd {
    width: 20%;
}

.last-item {
    border-bottom: 1px solid rgb(17, 17, 17) !important;
}

.bg-header-tbl {
    background: #e1dfe2;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    color: #484649;
    margin-bottom: 1rem;
}

.border-tbl {
    border: 1px solid #cbc9cc;
    border-radius: 10px;
}

.header-option {
    background-color: #f3f0f3;
    border-radius: 10px;
    color: #484649;
}

.header-option-selected {
    background-color: #c0d0d8;
    color: #484649;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #f3f0f3;
    font-weight: bold;
}

.info-options {
    background-color: white;
    border-radius: 0 0 10px 10px;
}

.bg-header-option {
    background-color: #e1dfe2;
    padding: 10px;
    font-size: 14px;
}

.bg-header-option tr th {
    text-align: start !important;
    padding: 10px;
}

td {
    vertical-align: sub;
}
</style>
