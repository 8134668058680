<template>
    <div>
        <div class="grid py-3 my-3">
            <div>
                <div class="flex flex-col">
                    <div class="value-form">{{ $t("team") }}</div>
                </div>
            </div>
        </div>
        <tblTeam :team="info" />
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";

import tblTeam from "@/components/tables/tbl_executing_team.vue";
export default {
    props: {
        info: Object,
    },
    components: {
        Icon,
        tblTeam,
    },
};
</script>
