<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { store } from "@/store/index";
import style from "@/assets/css/style.vue";
export default {
    components: { style },
    data() {
        return {
            store,
            containerWidth: "calc(100% - 230px)",
            containerLeft: "230px",
            containerNavbar: "calc(100% - 230px)",
        };
    },
    created() {
        this.emitter.on("resizeBody", (resize) => {
            if (!resize) {
                this.containerWidth = "calc(100% - 80px)";
                this.containerLeft = "80px";
                this.containerNavbar = "calc(100% - 80px)";
            } else {
                this.containerWidth = "calc(100% - 230px)";
                this.containerLeft = "230px";
                this.containerNavbar = "calc(100% - 230px)";
            }
        });
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    methods: {
        handleResize() {
            if (window.innerWidth < 1024) {
                this.containerWidth = "calc(100% - 80px)";
                this.containerNavbar = "calc(100% - 80px)";
                this.containerLeft = "80px";
            } else {
                this.containerWidth = "calc(100% - 230px)";
                this.containerNavbar = "calc(100% - 230px)";
                this.containerLeft = "230px";
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>
<style>
@import url("./assets/css/tailwind.css");

.navbar {
    position: fixed;
    width: v-bind(containerNavbar);
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    background-color: v-bind("store.colors.bgPage");
    z-index: 2;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    height: 67px;
    right: 0;
    transition: all 0.5s;
}

.main-page {
    /* top: 67px; */
    width: v-bind(containerWidth);
    height: 100vh;
    position: fixed;
    left: v-bind(containerLeft);
    overflow: scroll;
    transition: all 0.5s;
    top: 67px;
    padding: 0 20px;
}
.teste {
    overflow-y: scroll !important;
}
.card-add,
.card-add-check {
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    color: v-bind("store.colors.txtBtnDefault");
    font-weight: 600;
    background-color: v-bind("store.colors.btnDefault");
    border: 1px solid v-bind("store.colors.borderInput");
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-add-check {
    background-color: transparent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.card-show-act {
    color: v-bind("store.colors.txtBtnDefault");
    border: 1px solid v-bind("store.colors.borderInput");
    padding: 10px;
    border-radius: 4px;
    background-color: rgb(252, 252, 252);
}

.btn-default {
    width: 150px;
    margin: 0 10px 0 0;
    text-align: center;
    padding: 10px 0;
    border-radius: 4px;
    color: v-bind("store.colors.txtBtnDefault");
    font-weight: 500;
    cursor: pointer;
    background-color: v-bind("store.colors.btnDefault");
    border: 1px solid v-bind("store.colors.borderInput");
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-default:hover {
    background-color: #c0d0d8;
    color: #155e75;
    border: 1px solid #155e75;
}

.btn-delete {
    background-color: #fecaca;
    border: 1px solid #ff1a1a;
    color: #ff1a1a;
}

.btn-delete:hover,
.btn-hover-delete:hover {
    background-color: #ff7b7b;
    border: 1px solid #b10000;
    color: #b10000;
}

.sty-input {
    background-color: transparent;
    color: v-bind("store.colors.mainText");
    padding: 10px 15px;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    border: 1px solid transparent;
    transition: 0.4s;
    border: 1px solid v-bind("store.colors.borderInput");
}

.sty-input:focus {
    background-color: v-bind("store.colors.hover50");
}

.color-icon {
    color: v-bind("store.colors.txtDescription");
}

.color-icon:hover {
    color: v-bind("store.colors.mainText");
}

::-webkit-scrollbar-thumb {
    background: v-bind("store.colors.btnDefault");
    border-radius: 7px;
}

.page-title {
    font-size: 25px;
    margin-bottom: 10px;
    color: v-bind("store.colors.mainText");
    font-weight: bold;
}

.box-default {
    background-color: transparent;
    padding: 20px;
    color: v-bind("store.colors.mainText");
    border-radius: 8px;
    border: 1px solid v-bind("store.colors.borderInput");
}

.size-box-section {
    height: 60vh;
    overflow: auto;
}

.txt-default {
    color: v-bind("store.colors.mainText");
}

.card-project {
    border: 1px solid v-bind("store.colors.borderInput");
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    font-size: 14px;
    max-height: 480px;
}

.project-description {
    color: v-bind("store.colors.mainText");
    display: block;
    display: -webkit-box;
    font-size: 20dp;
    line-clamp: 6;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: auto;
    margin: 0;
    text-overflow: ellipsis;
    height: 25vh;
}

.separete {
    border-top: 1px solid v-bind("store.colors.borderInput");
}

.b-tbody {
    border: 1px solid v-bind("store.colors.borderInput");
    border-top: 1px solid #155e75;
}

.size-td {
    color: v-bind("store.colors.mainText");
    max-width: 150px;
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
}

.pagination:hover {
    background-color: #75acbd;
}

.topic-main {
    border-radius: 7px;
    padding: 8px 10px;
    margin: 4px 10px;
    color: v-bind("store.colors.mainText");
}

.option-collapse:hover,
.hover-td:hover {
    background-color: v-bind("store.colors.hoverTd");
    cursor: pointer;
}

.comp-active {
    font-weight: bold;
    color: rgb(58, 58, 58) !important;
    background-color: v-bind("store.colors.hoverTd");
}

.option-collapse {
    padding: 8px 20px;
    color: v-bind("store.colors.mainText");
    border-radius: 7px;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.brand-ideation {
    text-align: flex-start;
    font-size: 50px;
    font-weight: bold;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #002743;
}

.box-login {
    background-color: transparent;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    padding: 20px 60px 40px;
    border: 1px solid v-bind("store.colors.borderInput");
}

.sty-label {
    color: v-bind("store.colors.mainText");
    padding: 5px 0;
    font-weight: 600;
}

.sty-input-form {
    border-radius: 7px;
    padding: 10px 10px;
    outline: none;
    color: v-bind("store.colors.mainText");
    border: 1px solid v-bind("store.colors.borderInput");
}

.stay-connect {
    color: v-bind("store.colors.mainText");
}

.bg-profile {
    background-color: v-bind("store.colors.background200");
    padding: 7px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.box-count-steps {
    padding: 1px 10px;
    border-radius: 5px;
    color: #155e75;
    font-weight: bold;
    background-color: v-bind("store.colors.btnDefault");
    border: 1px solid v-bind("store.colors.borderInput");
}

.bg-language {
    position: absolute;
    top: 50px;
    right: 50px;
    border-radius: 5px;
    background-color: v-bind("store.colors.btnDefault");
}

.txt-title-proj {
    color: v-bind("store.colors.mainText");
    width: 285px;
}

.text-description {
    font-size: 14px;
    color: v-bind("store.colors.txtDescription");
    font-style: italic;
}

.label-ptr {
    font-weight: 600;
    color: v-bind("store.colors.txtDescription");
}

.box-avatar {
    background-color: v-bind("store.colors.btnDefault");
}

.options {
    margin: 2px 6px;
    border-radius: 7px;
    cursor: pointer;
    padding: 6px 10px;
    color: v-bind("store.colors.mainText");
}

.options:hover {
    background-color: v-bind("store.colors.hover50");
}

.all-box {
    top: 58px;
    border: 1px solid v-bind("store.colors.border150");
    border-radius: 7px;
}

.box-lang {
    padding: 5px 15px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid v-bind("store.colors.borderInput");
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.box-option {
    border: 1px solid v-bind("store.colors.borderInput");
    border-radius: 0 0 5px 5px;
    cursor: pointer;
    background-color: white;
}

.option-flag:hover {
    background-color: v-bind("store.colors.btnDefault");
}

.btn-card {
    text-align: center;
    padding: 10px 0;
    border-radius: 4px;
    color: v-bind("store.colors.txtBtnDefault");
    font-weight: 500;
    cursor: pointer;
    background-color: v-bind("store.colors.btnDefault");
}

.btn-card:hover {
    background-color: v-bind("store.colors.hoverBtnDefault");
}

.box-return:hover {
    background-color: v-bind("store.colors.hoverBtnDefault");
}

.box-return {
    border: 1px solid v-bind("store.colors.borderInput");
    border-radius: 4px;
    padding: 3px;
    cursor: pointer;
}

.title-form {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: #605d62;
    font-size: 16px;
}

.value-form {
    color: #1c1b1f;
    font-size: 16px;
    font-weight: 600;
}
.select-responsive,
.select-responsive-proj {
    width: 65.5%;
    border: 1px solid #164e63;
    border-radius: 7px;
    margin: 3px 0;
    position: absolute;
    z-index: 999;
    background-color: white;
    padding: 5px 7px;
}
.select-responsive-proj {
    width: 40%;
    right: 85px;
}
.border-selected {
    border: 1px solid #164e63;
}
</style>
