<template>
    <div class="txt-titulo">{{ $t("framing_potential") }}</div>
    <div class="flex flex-row justify-evenly">
        <div class="grid grid-cols-2 gap-2">
            <div class="donuts">
                <div class="flex items-center justify-center content-percent">
                    <span class="percents"
                        >{{ ictPotencial }}%
                        <span class="subtitle">ICT</span></span
                    >
                </div>
                <Doughnut
                    id="my-chart-ict"
                    :options="chartOptions"
                    :data="ictChartData"
                />
            </div>
            <div class="donuts">
                <div class="flex items-center justify-center content-percent">
                    <span class="percents"
                        >{{ companyPotential }}%
                        <span class="subtitle">{{
                            $t("companies")
                        }}</span></span
                    >
                </div>
                <Doughnut
                    id="my-chart-id2"
                    :options="chartOptions"
                    :data="companyChartData"
                />
            </div>
        </div>
    </div>
    <div class="description">
        <span>{{ $t("ict_mean") }}: {{ ictPotencial }}%</span>
        <span>{{ $t("company_mean") }}: {{ companyPotential }}%</span>
    </div>
</template>

<script>
import { store } from "@/store";
import axios from "axios";

import { Doughnut } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default {
    components: { Doughnut, Doughnut },
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            ictPotencial: 95,
            companyPotential: 95,
            chartOptions: {
                responsive: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    filler: {
                        propagate: true,
                    },
                    datalabels: {
                        display: false,
                    },
                },
                cutout: "70%",
                radius: "100%",
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        display: false,
                    },
                },
            },
        };
    },

    beforeCreate() {
        this.emitter.on("updateGraph", (value) => {
            if (this.selectedProjects.includes(value)) {
                const idx = this.selectedProjects.indexOf(value);
                this.selectedProjects.splice(idx, 1);
            } else {
                this.selectedProjects.push(value);
            }
        });
    },

    async beforeMount() {
        await this.getFramingPotential();
        this.loaded = true;
    },

    computed: {
        ictChartData() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            let ictPotential = 0;
            for (const project of filteredProjects) {
                ictPotential += project.ict_framing;
            }
            ictPotential /= filteredProjects.length;

            this.ictPotencial = Math.round(ictPotential);
            return {
                datasets: [
                    {
                        label: "ICT Potential",
                        backgroundColor: ["#E1DFE2", "#155E75"],
                        data: [100 - ictPotential, ictPotential],
                        borderWidth: 0,
                        borderRadius: 6,
                    },
                ],
            };
        },

        companyChartData() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            let companyPotential = 0;
            for (const project of filteredProjects) {
                companyPotential += project.company_framing * 100;
            }
            companyPotential /= filteredProjects.length;

            this.companyPotential = Math.round(companyPotential);
            return {
                datasets: [
                    {
                        backgroundColor: ["#E1DFE2", "#06B6D4"],
                        data: [100 - companyPotential, companyPotential],
                        borderWidth: 4,
                        borderRadius: 6,
                    },
                ],
            };
        },
    },

    methods: {
        async getFramingPotential() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length != 0) {
                return;
            }

            const res = await axios.get(
                `${store.BACKEND_URL}/api/statistics/framing-potential/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );

            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving framing potential information."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
        },
    },
};
</script>
