<template>
    <div>
        <div class="flex flex-row items-center justify-between">
            <div class="w-full">
                <div class="flex flex-row my-4">
                    <div
                        class="flex items-center justify-start w-1/3 p-0 px-4 sty-input"
                    >
                        <Icon type="search" class="mr-2" />
                        <input
                            type="text"
                            class="w-full py-3 bg-transparent outline-none placeholder:italic"
                            :placeholder="$t('search_value')"
                            v-model="searchProject"
                        />
                    </div>
                </div>
                <div class="flex flex-wrap items-center">
                    <div class="mr-2">
                        <span class="mr-4 txt-default"
                            >{{ $t("items_per_page") }}:</span
                        >
                        <select
                            v-model="itemsPerPage"
                            @change="setCurrentPage(1)"
                            class="w-20 py-2 mr-1 sty-input"
                        >
                            <option
                                v-for="option in itemsPerPageOptions"
                                :key="option"
                                :value="option"
                            >
                                {{ option }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-48 btn-default" @click="addFinancial">
                    {{ $t("add_income") }}
                </div>
            </div>
        </div>
        <div class="my-2 overflow-y-auto">
            <table class="min-w-full rounded-table">
                <thead class="bg-thead-tbl">
                    <tr class="rounded-lg bg-tr">
                        <th
                            class="px-4 py-2 cursor-pointer"
                            v-for="(column, index) in columns"
                            :key="index"
                            @click="sortBy(column)"
                        >
                            <div
                                class="flex items-center justify-center row-auto"
                            >
                                {{ $t(column.label) }}
                                <span v-if="column.sortable" class="ml-1">
                                    <Icon
                                        class="w-6 h-6 color-icon"
                                        :type="
                                            sortDirection === 'desc'
                                                ? 'arrow-up'
                                                : 'arrow-down'
                                        "
                                    />
                                </span>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody class="b-tbody">
                    <template v-for="(item, id) in filterProjects" :key="id">
                        <tr :class="item.isActive ? 'bg-thead-tbl' : ''">
                            <td
                                v-for="(column, index) in columns"
                                :key="index"
                                class="py-3 size-td"
                            >
                                <div
                                    class="flex items-center justify-center h-8 text-sm rounded-md"
                                >
                                    {{ item[column.field] }}
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="mt-4">
                <nav class="flex justify-between" v-if="items.length > 0">
                    <div>
                        <span class="mr-2">{{ $t("pages") }}:</span>
                        <button
                            v-for="pageNumber in totalPages"
                            :key="pageNumber"
                            @click="setCurrentPage(pageNumber)"
                            class="pagination"
                            :class="{
                                'selected-page': pageNumber === currentPage,
                            }"
                        >
                            {{ pageNumber }}
                        </button>
                    </div>
                </nav>
                <div class="my-2 text-gray-500" v-else>
                    {{ $t("no_data") }}.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

export default {
    components: { Icon },
    props: {
        projectId: String,
    },
    data() {
        return {
            store,
            columns: [
                { field: "period", sortable: true, label: "period" },
                {
                    field: "financial_income",
                    sortable: true,
                    label: "financial_income",
                },
                {
                    field: "invested_value",
                    sortable: true,
                    label: "invested_value",
                },
                {
                    field: "redeemed_value",
                    sortable: true,
                    label: "redeemed_value",
                },
                { field: "net_value", sortable: true, label: "net_value" },
                { field: "balance", sortable: true, label: "balance" },
            ],
            items: [],
            sortField: null,
            sortDirection: "asc",

            itemsPerPageOptions: [5, 10, 30],
            itemsPerPage: 5,
            currentPage: 1,
            searchProject: "",
            infoSelected: [],
            status: [{ type: "complete" }, { type: "in_progress" }],
        };
    },
    computed: {
        sortedItems() {
            if (this.sortField) {
                const field = this.sortField;
                const direction = this.sortDirection === "desc" ? 1 : -1;

                return this.items.slice().sort((a, b) => {
                    if (a[field] < b[field]) return -direction;
                    if (a[field] > b[field]) return direction;
                    return 0;
                });
            }

            return this.items;
        },
        filterProjects() {
            return this.paginatedItems.filter((proj) => {
                return proj.period
                    .toLowerCase()
                    .includes(this.searchProject?.toLowerCase());
            });
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.sortedItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.sortedItems.length / this.itemsPerPage);
        },
    },
    created() {
        this.emitter.on("newFinancial", (newItem) => {
            this.items.push({
                period: new Date(newItem.period)
                    .toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "2-digit",
                    })
                    .replace("-", "/"),
                financial_income: newItem.financial_income,
                redeemed_value: newItem.redeemed_value,
                invested_value: newItem.invested_value,
                net_value: newItem.net_value,
                balance: newItem.balance,
            });
        });
    },
    async mounted() {
        await this.getRevenues();
    },
    methods: {
        async getRevenues() {
            const authHeader = store.getAuthHeader();

            const res = await axios.get(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/revenues/`,
                { withCredentials: false, headers: authHeader }
            );

            for (const revenue of res.data) {
                this.items.push({
                    period: new Date(revenue.period)
                        .toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "2-digit",
                        })
                        .replace("-", "/"),
                    financial_income: store.formatMoney(revenue.revenue),
                    redeemed_value: store.formatMoney(revenue.withdraw),
                    invested_value: store.formatMoney(revenue.invested),
                    net_value: store.formatMoney(revenue.net_income),
                    balance: store.formatMoney(revenue.balance),
                });
            }
        },
        sortBy(column) {
            if (column.sortable) {
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
                this.sortField = column.field;
            }
        },
        setCurrentPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
            }
        },
        addFinancial() {
            this.emitter.emit("openModalFinancial", true);
        },
    },
};
</script>
