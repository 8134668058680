<template>
    <div class="w-full">
        <div class="my-2 overflow-y-auto">
            <table class="min-w-full rounded-table">
                <thead class="bg-thead-tbl">
                    <tr>
                        <th
                            class="px-4 py-2 cursor-pointer"
                            v-for="(column, index) in columns"
                            :key="index"
                            @click="sortBy(column)"
                        >
                            <div
                                class="flex items-center justify-between row-auto capitalize"
                            >
                                {{ $t(column.label) }}
                                <span v-if="column.sortable" class="ml-1">
                                    <Icon
                                        class="w-6 h-6 color-icon"
                                        :type="
                                            sortDirection === 'desc'
                                                ? 'arrow-up'
                                                : 'arrow-down'
                                        "
                                    />
                                </span>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody class="b-tbody divide-y divide-gray-300">
                    <tr v-for="(item, id) in paginatedItems" :key="id">
                        <td class="px-4 py-2 size-td">{{ item.name }}</td>
                        <td class="px-4 py-2 size-td">
                            {{ store.formatText(item.role) }}
                        </td>
                        <td
                            class="size-td"
                            :title="store.formatText(item.background)"
                        >
                            {{ store.formatText(item.background) }}
                        </td>
                        <td class="px-4 py-2 size-td">
                            <input
                                type="text"
                                :disabled="!item.edit"
                                v-model="item.total_hours"
                                class="p-1 w-10/12 outline-none rounded m-0"
                                :class="
                                    !item.edit
                                        ? 'border border-transparent bg-transparent'
                                        : 'border border-gray-400 bg-gray-100'
                                "
                            />
                        </td>
                        <td class="px-4 py-2 size-td">
                            <input
                                type="text"
                                :disabled="!item.edit"
                                v-model="item.total_payment"
                                @input="onInput(id, 'total_payment', $event)"
                                class="p-1 w-10/12 outline-none rounded m-0"
                                :class="
                                    !item.edit
                                        ? 'border border-transparent bg-transparent'
                                        : 'border border-gray-400 bg-gray-100'
                                "
                            />
                        </td>
                        <td class="px-4 py-2 size-td">
                            <input
                                type="text"
                                :disabled="!item.edit"
                                v-model="item.total_benefits"
                                @input="onInput(id, 'total_benefits', $event)"
                                class="p-1 w-10/12 outline-none rounded m-0"
                                :class="
                                    !item.edit
                                        ? 'border border-transparent bg-transparent'
                                        : 'border border-gray-400 bg-gray-100'
                                "
                            />
                        </td>
                        <td>
                            <div @click="editTeam(item)" class="cursor-pointer">
                                <Icon
                                    :type="item.edit ? 'check' : 'pencil'"
                                    class="w-5 h-5"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4">
                <nav class="flex justify-between" v-if="items.length > 0">
                    <div>
                        <span class="mr-2">{{ $t("pages") }}:</span>
                        <button
                            v-for="pageNumber in totalPages"
                            :key="pageNumber"
                            @click="setCurrentPage(pageNumber)"
                            class="pagination"
                            :class="{
                                'selected-page': pageNumber === currentPage,
                            }"
                        >
                            {{ pageNumber }}
                        </button>
                    </div>
                </nav>
                <div class="my-2 text-gray-500" v-if="items.length === 0">
                    {{ $t("no_data") }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";
import axios from "axios";

export default {
    props: {
        team: Object,
    },
    components: { Icon },
    data() {
        return {
            store,
            items: [],
            columns: [
                { field: "name", sortable: true, label: "name" },
                { field: "role", sortable: true, label: "function" },
                { field: "background", sortable: true, label: "formation" },
                {
                    field: "total_hours",
                    sortable: true,
                    label: "workload",
                },
                { field: "total_payment", sortable: true, label: "value" },
                {
                    field: "total_benefits",
                    sortable: true,
                    label: "benefits",
                },
            ],
            sortField: null,
            sortDirection: "asc",

            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 5,
            currentPage: 1,
        };
    },
    computed: {
        sortedItems() {
            if (this.sortField) {
                const field = this.sortField;
                const direction = this.sortDirection === "desc" ? 1 : -1;

                return this.items.slice().sort((a, b) => {
                    if (a[field] < b[field]) return -direction;
                    if (a[field] > b[field]) return direction;
                    return 0;
                });
            }

            return this.items;
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.sortedItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.sortedItems.length / this.itemsPerPage);
        },
    },
    created() {
        this.items = this.team.map((member) => ({
            ...member,
            total_hours: member.total_hours + " " + this.$t("hours"),
            total_payment: this.store.formatMoney(member.total_payment),
            total_benefits: this.store.formatMoney(member.total_benefits),
            edit: false,
        }));
    },

    methods: {
        sortBy(column) {
            if (column.sortable) {
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
                this.sortField = column.field;
            }
        },
        setCurrentPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
            }
        },
        onInput(index, field, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            this.items[index][field] = formattedValue;
        },
        async editTeam(item) {
            const authHeader = store.getAuthHeader();

            for (const member of this.items) {
                if (member._id !== item._id) {
                    member.edit = false;
                } else if (item.edit) {
                    item.edit = false;
                    let body = {
                        hours: parseFloat(member.total_hours.match(/\d+/)[0]),
                        payment: this.store.formatValueToSave(
                            member.total_payment
                        ),
                        benefits: this.store.formatValueToSave(
                            member.total_benefits
                        ),
                    };
                    try {
                        let res = await axios.put(
                            `${store.BACKEND_URL}/api/projects/${this.$route.params.id}/budget/${member._id}/?category='executing_team'`,
                            body,
                            { withCredentials: false, headers: authHeader }
                        );
                        if (res.status == 204) {
                            this.$toast.success("Alterado com sucesso.");
                        } else {
                            this.$toast.error("Erro ao alterar.");
                        }
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    item.edit = true;
                }
            }
        },
    },
};
</script>
