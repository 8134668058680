<template>
    <div class="grid grid-cols-4 gap-4">
        <div class="h-[75vh]">
            <div class="text-xl font-bold">{{ $t("sections") }}</div>
            <div class="py-2 text-sm italic">
                {{ $t("navigate_through_the_filling_sections") }}
            </div>
            <div
                class="flex items-center justify-start w-full sty-input inpt-filter"
            >
                <Icon type="search" class="mr-2" />
                <input
                    type="text"
                    class="w-full bg-transparent outline-none placeholder:italic"
                    v-model="searchComp"
                    placeholder="Pesquisar seção..."
                />
            </div>
            <div class="py-2 h-[64vh] overflow-auto">
                <div
                    class="options-ptr"
                    :class="{
                        'options-ptr-selected': isSelected(step.section),
                    }"
                    v-for="(step, index) in filterComponents"
                    :key="index"
                    @click="selectComponent(step.section)"
                >
                    {{ $t(step.section) }}
                    <Icon
                        type="arrow-right"
                        class="w-4 h-4"
                        v-if="step.select"
                    />
                </div>
            </div>
        </div>
        <div class="col-span-3">
            <div>
                <div class="flex items-center justify-between">
                    <div class="title-section">
                        {{ $t("sections") }}
                        {{
                            currentSection.number == null
                                ? 1
                                : currentSection.number
                        }}
                        -
                        {{
                            currentSection.name == null
                                ? $t("general_information")
                                : $t(currentSection.name)
                        }}
                    </div>
                    <div class="box-count-steps">{{ currentIndex + 1 }}/21</div>
                </div>
                <div class="w-full bg-gray-200 rounded-full h-2.5 mt-2 mb-4">
                    <div
                        class="bg-cyan-900 h-2.5 rounded-full"
                        :style="{ width: progressBarWidth }"
                    ></div>
                </div>
            </div>
            <component
                :is="currentComponent"
                :next-page="nextPage"
                :previous-page="previousPage"
                :current-index="currentIndex"
            />
        </div>
    </div>
</template>

<script>
import Icon from "../../../UI/Icon";

import { store } from "@/store";
import GeneralInformation from "./sectionsForm/general_information.vue";
import Documents from "./sectionsForm/documents_update.vue";
import InstitutionalData from "./sectionsForm/institutional_data.vue";
import techinicalData from "./sectionsForm/technical_data.vue";
import Activities from "./sectionsForm/info_activities.vue";
import ExecutingTeam from "./sectionsForm/executing_team.vue";
import StaffExpenses from "./sectionsForm/staff_expenses.vue";
import MaterialExpenses from "./sectionsForm/material_expenses.vue";
import AcquisitionExpenses from "./sectionsForm/acquisition_expenses.vue";
import RelatedExpenses from "./sectionsForm/related_expenses.vue";
import HiringExpenses from "./sectionsForm/hiring_expenses.vue";
import PrototypeExpenses from "./sectionsForm/prototype_expenses.vue";
import ConstructionExpenses from "./sectionsForm/construction_expenses.vue";
import EntitlementExpenses from "./sectionsForm/entitlement_expenses.vue";
import CostExpenses from "./sectionsForm/cost_expenses.vue";
import TrainingExpenses from "./sectionsForm/training_expenses.vue";
import ResourceExpenditure from "./sectionsForm/resource_expenditure.vue";
import TestingExpenses from "./sectionsForm/testing_expenses.vue";
import OtherExpenses from "./sectionsForm/other_expenses.vue";
import TaxObligations from "./sectionsForm/tax_obligations.vue";
import OtherSources from "./sectionsForm/other_sources.vue";

export default {
    components: {
        Icon,
        GeneralInformation,
        Documents,
        InstitutionalData,
        techinicalData,
        Activities,
        ExecutingTeam,
        StaffExpenses,
        MaterialExpenses,
        AcquisitionExpenses,
        RelatedExpenses,
        HiringExpenses,
        PrototypeExpenses,
        ConstructionExpenses,
        EntitlementExpenses,
        CostExpenses,
        TrainingExpenses,
        ResourceExpenditure,
        TestingExpenses,
        OtherExpenses,
        TaxObligations,
        OtherSources,
    },
    data() {
        return {
            store,
            currentIndex: 0,
            searchComp: "",
            progressBarWidth: "5%",
            currentSection: [
                {
                    section: "general_information",
                    number: 1,
                    select: true,
                },
                {
                    section: "documents",
                    number: 2,
                    select: false,
                },
                {
                    section: "institutional_data",
                    number: 3,
                    select: false,
                },
                {
                    section: "technical_data",
                    number: 4,
                    select: false,
                },
                {
                    section: "activities",
                    number: 5,
                    select: false,
                },
                {
                    section: "execution_team",
                    number: 6,
                    select: false,
                },
                {
                    section: "team_expenses",
                    number: 7,
                    select: false,
                },
                {
                    section: "consumables_expenses",
                    number: 8,
                    select: false,
                },
                {
                    section: "travel_expenses",
                    number: 9,
                    select: false,
                },
                {
                    section: "services_expenses",
                    number: 10,
                    select: false,
                },
                {
                    section: "tib_service_expenses",
                    number: 11,
                    select: false,
                },
                {
                    section: "prototype_expenses",
                    number: 12,
                    select: false,
                },
                {
                    section: "construction_installation_expenses",
                    number: 13,
                    select: false,
                },
                {
                    section: "expenses_goods_and_rights",
                    number: 14,
                    select: false,
                },
                {
                    section: "costs_expenses",
                    number: 15,
                    select: false,
                },
                {
                    section: "supply_capacity_expenses",
                    number: 16,
                    select: false,
                },
                {
                    section: "human_resources_expenses",
                    number: 17,
                    select: false,
                },
                {
                    section: "tests_expenses",
                    number: 18,
                    select: false,
                },
                {
                    section: "other_expenses",
                    number: 19,
                    select: false,
                },
                {
                    section: "tax_obligations",
                    number: 20,
                    select: false,
                },
                {
                    section: "other_sources",
                    number: 21,
                    select: false,
                },
            ],

            ptrDocument: [],
        };
    },
    computed: {
        componentsList() {
            return [
                "GeneralInformation",
                "Documents",
                "InstitutionalData",
                "techinicalData",
                "Activities",
                "ExecutingTeam",
                "StaffExpenses",
                "MaterialExpenses",
                "AcquisitionExpenses",
                "RelatedExpenses",
                "HiringExpenses",
                "PrototypeExpenses",
                "ConstructionExpenses",
                "EntitlementExpenses",
                "CostExpenses",
                "TrainingExpenses",
                "ResourceExpenditure",
                "TestingExpenses",
                "OtherExpenses",
                "TaxObligations",
                "OtherSources",
            ];
        },
        currentComponent() {
            return this.componentsList[this.currentIndex];
        },
        buttonContainerClass() {
            return {
                "justify-between": this.currentIndex > 0,
                "justify-end": this.currentIndex === 0,
            };
        },
        filterComponents() {
            return this.currentSection.filter((comp) => {
                return comp.section
                    .toLowerCase()
                    .includes(this.searchComp?.toLowerCase());
            });
        },
    },
    methods: {
        nextPage() {
            this.currentIndex = Math.min(
                this.currentIndex + 1,
                this.componentsList.length - 1
            );
            let nameSection = this.currentSection.find(
                (id) =>
                    id.section ===
                    this.currentSection[this.currentIndex].section
            );
            this.selectComponent(nameSection.section);
        },
        previousPage() {
            this.currentIndex = Math.max(this.currentIndex - 1, 0);

            let nameSection = this.currentSection.find(
                (id) =>
                    id.section ===
                    this.currentSection[this.currentIndex].section
            );
            this.selectComponent(nameSection.section);
        },
        selectComponent(section) {
            this.currentIndex = this.currentSection.findIndex(
                (step) => step.section === section
            );
            this.currentSection.number = this.currentIndex + 1;
            this.currentSection.name = section;

            const foundSection = this.currentSection.find(
                (sect) => sect.section === section
            );
            this.currentSection.forEach((sect) => {
                sect.select = sect === foundSection;
            });

            this.updateProgressBarWidth();
        },
        updateProgressBarWidth() {
            const totalSteps = this.currentSection.length;
            const currentStep = this.currentIndex + 1;
            const widthPercentage = (currentStep / totalSteps) * 100 + "%";
            this.progressBarWidth = widthPercentage;
        },
        isSelected(section) {
            return this.currentSection[this.currentIndex].section === section;
        },
    },
};
</script>
<style>
.options-ptr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 5px;
    margin: 0 0 7px 0;
    cursor: pointer;
}

.options-ptr-selected {
    background-color: #c0d0d8;
    color: #155e75;
    border: 1px solid #155e75;
    font-weight: bold;
}

.options-ptr:hover {
    background-color: #c0d0d8;
    color: #155e75;
    border: 1px solid #155e75;
}
</style>
