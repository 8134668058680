<template>
    <div>
        <div class="flex flex-row justify-between mb-6">
            <div class="flex flex-wrap items-center">
                <div class="mr-2">
                    <span class="mr-4 txt-default"
                        >{{ $t("items_per_page") }}:</span
                    >
                    <select
                        v-model="itemsPerPage"
                        @change="setCurrentPage(1)"
                        class="w-20 py-2 mr-1 sty-input"
                    >
                        <option
                            v-for="option in itemsPerPageOptions"
                            :key="option"
                            :value="option"
                        >
                            {{ option }}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <div class="btn-default" @click="addStatus">
                    {{ $t("add_status") }}
                </div>
            </div>
        </div>

        <div class="my-2 overflow-y-auto">
            <table class="min-w-full rounded-table">
                <thead class="bg-thead-tbl">
                    <tr class="rounded-lg bg-tr">
                        <th
                            class="px-4 py-2 cursor-pointer"
                            v-for="(column, index) in columns"
                            :key="index"
                            @click="sortBy(column)"
                        >
                            <div
                                class="flex items-center justify-start row-auto"
                            >
                                {{ $t(column.label) }}
                                <span v-if="column.sortable" class="ml-1">
                                    <Icon
                                        class="w-6 h-6 color-icon"
                                        :type="
                                            sortDirection === 'desc'
                                                ? 'arrow-up'
                                                : 'arrow-down'
                                        "
                                    />
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody class="b-tbody divide-y divide-gray-300">
                    <tr v-for="(item, id) in paginatedItems" :key="id">
                        <td class="py-3 px-4">{{ item.period }}</td>
                        <td class="py-3 px-4">{{ item.amount_disbursed }}</td>
                        <td class="py-3 px-4">{{ $t(item.category) }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4">
                <nav class="flex justify-between" v-if="items.length > 0">
                    <div>
                        <span class="mr-2">{{ $t("pages") }}:</span>
                        <button
                            v-for="pageNumber in totalPages"
                            :key="pageNumber"
                            @click="setCurrentPage(pageNumber)"
                            class="pagination"
                            :class="{
                                'selected-page': pageNumber === currentPage,
                            }"
                        >
                            {{ pageNumber }}
                        </button>
                    </div>
                </nav>
                <div class="my-2 text-gray-500" v-if="items.length === 0">
                    {{ $t("no_data") }}.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

export default {
    components: { Icon },
    props: {
        projectId: String,
    },
    data() {
        return {
            store,
            items: [],
            columns: [
                { field: "period", sortable: true, label: "period" },
                {
                    field: "amount_disbursed",
                    sortable: true,
                    label: "amount_disbursed",
                },
                { field: "category", sortable: true, label: "category" },
            ],
            sortField: null,
            sortDirection: "asc",

            itemsPerPageOptions: [5, 10, 30],
            itemsPerPage: 5,
            currentPage: 1,
            searchProject: "",
            infoSelected: [],
        };
    },

    computed: {
        sortedItems() {
            if (this.sortField) {
                const field = this.sortField;
                const direction = this.sortDirection === "desc" ? 1 : -1;

                return this.items.slice().sort((a, b) => {
                    if (a[field] < b[field]) return -direction;
                    if (a[field] > b[field]) return direction;
                    return 0;
                });
            }

            return this.items;
        },

        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.sortedItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.sortedItems.length / this.itemsPerPage);
        },
    },
    created() {
        this.emitter.on("newStatusReport", (newStatusReport) => {
            this.items.push({
                ...newStatusReport,
                amount_disbursed: store.formatMoney(
                    Number(newStatusReport.value)
                ),
            });
        });
    },
    async mounted() {
        await this.getStatusReport();
    },
    methods: {
        async getStatusReport() {
            const authHeader = store.getAuthHeader();

            const res = await axios.get(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/status-reports/`,
                {
                    headers: authHeader,
                    withCredentials: false,
                }
            );

            if (res.status >= 300) {
                this.$toast.error(
                    "Something went wrong retrieving status reports."
                );
                return;
            }
            for (const statusReport of res.data) {
                this.items.push({
                    period: statusReport.period,
                    amount_disbursed: store.formatMoney(statusReport.value),
                    category: statusReport.category,
                });
            }
        },
        addStatus() {
            this.emitter.emit("openModaAddStatus", true);
        },
        sortBy(column) {
            if (column.sortable) {
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
                this.sortField = column.field;
            }
        },
        setCurrentPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
            }
        },
        getRow(item) {
            for (const currentItem of this.items) {
                if (currentItem.name === item.name) {
                    currentItem.isActive = !currentItem.isActive;
                    if (currentItem.isActive) {
                        this.infoSelected = currentItem;
                    }
                } else {
                    currentItem.isActive = false;
                }
            }
        },
    },
};
</script>
