<template>
    <div>
        <Chart
            type="bar"
            :data="chartData"
            :options="chartOptions"
            class="h-[30rem]"
        />
    </div>
</template>

<script>
import Chart from "primevue/chart";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        Chart,
    },
    data() {
        return {
            store,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "",
                        backgroundColor: "#06B6D4",
                        data: [],
                    },
                    {
                        label: "",
                        backgroundColor: "#CBC9CC",
                        data: [],
                    },
                ],
            },
            chartOptions: {
                indexAxis: "y",
                borderRadius: 7,
                maxBarThickness: 35,
                categoryPercentage: 0.4,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        position: "top",
                        grid: {
                            display: true,
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            callback: (value, index, values) => {
                                return this.abbreviateName(
                                    store.formatText(
                                        this.chartData.labels[index]
                                    )
                                );
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            font: {
                                size: 16,
                            },
                            usePointStyle: true,
                            pointStyle: "circle",
                            boxWidth: 8,
                            boxHeight: 8,
                        },
                    },
                    filler: {
                        propagate: true,
                    },
                    datalabels: {
                        display: false,
                    },
                },
            },
            projectsInfo: [],
            selectedProjects: [],
            biggestDeadline: 0,
            loaded: false,
        };
    },
    beforeCreate() {
        this.emitter.on("updateGraph", async (value) => {
            if (this.selectedProjects.includes(value)) {
                this.selectedProjects = this.selectedProjects.filter(
                    (id) => id !== value
                );
            } else {
                this.selectedProjects.push(value);
            }

            await this.getProjectsDeadlines();
            this.populateChartData();
        });
        this.emitter.on("updateLang", (upd) => {
            this.populateChartData();
        });
    },
    async beforeMount() {
        await this.getProjectsDeadlines();
        this.populateChartData();
        this.loaded = true;
    },
    computed: {
        progress() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            let data = [];
            for (const project of filteredProjects) {
                const startDate = new Date(project.start_date);
                const endDate = new Date(project.end_date);

                const remainingMonths = store.getMonthsDiff(startDate, endDate);

                const today = new Date();
                let currentSize = store.getMonthsDiff(today, startDate);
                currentSize = Math.max(currentSize, 0);

                const actualMonths = store.getMonthsDiff(today, startDate);

                data.push({
                    name: project.title,
                    size: currentSize,
                    remaining: remainingMonths,
                });
            }

            return data;
        },
    },
    methods: {
        populateChartData() {
            const labels = this.progress.map((project) => project.name);
            const remainingMonths = this.progress.map(
                (project) => project.remaining
            );
            const actualMonths = this.progress.map((project) => project.size);

            this.chartData.labels = labels;
            this.chartData.datasets[1].data = remainingMonths;
            this.chartData.datasets[0].data = actualMonths;

            this.chartData.datasets[1].label = this.$t("months_elapsed");
            this.chartData.datasets[0].label = this.$t("total_months");
        },
        abbreviateName(name) {
            const maxLength = 20;
            const words = name.split(" ");
            let lines = [];
            let currentLine = "";

            words.forEach((word) => {
                if ((currentLine + word).length <= maxLength) {
                    currentLine += word + " ";
                } else {
                    lines.push(currentLine.trim());
                    currentLine = word + " ";
                }
            });

            if (currentLine) {
                lines.push(currentLine.trim());
            }
            return lines;
        },
        async getProjectsDeadlines() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length !== 0) {
                return;
            }

            const res = await axios.get(
                `${store.BACKEND_URL}/api/statistics/projects-deadlines/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );

            if (res.status !== 200) {
                this.$toast.error(
                    "Something went wrong retrieving projects deadlines."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
            this.calculateBiggestDeadline();
        },
        calculateBiggestDeadline() {
            let maxDeadline = 0;
            for (const project of this.projectsInfo) {
                const startDate = new Date(project.start_date);
                const endDate = new Date(project.end_date);
                const remainingMonths = store.getMonthsDiff(startDate, endDate);
                maxDeadline = Math.max(maxDeadline, remainingMonths);
            }
            this.biggestDeadline = maxDeadline;
        },
    },
};
</script>
