<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{ $t("expenses_related_to_third_party_services") }}
            </div>
            <div v-for="(item, index) in service_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("type_of_service")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.type"
                        >
                            <option value="1">
                                {{ $t("specialized_technical_service") }}
                            </option>
                            <option value="2">
                                {{
                                    $t(
                                        "registration_fee_for_conference_or_event"
                                    )
                                }}
                            </option>
                            <option value="3">
                                {{
                                    $t("locomotion_and_transportation_service")
                                }}
                            </option>
                            <option value="4">
                                {{ $t("editing_and_printing_service") }}
                            </option>
                            <option value="4">
                                {{ $t("maintenance_service") }}
                            </option>
                            <option value="4">
                                {{ $t("computational_services") }}
                            </option>
                            <option value="4">
                                {{
                                    $t(
                                        "qualification_and_certification_services"
                                    )
                                }}
                            </option>
                            <option value="4">
                                {{ $t("other_support_service") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col col-span-2">
                        <label for="title" class="label-ptr"
                            >{{ $t("service_description") }}
                            <sup class="normal-case"
                                >150 {{ $t("characters") }}</sup
                            ></label
                        >
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.description"
                        />
                        <small
                            >{{
                                store.remainingCharacters(item.description, 150)
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col col-span-2">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup class="normal-case"
                                >300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("value") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.value"
                            @input="onInput(index, $event)"
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="DeletecostExpenses(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="my-4">
                <div class="w-48 btn-default" @click="addService">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t(
                        "total_value_of_expenses_related_to_third_party_services"
                    )
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValue"
                    readonly
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            service_expenses: [
                {
                    type: "",
                    description: "",
                    company_name: "",
                    justification: "",
                    value: null,
                },
            ],
            totalValue: "",

            loading: false,
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (data != null && data.hasOwnProperty("service_expenses")) {
            this.service_expenses = data.service_expenses.map((expense) => ({
                ...expense,
                value: this.store.formatMoney(expense.value),
            }));
            this.updateTotalValue();
        }
    },
    methods: {
        addService() {
            this.service_expenses.push({
                type: "",
                description: "",
                company_name: "",
                justification: "",
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;
            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};

            parsedData.service_expenses = this.service_expenses.map((act) => {
                return {
                    ...act,
                    value: this.store.formatValueToSave(act.value),
                };
            });
            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.service_expenses[index]) {
                this.service_expenses[index].value = formattedValue;
            }
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.service_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        DeletecostExpenses(index) {
            this.service_expenses.splice(index, 1);
            this.updateTotalValue();
        },
    },
};
</script>
