<template>
    <div class="flex flex-row justify-evenly">
        <div class="donuts">
            <div
                class="flex items-center justify-center absolute top-0 h-[160px] w-[200px]"
            >
                <span class="percents percents-project">
                    {{ Math.round(prog.activitiesPercentage) }}%
                    <span class="subtitle">{{ $t("finished") }}</span>
                </span>
            </div>
            <div class="chart-container">
                <Doughnut :options="chartOptions" :data="chartData" />
            </div>
        </div>
    </div>
    <div class="flex flex-col">
        <span class="progress-description">
            <div class="font-semibold">{{ $t("steps_completed") }}:</div>
            <div>{{ prog.completedActivities }}/{{ prog.totalActivities }}</div>
        </span>
        <span class="progress-description">
            <div class="font-semibold">{{ $t("used_resources") }}:</div>
            <div>
                {{ store.formatMoney(prog.usedBudget) }}
            </div>
        </span>
        <span class="progress-description">
            <div class="font-semibold">{{ $t("budget") }}:</div>
            <div>
                {{ store.formatMoney(prog.budget) }}
            </div>
        </span>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
    props: {
        prog: Object,
    },
    components: { Doughnut, Icon },
    data() {
        const total = this.prog.totalActivities;
        const completed = this.prog.completedActivities;
        const remaining = total - completed;

        const completedPercentage = this.prog.activitiesPercentage;
        const remainingPercentage = 100 - completedPercentage;
        return {
            store,
            editEmp: false,
            commitment: "",
            chartData: {
                datasets: [
                    {
                        label: "Actives",
                        backgroundColor: ["#E1DFE2", "#155E75"],
                        data: [remainingPercentage, completedPercentage],
                        borderWidth: 4,
                        borderRadius: 6,
                    },
                ],
            },
            chartOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    filler: {
                        propagate: true,
                    },
                    datalabels: {
                        display: false,
                    },
                },
                cutout: "70%",
                radius: "100%",
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                    },
                },
            },
        };
    },
    created() {
        this.commitment = this.store.formatMoney(this.prog.budget);
    },
    methods: {
        onInput(event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            this.prog.budget = formattedValue;
        },
    },
};
</script>
<style>
.progress-description {
    margin: 7px 0 5px;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}

.chart-container {
    width: 200px;
    height: 200px;
}
</style>
