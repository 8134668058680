<template>
    <div class="flex justify-center">
        <div
            class="inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            :class="size ? size : 'h-32 w-32'"
            role="status"
        ></div>
    </div>
</template>

<script>
import { store } from "@/store";

export default {
    props: {
        size: String,
    },
    data() {
        return {
            store,
        };
    },
};
</script>

<style>
.animate-spin {
    color: v-bind("store.colors.txtDescription") !important;
}
</style>
