<template>
    <div class="flex justify-end" v-if="openModalFilter">
        <div class="box-filter">
            <div class="flex justify-end">
                <div
                    @click="openModalFilter = !openModalFilter"
                    class="cursor-pointer"
                >
                    <Icon type="x-circle" class="w-6 h-6 text-gray-500" />
                </div>
            </div>
            <div
                class="flex flex-row items-center justify-start cursor-pointer"
                @click="openStatus = !openStatus"
            >
                <Icon
                    type="arrow-down"
                    class="w-4 h-4"
                    :class="!openStatus ? 'rotate-down' : 'rotate-up'"
                />
                <div class="mx-2 font-bold text-md">Status</div>
            </div>

            <div class="flex flex-col gap-1" v-show="openStatus">
                <div
                    v-for="(item, index) in statusList"
                    :key="index"
                    class="flex flex-row items-center justify-between p-1 border border-transparent rounded-sm"
                    :class="{
                        'cursor-pointer': !item.statusClicked,
                        border: item.statusClicked,
                    }"
                    :style="item.statusClicked ? item.borderColorClass : {}"
                    @click="toggleStatus(index)"
                >
                    <div class="flex flex-row items-center">
                        <div
                            :class="[
                                item.iconBgColorClass,
                                'w-3 h-3',
                                'rounded-full',
                            ]"
                        ></div>
                        <span class="mx-2 text-sm">{{ $t(item.status) }}</span>
                    </div>
                    <Icon
                        :type="item.iconType"
                        v-if="item.statusClicked"
                        :class="[item.iconColorClass, 'w-4 h-4']"
                    />
                </div>
            </div>

            <div
                class="flex flex-row items-center justify-start my-2 cursor-pointer"
                @click="creationDate = !creationDate"
            >
                <Icon
                    type="arrow-down"
                    class="w-4 h-4"
                    :class="!creationDate ? 'rotate-down' : 'rotate-up'"
                />
                <div class="mx-2 font-bold text-md">
                    {{ $t("creation_date") }}
                </div>
            </div>
            <div class="flex flex-row" v-show="creationDate">
                <div class="text-sm">
                    <span class="italic">{{ $t("start_date") }}</span>
                    <input
                        type="date"
                        class="p-1 my-1 border border-gray-400 rounded-md"
                    />
                </div>
                <div class="text-sm">
                    <span class="italic">{{ $t("final_date") }}</span>
                    <input
                        type="date"
                        class="p-1 my-1 border border-gray-400 rounded-md"
                    />
                </div>
            </div>
            <div
                class="flex flex-row items-center justify-start my-2 cursor-pointer"
                @click="finishdate = !finishdate"
            >
                <Icon
                    type="arrow-down"
                    class="w-4 h-4"
                    :class="!finishdate ? 'rotate-down' : 'rotate-up'"
                />
                <div class="mx-2 font-bold text-md">
                    {{ $t("completion_date") }}
                </div>
            </div>
            <div class="flex flex-row" v-show="finishdate">
                <div class="text-sm">
                    <span class="italic">{{ $t("start_date") }}</span>
                    <input
                        type="date"
                        class="p-1 my-1 border border-gray-400 rounded-md"
                    />
                </div>
                <div class="text-sm">
                    <span class="italic">{{ $t("final_date") }}</span>
                    <input
                        type="date"
                        class="p-1 my-1 border border-gray-400 rounded-md"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
export default {
    components: { Icon },
    data() {
        return {
            openModalFilter: false,

            openStatus: true,
            creationDate: false,
            finishdate: false,

            statusList: [
                {
                    status: "new",
                    iconType: "check",
                    iconBgColorClass: "bg-purple-600",
                    iconColorClass: "text-purple-600",
                    borderColorClass: {
                        border: "1px solid",
                        borderColor: "#9f7aea",
                        backgroundColor: "#F3E8FF",
                    },
                    statusClicked: true,
                },
                {
                    status: "under_review",
                    iconType: "check",
                    iconBgColorClass: "bg-teal-600",
                    iconColorClass: "text-teal-600",
                    borderColorClass: {
                        border: "1px solid",
                        borderColor: "#0D9488",
                        backgroundColor: "#CCFBF1",
                    },
                    statusClicked: false,
                },
                {
                    status: "in_progress",
                    iconType: "check",
                    iconBgColorClass: "bg-amber-600",
                    iconColorClass: "text-amber-600",
                    borderColorClass: {
                        border: "1px solid",
                        borderColor: "#D97706",
                        backgroundColor: "#FEF3C7",
                    },
                    statusClicked: false,
                },
                {
                    status: "finished",
                    iconType: "check",
                    iconBgColorClass: "bg-green-600",
                    iconColorClass: "text-green-600",
                    borderColorClass: {
                        border: "1px solid",
                        borderColor: "#16A34A",
                        backgroundColor: "#DCFCE7",
                    },
                    statusClicked: false,
                },
                {
                    status: "canceled",
                    iconType: "check",
                    iconBgColorClass: "bg-red-600",
                    iconColorClass: "text-red-600",
                    borderColorClass: {
                        border: "1px solid",
                        borderColor: "#DC2626",
                        backgroundColor: "#FEE2E2",
                    },
                    statusClicked: false,
                },
            ],
        };
    },
    created() {
        this.emitter.on("open filter", (val) => {
            this.openModalFilter = val;
        });
    },
    methods: {
        toggleStatus(index) {
            this.statusList[index].statusClicked =
                !this.statusList[index].statusClicked;
        },
    },
};
</script>
<style></style>
