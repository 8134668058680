<template>
    <div>
        <div class="txt-titulo">
            <div>{{ $t("list_of_items_per_project") }}</div>
            <div class="mt-4">
                <select
                    v-model="selectedProject"
                    class="truncate full-select cursor-pointer"
                >
                    <option v-for="(ts, tsId) in projects" :value="ts._id">
                        {{ store.formatText(ts.title) }}
                    </option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-3">
            <div>
                <div class="tableValues">
                    <table border="1">
                        <thead>
                            <tr>
                                <th class="text-start">
                                    {{ $t("expense_item") }}
                                </th>
                                <th class="text-start">
                                    {{ $t("available") }}
                                </th>
                                <th class="text-start">
                                    {{ $t("total") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in tableItems"
                                :key="index"
                            >
                                <td
                                    class="flex flex-row items-center border-none"
                                >
                                    <div
                                        class="p-1.5 mr-2 rounded-full"
                                        :style="{
                                            backgroundColor: item.color,
                                        }"
                                    ></div>
                                    {{ $t(item.category) }}
                                </td>
                                <td>{{ item.available }}</td>
                                <td>{{ item.total }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total</th>
                                <th>{{ totalAvailable }}</th>
                                <th>{{ total }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-span-2">
                <Bar :data="chartData" :options="options" height="500px" />
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store";
import axios from "axios";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";
import { Bar } from "vue-chartjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default {
    components: { Bar },
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            selectedProject: "",
            titleSelected: "",
            projects: [],
            tableItems: [],
            total: 0,
            totalAvailable: 0,
            options: {
                responsive: true,
                borderRadius: 7,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            font: {
                                size: 14,
                            },
                            usePointStyle: true,
                            pointStyle: "circle",
                            boxWidth: 8,
                            boxHeight: 8,
                        },
                    },
                    datalabels: {
                        display: false,
                    },
                },
            },
        };
    },

    beforeCreate() {
        this.emitter.on("updateGraph", (value) => {
            if (this.selectedProjects.includes(value)) {
                const idx = this.selectedProjects.indexOf(value);
                this.selectedProjects.splice(idx, 1);
            } else {
                this.selectedProjects.push(value);
            }
        });
    },

    async beforeMount() {
        await this.getProjectsItems();
        await this.getProjects();
        this.loaded = true;
    },

    computed: {
        chartData() {
            if (!this.selectedProject) {
                return {
                    labels: [],
                    datasets: [],
                };
            }
            const backgroundColor = [
                "#6CD6AA",
                "#ED7545",
                "#06B6D4",
                "#B778A2",
                "#155E75",
                "#1C1B1F",
                "#4CA84F",
                "#FF9C26",
                "#F5CE9A",
                "#5260ff",
                "#eb445a",
                "#01FF70",
            ];
            const projectData = this.projectsInfo.find(
                (project) => project._id == this.selectedProject
            );
            this.titleSelected = projectData.title;

            const categoryTotals = {};
            for (const item of projectData.items) {
                if (!categoryTotals[item.category]) {
                    categoryTotals[item.category] = {
                        category: item.category,
                        used: 0,
                        total: item.total,
                    };
                }

                categoryTotals[item.category].used += item.used;
            }

            let totalAvailable = 0;
            let total = 0;
            this.tableItems = Object.values(categoryTotals).map((item) => {
                const available = item.total - item.used;

                const threshold = 1e-6;

                const adjustedAvailable =
                    Math.abs(available) < threshold ? 0 : available;
                totalAvailable += adjustedAvailable;
                total += item.total;
                return {
                    category: item.category,
                    total: store.formatMoney(item.total),
                    available: store.formatMoney(adjustedAvailable),
                };
            });
            this.totalAvailable = store.formatMoney(totalAvailable);
            this.total = store.formatMoney(total);

            const quarters = {};
            for (const item of projectData.items) {
                const date = new Date(item.date);
                const year = date.getUTCFullYear();
                const quarter = Math.ceil((date.getUTCMonth() + 1) / 3);
                const key = `${year}-Q${quarter}`;
                if (!quarters[key]) {
                    quarters[key] = {};
                }
                if (!quarters[key][item.category]) {
                    quarters[key][item.category] = 0;
                }

                quarters[key][item.category] += item.used;
            }

            const sortedQuarters = Object.keys(quarters);
            sortedQuarters.sort();

            const datasets = [];
            let i = 0;
            for (const category of Object.keys(categoryTotals)) {
                const categoryData = [];

                for (const quarter of sortedQuarters) {
                    categoryData.push(quarters[quarter][category] || null);
                }

                datasets.push({
                    label: this.store.formatText(
                        this.removeExpenseWord(this.$t(category))
                    ),
                    data: categoryData,
                    backgroundColor: backgroundColor[i],
                });
                this.tableItems[i].color = backgroundColor[i];
                i++;
            }

            return {
                labels: sortedQuarters,
                datasets,
            };
        },
    },

    methods: {
        removeExpenseWord(label) {
            return label.replace(/^(Despesas de?|Despesas?)\s*/, "");
        },

        groupValuesByQuarter(data) {
            const quarters = {};

            for (const category in data) {
                const values = data[category];
                for (const value of values) {
                    const date = new Date(value.date);
                    const year = date.getUTCFullYear();
                    const quarter = Math.floor((date.getUTCMonth() + 3) / 3);
                    const key = `${year}-${quarter}`;
                    if (!quarters[key]) {
                        quarters[key] = {};
                    }
                    quarters[key][category] = value.value;
                }
            }

            const result = [];
            for (const category in data) {
                const categoryData = [];
                for (const key in quarters) {
                    categoryData.push(quarters[key][category] || null);
                }
                result.push({
                    label: category,
                    data: categoryData,
                });
            }

            return result;
        },
        async getProjectsItems() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length != 0) {
                return;
            }
            let res;
            try {
                res = await axios.get(
                    `${store.BACKEND_URL}/api/statistics/items-per-project/`,
                    {
                        withCredentials: false,
                        headers: authHeader,
                    }
                );
            } catch (er) {
                console.log(er);
                return;
            }

            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving projects items."
                );
                return;
            }

            this.projectsInfo = res.data;
            if (this.projectsInfo.length > 0) {
                this.selectedProject = this.projectsInfo[0]._id;
            }
        },
        async getProjects() {
            const authHeader = store.getAuthHeader();
            if (!authHeader) {
                localStorage.clear();
                this.$router.push("/");
                return;
            }

            let res;
            try {
                res = await axios.get(`${store.BACKEND_URL}/api/projects/`, {
                    headers: authHeader,
                });
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            if (res.status != 200) {
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            for (const project of res.data) {
                const base = project.base;
                const institutionalData = project.institutional_data;

                const title = base.title;
                const description = project.technical_data.goal;
                const projectStartDate = new Date(base.contract_date);
                const totalActivities = project.activities.length;

                const completedActivitiesCount = project.activities.reduce(
                    (acc, activity) => {
                        return acc + (activity.progress == 100 ? 1 : 0);
                    },
                    0
                );
                const status =
                    completedActivitiesCount == totalActivities
                        ? "Completo"
                        : "Em andamento";

                const totalValue = store.calculateProjectTotalExpenses(project);

                const mainExecutor = institutionalData.executor_companies.length
                    ? institutionalData.executor_companies[0].company_name
                    : "";

                this.projects.push({
                    _id: project._id,
                    title,
                    status,
                    description,
                    lastUpdate: new Date(project.updated_at),
                    startDate: projectStartDate,
                    completedActivities: completedActivitiesCount,
                    totalActivities: totalActivities,
                    check: true,
                    openInfo: false,
                    mainExecutor,
                    totalValue,
                });
            }
        },
    },
};
</script>

<style>
.tableValues {
    border-radius: 10px;
    border: 1px solid #b6b4b7;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.tableValues table {
    font-size: 0.8rem;
    width: 100%;
    overflow: hidden;
}

.tableValues table thead,
.tableValues table tfoot {
    background-color: #e1dfe2;
    text-transform: uppercase;
}

.tableValues table thead {
    border-bottom: 1px solid #b6b4b7;
}

.tableValues table tfoot {
    border-top: 1px solid #b6b4b7;
}

.tableValues table tbody tr:nth-of-type(2n) {
    background-color: rgba(0, 0, 0, 0.05);
}

.tableValues table td,
.tableValues table th {
    padding: 8px;
}

.tableValues table tr {
    padding: 8px;
    border-bottom: 1px solid #b6b4b7;
}

.tableValues table tr:last-child {
    border-bottom: 0;
}

canvas {
    padding: 0 20px;
}

.txt-titulo select {
    width: 385px;
    margin-bottom: 1rem;
    font-weight: 600;
    background-color: transparent;
    outline: 0;
    padding: 8px 20px 8px 10px;
    border: 1px solid #b6b4b7;
    border-radius: 5px;
}
</style>
