<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">{{ $t("activities") }}</div>
            <div v-for="(item, index) in activities" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("activities") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-5 gap-4">
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("start_month")
                        }}</label>
                        <input
                            type="number"
                            class="sty-input"
                            v-model="item.start_month"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("final_month")
                        }}</label>
                        <input
                            type="number"
                            class="sty-input"
                            v-model="item.end_month"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 my-4">
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("detailing") }}
                            <sup>max 500 {{ $t("characters") }}</sup></label
                        >
                        <textarea
                            rows="6"
                            class="sty-input"
                            maxlength="500"
                            v-model="item.description"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(item.description, 500)
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                </div>
                <div class="flex justify-end">
                    <div
                        class="w-48 my-1 btn-delete btn-default"
                        @click="activities.splice(index, 1)"
                    >
                        {{ $t("delete_activity") }}
                    </div>
                </div>
                <div class="my-6 separete"></div>
            </div>
            <div class="mt-4">
                <div class="w-48 btn-default" @click="Addactivities">
                    {{ $t("add_activity") }}
                </div>
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            activities: [],

            loading: false,
            nextActivityNumber: 1,
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (data != null && data.hasOwnProperty("activities")) {
            this.activities = data.activities;
        }
    },
    methods: {
        Addactivities() {
            this.activities.push({
                description: "",
                start_month: null,
                end_month: null,
                name: "ATV. " + this.nextActivityNumber,
                progress: 0,
            });
            this.nextActivityNumber++;
        },
        async handleNextPage() {
            this.loading = true;
            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};

            parsedData.activities = this.activities;
            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
    },
};
</script>
