<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-position" v-if="openModal">
            <div class="card-modal">
                <div
                    class="flex flex-row justify-between px-3 py-2 border-separete"
                >
                    <div class="title-modal">
                        {{ $t("add_status") }}
                    </div>
                    <div
                        class="flex items-center justify-center"
                        @click="closeModal"
                    >
                        <Icon
                            type="x-circle"
                            class="w-8 h-8 text-gray-600 cursor-pointer hover:text-gray-800"
                        />
                    </div>
                </div>
                <div class="p-2">
                    <div class="grid grid-cols-2 gap-3 card-body">
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("period")
                            }}</label>
                            <input
                                type="text"
                                maxlength="7"
                                v-imask="maskDate"
                                class="input-modal input-date"
                                v-model="item.period"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("fulfilled_value")
                            }}</label>
                            <div class="input-modal">
                                <input
                                    type="text"
                                    class="input-value outline-none"
                                    v-model="item.value"
                                    @input="onInput"
                                />
                            </div>
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("percentage_activities")
                            }}</label>
                            <div class="input-modal">
                                <input
                                    type="text"
                                    maxlength="3"
                                    @input="validateInput()"
                                    class="outline-none bg-transparent"
                                    v-model="item.percentage"
                                />
                                <span>%</span>
                            </div>
                        </div>

                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("activities")
                            }}</label>
                            <select class="input-modal" v-model="item.activity">
                                <option v-for="(act, _) in act" :value="act">
                                    {{ $t(act.name) }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="grid grid-cols-1 px-2">
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("category")
                            }}</label>
                            <select class="input-modal" v-model="item.category">
                                <option
                                    v-for="(ctg, _) in categories"
                                    :value="ctg"
                                >
                                    {{ $t(ctg) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="flex flex-row justify-end py-4">
                        <div class="btn-default" @click="closeModal">
                            {{ $t("cancel") }}
                        </div>
                        <div
                            class="btn-default btn-selected"
                            @click="saveStatusReport"
                        >
                            {{ $t("save") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        Icon,
    },
    props: {
        projectId: String,
        act: Object,
    },
    data() {
        return {
            store,
            categories: [
                "team",
                "consumables_expenses",
                "tickets_expenses",
                "rates_and_costs_expenses",
                "service_expenses",
                "tib_service_expenses",
                "prototype_expenses",
                "construction_and_installation_expenses",
                "equipment_expenses",
                "rights_and_properties_expenses",
                "costs_expenses",
                "supply_capacity_expenses",
                "scholarship_expenses",
                "allowance_expenses",
                "tests_expenses",
                "importation_expenses",
                "operational_expenses",
                "refund_expenses",
                "taxes_expenses",
            ],
            item: {
                period: "",
                value: null,
                category: "",
                percentage: "",
                activity: "",
            },
            openModal: false,
            maskDate: {
                mask: "00/0000",
                lazy: true,
            },
        };
    },
    created() {
        this.emitter.on("openModaAddStatus", (val) => {
            this.openModal = val;
        });
    },
    methods: {
        async saveStatusReport() {
            const authHeader = store.getAuthHeader();

            const newStatusReport = {
                period: this.item.period,
                value:
                    this.item.value === null
                        ? null
                        : this.store.formatValueToSave(this.item.value),
                category: this.item.category,
            };
            try {
                const act = await axios(
                    `${store.BACKEND_URL}/api/projects/${this.projectId}/activities/${this.item.activity.id}/`,
                    {
                        method: "PUT",
                        headers: authHeader,
                        data: {
                            progress: this.item.percentage,
                        },
                        withCredentials: false,
                    }
                );
                const res = await axios.post(
                    `${store.BACKEND_URL}/api/projects/${this.projectId}/status-reports/`,
                    newStatusReport,
                    {
                        headers: authHeader,
                        withCredentials: false,
                    }
                );
                if (res.status == 201) {
                    this.item = {
                        period: "",
                        value: null,
                        category: "",
                        percentage: "",
                        activity: "",
                    };
                    this.$toast.success(
                        this.$t("status_report_saved_successfully")
                    );
                    this.emitter.emit("newStatusReport", newStatusReport);
                } else if (res.status >= 300) {
                    this.$toast.error(
                        "Something went wrong retrieving status reports."
                    );
                    return;
                }
            } catch (err) {
                console.log(err);
            }

            this.closeModal();
        },
        closeModal() {
            this.openModal = false;
            this.item = {
                period: "",
                value: null,
                category: "",
            };
            this.emitter.emit("updateTableStatus", true);
        },

        formatCurrency(value) {
            if (!value) return "";
            value = value.replace(/\D/g, "");
            let numberValue = parseInt(value) / 100;
            return numberValue.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },
        onInput(event) {
            let formattedValue = this.formatCurrency(event.target.value);
            this.item.value = formattedValue;
        },
        validateInput() {
            const value = parseFloat(this.item.percentage);
            if (value > 100) {
                this.item.percentage = 100;
            } else if (value < 0 || isNaN(value)) {
                this.item.percentage = "";
            } else {
                this.item.percentage = parseFloat(this.item.percentage);
            }
        },
    },
};
</script>
<style>
.input-date {
    cursor: text;
}

.input-value {
    background-color: transparent;
    outline: none;
}
</style>
