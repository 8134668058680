<template>
    <div class="flex flex-col justify-evenly">
        <div class="txt-titulo">{{ $t("financial_s_curse") }}</div>
        <Line :data="chartData" :options="chartOptions" />
    </div>
</template>

<script>
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import { format } from "date-fns";
import { store } from "@/store";
import axios from "axios";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default {
    components: { Line },
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            chartOptions: {
                responsive: true,
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        position: "bottom",
                        labels: {
                            font: {
                                size: 14,
                            },
                            usePointStyle: true,
                            pointStyle: "circle",
                            boxWidth: 8,
                            boxHeight: 8,
                        },
                    },
                    filler: {
                        propagate: true,
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            font: {
                                size: 10.8,
                            },
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
        };
    },

    beforeCreate() {
        this.emitter.on("projCurve", (value) => {
            this.selectedProjects = [];
            this.selectedProjects.push(value);
        });
    },

    async beforeMount() {
        await this.getFinancialSCurve();
        this.loaded = true;
    },

    computed: {
        chartData() {
            let filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            filteredProjects =
                filteredProjects.length > 0 ? filteredProjects[0] : null;

            let labels = [];
            let expectedData = [];
            let realData = [];
            let estimateddData = [];
            if (filteredProjects) {
                labels = filteredProjects.months;
                expectedData = filteredProjects.expected_costs;
                realData = filteredProjects.actual_costs;
                estimateddData = filteredProjects.estimated_costs;
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Previsto Acumulado",
                        backgroundColor: "rgba(183, 120, 162)",
                        borderColor: "#B778A2",
                        data: expectedData,
                        tension: 0.4,
                    },
                    {
                        label: "Real Acumulado",
                        backgroundColor: "rgba(237, 117, 69)",
                        borderColor: "#ed7545",
                        data: realData,
                        tension: 0.4,
                    },
                    {
                        label: "Estimado Acumulado",
                        backgroundColor: "rgba(108, 214, 170)",
                        borderColor: "#6cd6aa",
                        data: estimateddData,
                        tension: 0.4,
                    },
                ],
            };
        },
    },

    methods: {
        async getFinancialSCurve() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length != 0) {
                return;
            }
            let res;
            try {
                res = await axios.get(
                    `${store.BACKEND_URL}/api/statistics/financial-s-curve/`,
                    {
                        withCredentials: false,
                        headers: authHeader,
                    }
                );
            } catch (er) {
                console.log(er);
                return;
            }

            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving financial information."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
        },
    },
};
</script>
<style>
.txt-titulo {
    margin-bottom: 1rem;
    font-weight: 600;
}

canvas {
    width: 100% !important;
}

.title {
    margin-bottom: 1rem;
    font-weight: 600;
}
</style>
