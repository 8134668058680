<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{
                    $t(
                        "expenses_for_materials_components_and_services_for_prototype_or_pilot_unit"
                    )
                }}
            </div>
            <div v-for="(item, index) in prototype_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("type_of_expense")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.type"
                        >
                            <option
                                value="Material ou componente - protótipo ou unidade piloto"
                            >
                                {{
                                    $t(
                                        "material_or_component_prototype_or_pilot_unit"
                                    )
                                }}
                            </option>
                            <option
                                value="Serviço de terceiro - protótipo ou unidade piloto"
                            >
                                {{
                                    $t(
                                        "third_party_service_prototype_or_pilot_unit"
                                    )
                                }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("origin")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.origin"
                        >
                            <option value="Nacional">
                                {{ $t("national") }}
                            </option>
                            <option value="Importado">
                                {{ $t("imported") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("item_description") }}
                            <sup class="normal-case"
                                >max 80 {{ $t("caracters") }}</sup
                            ></label
                        >
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.description"
                            maxlength="80"
                        />
                        <small
                            >{{
                                store.remainingCharacters(item.description, 80)
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup class="normal-case"
                                >max 300 {{ $t("caracters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("quantity")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.quantity"
                            @input="onQuantityInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("unit_price")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.unit_value"
                            @input="onInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("total_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input cursor-default"
                            placeholder="R$"
                            v-model="item.value"
                            readonly
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="removePrototype(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="my-4">
                <div class="w-48 btn-default" @click="addExpense">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t(
                        "total_value_of_expenses_for_basic_industrial_technology_services"
                    )
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValue"
                    readonly
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    watch: {
        materialExpenses: {
            handler: "updateTotalValue",
            deep: true,
        },
    },

    data() {
        return {
            store,
            prototype_expenses: [],
            totalValue: "",

            loading: false,
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (data != null && data.hasOwnProperty("prototype_expenses")) {
            this.prototype_expenses = data.prototype_expenses.map(
                (expense) => ({
                    ...expense,
                    value: this.store.formatMoney(expense.value),
                    unit_value: this.store.formatMoney(expense.unit_value),
                    origin: this.store.formatText(expense.origin),
                    type: this.store.formatText(expense.type),
                })
            );
            this.updateTotalValue();
        }
    },
    methods: {
        addExpense() {
            this.prototype_expenses.push({
                type: "",
                description: "",
                company_name: "",
                justification: "",
                origin: "",
                unit_value: null,
                quantity: null,
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};
            parsedData.prototype_expenses = this.prototype_expenses.map(
                (act) => {
                    return {
                        ...act,
                        value: this.store.formatValueToSave(act.value),
                        unit_value: this.store.formatValueToSave(
                            act.unit_value
                        ),
                    };
                }
            );

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));
            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.prototype_expenses[index]) {
                this.prototype_expenses[index].unit_value = formattedValue;
                this.calculateTotalValue(index);
            }
        },
        onQuantityInput(index, event) {
            if (this.prototype_expenses[index]) {
                this.prototype_expenses[index].quantity = event.target.value;
                this.calculateTotalValue(index);
            }
        },
        calculateTotalValue(index) {
            let item = this.prototype_expenses[index];
            let quantity = parseFloat(item.quantity) || 0;
            let unitValue = this.store.parseCurrency(item.unit_value) / 100;
            let totalValue = quantity * unitValue;
            item.value = this.store.formatCurrency(
                (totalValue * 100).toString()
            );
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.prototype_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        removePrototype(index) {
            this.prototype_expenses.splice(index, 1);
            this.updateTotalValue();
        },
    },
};
</script>
