<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{
                    $t(
                        "expenses_related_to_acquisition_of_other_permanent_assets_not_infrastructure"
                    )
                }}
            </div>
            <div v-for="(item, index) in rightsExpenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("type_of_asset_or_right")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.type"
                        >
                            <option value="Software">Software</option>
                            <option value="Material bibliográfico">
                                {{ $t("bibliographic_material") }}
                            </option>
                            <option
                                value="Dado geológico, geoquímico ou geofísico público"
                            >
                                {{
                                    $t(
                                        "public_geological_geochemical_geophysical_data"
                                    )
                                }}
                            </option>
                            <option value="Dado não regulado pela ANP">
                                {{ $t("data_not_regulated_by_ANP") }}
                            </option>
                            <option value="Outros">{{ $t("other") }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">
                            {{ $t("origin") }}
                        </label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.origin"
                        >
                            <option value="Nacional">
                                {{ $t("national") }}
                            </option>
                            <option value="Importado">
                                {{ $t("imported") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("specification_of_asset_or_right") }}
                            <sup class="normal-case"
                                >max 80 {{ $t("characters") }}</sup
                            >
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.right_or_property"
                            maxlength="80"
                        />
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.right_or_property,
                                    80
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup class="normal-case"
                                >max 300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("quantity") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.quantity"
                            @input="onQuantityInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("unit_price")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.unit_value"
                            @input="onInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("total_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input cursor-default"
                            placeholder="R$"
                            v-model="item.value"
                            readonly
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="removePrototype(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
            </div>
            <div class="my-4 separete"></div>
            <div class="mt-4">
                <div class="w-48 btn-default" @click="addRightsExpenses">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="my-4 separete"></div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t(
                        "total_value_of_expenses_related_to_construction_work_or_installation"
                    )
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValue"
                    readonly
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            rightsExpenses: [],
            totalValue: "",
            loading: false,
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (
            data != null &&
            data.hasOwnProperty("rights_and_properties_expenses")
        ) {
            this.rightsExpenses = data.rights_and_properties_expenses.map(
                (re) => ({
                    ...re,
                    value: this.store.formatMoney(re.value),
                    unit_value: this.store.formatMoney(re.unit_value),
                    origin: this.store.formatText(re.origin),
                    type: this.store.formatText(re.type),
                })
            );
            this.updateTotalValue();
        }
    },
    methods: {
        addRightsExpenses() {
            this.rightsExpenses.push({
                company_name: "",
                type: "",
                origin: "",
                right_or_property: "",
                justification: "",
                unit_value: null,
                quantity: null,
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};
            parsedData.rights_and_properties_expenses = this.rightsExpenses.map(
                (act) => {
                    return {
                        ...act,
                        value: this.store.formatValueToSave(act.value),
                        unit_value: this.store.formatValueToSave(
                            act.unit_value
                        ),
                    };
                }
            );

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));
            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },

        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.rightsExpenses[index]) {
                this.rightsExpenses[index].unit_value = formattedValue;
                this.calculateTotalValue(index);
            }
        },
        onQuantityInput(index, event) {
            if (this.rightsExpenses[index]) {
                this.rightsExpenses[index].quantity = event.target.value;
                this.calculateTotalValue(index);
            }
        },
        calculateTotalValue(index) {
            let item = this.rightsExpenses[index];
            let quantity = parseFloat(item.quantity) || 0;
            let unitValue = this.store.parseCurrency(item.unit_value) / 100;
            let totalValue = quantity * unitValue;
            item.value = this.store.formatCurrency(
                (totalValue * 100).toString()
            );
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.rightsExpenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        removePrototype(index) {
            this.rightsExpenses.splice(index, 1);
            this.updateTotalValue();
        },
    },
};
</script>
