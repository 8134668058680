<template>
    <div>
        <div class="navbar" ref="menuContainer">
            <div class="bg-profile">
                <MenuAvatar />
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

import MenuAvatar from "@/components/MenuAvatar.vue";
export default {
    components: { Icon, MenuAvatar },
    data() {
        return {
            store,
            droplang: false,
        };
    },
    mounted() {
        document.addEventListener("click", this.closeLang);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeLang);
    },
    computed: {
        dropMenuStyles() {
            return {
                "max-height": this.droplang ? "1000px" : "0",
                opacity: this.droplang ? "1" : "0",
            };
        },
    },
    methods: {
        openMenu() {
            this.droplang = !this.droplang;
        },
        switchLanguage(lang) {
            this.$i18next.changeLanguage(lang);
        },
        closeLang(event) {
            if (
                this.$refs.menuContainer &&
                !this.$refs.menuContainer.contains(event.target)
            ) {
                this.droplang = false;
            }
        },
    },
};
</script>
