<template>
    <div class="flex flex-row">
        <Sidebar />
        <div>
            <Navbar />
            <div class="main-page">
                <div class="page-title">{{ $t("account_settings") }}</div>
                <div class="card-grap">
                    <div class="flex flex-row mb-4">
                        <div v-for="(btn, idbtn) of btnInfo" :key="idbtn">
                            <div
                                class="btn-default"
                                :class="btn.selected ? 'btn-selected' : ''"
                                @click="selectInfo(btn)"
                            >
                                {{ $t(btn.name) }}
                            </div>
                        </div>
                    </div>
                    <div class="box-default" v-show="active('my_account')">
                        <MyAccount />
                    </div>
                    <div class="box-default" v-show="active('user')"></div>
                    <div
                        class="box-default"
                        v-show="active('notification')"
                    ></div>
                </div>
            </div>
        </div>
        <ModalRestPass />
    </div>
</template>
<script>
import { store } from "@/store";
import Sidebar from "../Sidebar.vue";
import Navbar from "../Navbar.vue";

import MyAccount from "../../components/settings/MyAccount.vue";
import ModalRestPass from "../../components/modal/ModalRestPassword.vue";

export default {
    components: {
        Sidebar,
        Navbar,
        MyAccount,
        ModalRestPass,
    },
    data() {
        return {
            store: store,
            btnInfo: [
                { name: "my_account", selected: true },
                // { name: "user", selected: false },
                // { name: "notification", selected: false }
            ],
            showComp: "my_account",
        };
    },
    methods: {
        selectInfo(btn) {
            this.showComp = btn.name;
            for (const b of this.btnInfo) {
                if (b.name === btn.name) {
                    b.selected = true;
                } else {
                    b.selected = false;
                }
            }
        },
        active: function (val) {
            return this.showComp === val;
        },
        actButton(val) {
            this.showAction = val;
        },
        activate(val) {
            return this.showAction === val;
        },
    },
};
</script>
<style></style>
