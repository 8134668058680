<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">{{ $t("execution_team") }}</div>
            <div v-for="(item, indexTd) in team_data" :key="indexTd">
                <div class="subtitle-ptr">
                    {{ $t("member_of_the_execution_team") }} {{ indexTd + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("name_or_identification") }}
                            <sup>max 100 {{ $t("characters") }}</sup>
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                            maxlength="100"
                        />
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.company_name,
                                    100
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("function")
                        }}</label>
                        <select class="sty-input" v-model="item.role">
                            <option value="Coordenador">
                                {{ $t("coordinator") }}
                            </option>
                            <option value="Pesquisador">
                                {{ $t("researcher") }}
                            </option>
                            <option value="Pesquisador visitante">
                                {{ $t("visiting_researcher") }}
                            </option>
                            <option value="Técnico">
                                {{ $t("technician") }}
                            </option>
                            <option value="Bolsista - graduado">
                                {{ $t("scholarship_holder") }}
                            </option>
                            <option value="Bolsista - mestrado">
                                {{ $t("scholarship_master") }}
                            </option>
                            <option value="Bolsista - doutorado">
                                {{ $t("scholarship_doctorate") }}
                            </option>
                            <option value="Bolsista - Pós-doutorado">
                                {{ $t("scholarship_postdoctoral") }}
                            </option>
                            <option value="Outros">{{ $t("other") }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("formation") }}
                            <sup>max 80 {{ $t("characters") }}</sup></label
                        >
                        <input
                            type="text"
                            class="sty-input"
                            maxlength="80"
                            v-model="item.background"
                        />
                        <small
                            >{{
                                store.remainingCharacters(item.background, 80)
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("period") }}
                            <sup class="normal-case"
                                >({{ $t("in_months") }})</sup
                            ></label
                        >
                        <input
                            type="number"
                            class="sty-input"
                            v-model="item.contract_period"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">CPF</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-imask="maskCpf"
                            v-model="item.cpf"
                        />
                    </div>

                    <div
                        class="flex flex-col gap-4 col-span-3"
                        v-if="activities.length > 0"
                    >
                        <div class="label-ptr">
                            {{ $t("activities_to_participate_in_project") }}
                        </div>
                        <div class="grid lg:grid-cols-6 gap-4">
                            <div
                                v-for="(act, index) in item.activities"
                                :key="index"
                            >
                                <div
                                    class="card-add-check"
                                    :class="{
                                        'bg-green-300 text-black font-bold':
                                            act.checked,
                                    }"
                                >
                                    <div
                                        class="box-select"
                                        @click="btnActivity(act, item, indexTd)"
                                    >
                                        <Icon
                                            type="check"
                                            class="w-4 h-4 text-black"
                                            v-if="act.checked"
                                        />
                                    </div>
                                    <div class="mx-2">{{ act.name }}</div>
                                    <div
                                        @click="
                                            btnShowInfo(act, index, indexTd)
                                        "
                                        class="cursor-pointer"
                                    >
                                        <Icon
                                            type="arrow-up"
                                            class="w-8 ml-4"
                                            :class="
                                                !act.showInfo
                                                    ? 'rotate-up'
                                                    : 'rotate-down'
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="showDetails"
                            class="card-show-act grid grid-cols-3 gap-4 overflow-hidden transition-all duration-500"
                            :style="{
                                'max-height': showDetails ? '1000px' : '0',
                                opacity: showDetails ? '1' : '0',
                            }"
                        >
                            <div class="flex flex-col">
                                <label for="title" class="label-ptr">{{
                                    $t("activity_mame")
                                }}</label>
                                <div class="sty-input bg-gray-200">
                                    {{ activeActivity?.name }}
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <label for="title" class="label-ptr">{{
                                    $t("start_date")
                                }}</label>
                                <div class="sty-input bg-gray-200">
                                    {{ activeActivity?.start_month }}
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <label for="title" class="label-ptr">{{
                                    $t("final_date")
                                }}</label>
                                <div class="sty-input bg-gray-200">
                                    {{ activeActivity?.end_month }}
                                </div>
                            </div>
                            <div class="flex flex-col col-span-3">
                                <label for="title" class="label-ptr">{{
                                    $t("activity_description")
                                }}</label>
                                <div class="sty-input bg-gray-200">
                                    {{ activeActivity?.description }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="team_data.splice(index, 1)"
                    >
                        {{ $t("remove_member") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="" @click="addTeam">
                <div class="w-48 btn-default">{{ $t("add_member") }}</div>
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";
import axios from "axios";

export default {
    components: {
        Icon,
    },
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            team_data: [
                {
                    company_name: "",
                    name: "",
                    role: "",
                    justification: "",
                    background: "",
                    contract_period: null,
                    cpf: "",
                    total_hours: 1000,
                    total_payment: 10000,
                    total_benefits: 1000,
                    activities: [],
                },
            ],
            showDetails: false,
            user_activities: "",
            maskCpf: {
                mask: "000.000.000-00",
                lazy: true,
            },
            activeActivity: null,
            activities: [],
            loading: false,
            ptrDatas: [],
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];

        if (data && data.activities) {
            this.activities = data.activities.map((activity) => {
                return {
                    ...activity,
                    checked: false,
                    showInfo: false,
                };
            });
        }

        if (data && data.team) {
            this.team_data = data.team.map((team) => {
                const updatedActivities = this.activities.map((activity) => {
                    return {
                        ...activity,
                        checked: team.activities.includes(activity.name),
                    };
                });

                return {
                    ...team,
                    role: this.store.formatText(team.role),
                    activities: updatedActivities,
                };
            });
        }
    },
    methods: {
        addTeam() {
            this.team_data.push({
                company_name: "",
                name: "",
                role: "",
                justification: "",
                background: "",
                contract_period: null,
                payment_type: "STRING",
                cpf: "",
                total_hours: 1000,
                total_payment: 10000,
                total_benefits: 1000,
                activities: [],
            });
        },
        btnShowInfo(act, index, indexTd) {
            const targetActivities = this.team_data[indexTd].activities;
            for (const activity of targetActivities) {
                activity.showInfo = false;
            }

            if (this.activeActivity === act) {
                this.activeActivity = null;
                this.showDetails = false;
            } else {
                this.activeActivity = act;
                act.showInfo = true;
                this.showDetails = true;
            }
        },

        btnActivity(act, item, indexTd) {
            act.checked = !act.checked;
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};

            parsedData.team = this.team_data;
            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
    },
};
</script>
