<template>
    <div>
        <div class="txt-titulo">
            <select
                v-model="selectedProject"
                class="truncate full-select cursor-pointer"
                @change="handleSelection"
            >
                <option v-for="(ts, tsId) in projects" :value="ts">
                    {{ store.formatText(ts.title) }}
                </option>
            </select>
        </div>
        <div class="gap-4 grid xl:grid-cols-2">
            <!-- <div class="rounded-lg card-graficos box-default border-none">
                <CurveSfinancial />
            </div> -->
            <div class="rounded-lg card-graficos box-default border-none">
                <ActivityScurve />
            </div>
        </div>
    </div>
</template>
<script>
import CurveSfinancial from "@/components/dashboards/CurveSfinancial.vue";
import ActivityScurve from "@/components/dashboards/ActivityScurve.vue";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        CurveSfinancial,
        ActivityScurve,
    },
    data() {
        return {
            selectedProject: "",
            titleSelected: "",
            projects: [],
            store,
        };
    },
    async beforeMount() {
        await this.getProjects();
    },
    methods: {
        handleSelection() {
            this.emitter.emit("projCurve", this.selectedProject._id);
            this.titleSelected = this.selectedProject.title;
        },
        async getProjects() {
            const authHeader = store.getAuthHeader();
            if (!authHeader) {
                localStorage.clear();
                this.$router.push("/");
                return;
            }

            let res;
            try {
                res = await axios.get(`${store.BACKEND_URL}/api/projects/`, {
                    headers: authHeader,
                });
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            if (res.status != 200) {
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            for (const project of res.data) {
                const base = project.base;
                const institutionalData = project.institutional_data;

                const title = base.title;
                const description = project.technical_data.goal;
                const projectStartDate = new Date(base.contract_date);
                const totalActivities = project.activities.length;

                const completedActivitiesCount = project.activities.reduce(
                    (acc, activity) => {
                        return acc + (activity.progress == 100 ? 1 : 0);
                    },
                    0
                );
                const status =
                    completedActivitiesCount == totalActivities
                        ? "Completo"
                        : "Em andamento";

                const totalValue = store.calculateProjectTotalExpenses(project);

                const mainExecutor = institutionalData.executor_companies.length
                    ? institutionalData.executor_companies[0].company_name
                    : "";

                this.projects.push({
                    _id: project._id,
                    title,
                    status,
                    description,
                    lastUpdate: new Date(project.updated_at),
                    startDate: projectStartDate,
                    completedActivities: completedActivitiesCount,
                    totalActivities: totalActivities,
                    check: true,
                    openInfo: false,
                    mainExecutor,
                    totalValue,
                });
            }
            this.selectedProject = this.projects[0];
            if (this.selectedProject != undefined) {
                this.titleSelected = this.selectedProject.title;
            }
        },
    },
};
</script>
