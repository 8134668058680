<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">{{ $t("institutional_data") }}</div>
            <div class="flex flex-col items-start">
                <div class="subtitle-ptr">
                    {{ $t("oil_plan") }}
                </div>
                <div class="flex flex-col w-96">
                    <label for="title" class="label-ptr">CNPJ</label>
                    <input
                        type="text"
                        class="sty-input"
                        v-imask="maskCnpj"
                        v-model="obj.workPlaPresenterCnpj"
                        maxlength="18"
                    />
                </div>
            </div>
            <div class="my-6 separete"></div>
            <div class="flex flex-col">
                <div class="subtitle-ptr">
                    {{ $t("oil_proponents") }}
                </div>
                <div
                    class="grid grid-cols-4 gap-4"
                    v-for="(prp, prpId) in obj.proponents"
                    :key="prpId"
                >
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("corporate_reason")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="prp.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">CNPJ</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-imask="maskCnpj"
                            v-model="prp.cnpj"
                            maxlength="18"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("percentage_resources")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            maxlength="3"
                            @input="validateInput(prp)"
                            v-model="prp.resource_percentage"
                        />
                    </div>
                    <div class="flex items-end">
                        <div
                            class="flex items-center justify-center px-4 py-2 rounded-md cursor-pointer btn-delete"
                            @click="deleteCompany(prpId)"
                        >
                            <div class="text-red-700">
                                <Icon type="trash" class="w-6 h-6" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 btn-default" @click="addCompany">
                    {{ $t("add_company") }}
                </div>

                <div class="my-6 separete"></div>
                <div class="subtitle-ptr">
                    {{ $t("institutions_clause") }}
                </div>
                <div class="text-gray-400">*{{ $t("required_executor") }}</div>

                <div v-for="(ins, insId) in obj.executorCompanies" :key="insId">
                    <div class="grid grid-cols-4 gap-4 mt-6">
                        <div class="flex flex-col">
                            <label for="title" class="label-ptr">{{
                                $t("corporate_reason")
                            }}</label>
                            <input
                                type="text"
                                class="sty-input"
                                v-model="ins.company_name"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="title" class="label-ptr">CNPJ</label>
                            <input
                                type="text"
                                class="sty-input"
                                v-imask="maskCnpj"
                                v-model="ins.cnpj"
                                maxlength="18"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label for="title" class="label-ptr">{{
                                $t("company_type")
                            }}</label>
                            <select
                                class="capitalize sty-input"
                                v-model="ins.type"
                            >
                                <option value="Instituição credenciada">
                                    {{ $t("accredited_institution") }}
                                </option>
                                <option value="Empresa petrolífera">
                                    {{ $t("oil_company") }}
                                </option>
                                <option value="Empresa brasileira">
                                    {{ $t("brazilian_company") }}
                                </option>
                                <option
                                    value="Organização de normalização ou equivalente"
                                >
                                    {{ $t("standardization_equivalent") }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="subtitle-ptr">
                        {{ $t("brazilian_company") }}
                    </div>
                    <div class="grid grid-cols-4">
                        <div class="flex flex-col">
                            <label for="title" class="label-ptr">{{
                                $t("port")
                            }}</label>
                            <select
                                class="capitalize sty-input"
                                v-model="ins.size"
                                :class="{
                                    'bg-disabled':
                                        ins.type != 'Empresa brasileira',
                                }"
                                :disabled="ins.type != 'Empresa brasileira'"
                            >
                                <option value="Micro ou pequeno">
                                    {{ $t("micro_small") }}
                                </option>
                                <option value="Médio">
                                    {{ $t("average") }}
                                </option>
                                <option value="Grande">{{ $t("big") }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <div
                            class="flex items-center justify-center px-4 py-2 rounded-md cursor-pointer btn-delete"
                            @click="obj.executorCompanies.splice(insId, 1)"
                        >
                            <div class="text-red-700">Deletar empresa</div>
                        </div>
                    </div>
                    <div class="my-4 separete"></div>
                </div>
                <div class="mt-4">
                    <div class="w-48 btn-default" @click="addCompanyPDi">
                        {{ $t("add_company") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

export default {
    components: {
        Icon,
    },
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            maskCnpj: {
                mask: "00.000.000/0000-00",
                lazy: true,
            },

            obj: {
                workPlaPresenterCnpj: "",
                proponents: [],
                executorCompanies: [],
            },
            ptrDatas: [],
            loading: false,
            authHeader: store.getAuthHeader(),
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];

        if (data != null && data.hasOwnProperty("institutional_data")) {
            this.obj.executorCompanies =
                data.institutional_data.executor_companies.map((executor) => {
                    return {
                        ...executor,
                        type: this.store.formatText(executor.type),
                        cnpj: this.store.formatCNPJ(executor.cnpj),
                        size: this.store.formatText(executor.size),
                    };
                });
        }
        if (data != null && data.hasOwnProperty("institutional_data")) {
            this.obj.proponents = data.institutional_data.proponents.map(
                (prop) => {
                    return {
                        ...prop,
                        cnpj: this.store.formatCNPJ(prop.cnpj),
                    };
                }
            );
        }
        if (data != null && data.hasOwnProperty("institutional_data")) {
            this.obj.workPlaPresenterCnpj = this.store.formatCNPJ(
                data.institutional_data.work_plan_presenter_cnpj
            );
        }
    },

    methods: {
        validateInput(prp) {
            const value = parseFloat(prp.resource_percentage);
            if (value > 100) {
                prp.resource_percentage = 100;
            } else {
                prp.resource_percentage = parseFloat(prp.resource_percentage);
            }
        },

        addCompany() {
            this.obj.proponents.push({
                company_name: "",
                cnpj: "",
                resource_percentage: null,
            });
        },
        addCompanyPDi() {
            this.obj.executorCompanies.push({
                company_name: "",
                cnpj: "",
                type: "",
                size: "",
                visibility: "public",
                profitable: true,
                research_unit: "string",
                credential_number: "string",
                cpf: "",
            });
        },
        deleteCompany(prpId) {
            this.obj.proponents.splice(prpId, 1);
        },
        async handleNextPage() {
            this.loading = true;

            let instDatas = {};
            instDatas.executor_companies = this.obj.executorCompanies.map(
                (executor) => {
                    return {
                        ...executor,
                        cnpj: executor.cnpj.replace(/\D/g, ""),
                    };
                }
            );
            instDatas.proponents = this.obj.proponents.map((proponent) => {
                return {
                    ...proponent,
                    cnpj: proponent.cnpj.replace(/\D/g, ""),
                };
            });
            instDatas.work_plan_presenter_cnpj =
                this.obj.workPlaPresenterCnpj.replace(/\D/g, "");

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};
            parsedData.institutional_data = parsedData.institutional_data || [];
            parsedData.institutional_data = instDatas;

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1500);
        },
    },
};
</script>
