<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{ $t("ancillary_import_expenses") }}
            </div>
            <div v-for="(item, index) in importation_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("description") }}
                            <sup class="normal-case"
                                >max 300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.description"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(item.description, 300)
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col col-span-2">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup class="normal-case"
                                >max 300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("declared_value") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.value"
                            @input="onInput(index, $event)"
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="deleteRefund(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="my-4">
                <div class="w-48 btn-default" @click="addImportationExpenses">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t("total_value_of_ancillary_import_expenses")
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValue"
                    readonly
                />
            </div>

            <!-- Despesas operacionais e administrativas -->
            <div class="my-8 separete"></div>

            <div class="title-form-ptr">
                {{ $t("operational_and_administrative_expenses") }}
            </div>
            <div v-for="(item, index) in operational_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("calculation_base_brl") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.base"
                            @input="onInputOp(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("declared_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.declared"
                            @input="onInputDec(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("declared_percentage_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input cursor-default"
                            v-model="item.percentage"
                            readonly
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("admissible_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input cursor-default"
                            placeholder="R$"
                            v-model="item.base"
                            readonly
                        />
                    </div>
                </div>
                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="deleteOperational(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>

            <div class="my-4">
                <div class="w-48 btn-default" @click="addOperationalExpenses">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t("total_value_of_operational_expenses")
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValueOp"
                    readonly
                />
            </div>

            <div class="my-8 separete"></div>

            <!-- Ressarcimento de custos indiretos -->
            <div class="title-form-ptr">
                {{ $t("reimbursement_of_indirect_costs") }}
            </div>
            <div v-for="(item, index) in refund_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("calculation_base_brl") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.base"
                            @input="onInputCompany(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("declared_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.declared"
                            @input="onInputDecCompany(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("declared_percentage_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="%"
                            v-model="item.percentage"
                            readonly
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("admissible_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input cursor-default"
                            placeholder="R$"
                            v-model="item.declared"
                            readonly
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="deleteCompany(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>

            <div class="my-4">
                <div class="w-48 btn-default" @click="addRefundExpenses">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="my-4 separete"></div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t("total_value_of_reimbursement_of_indirect_costs")
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValueCompany"
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            importation_expenses: [
                {
                    company_name: "",
                    description: "",
                    justification: "",
                    value: null,
                },
            ],
            operational_expenses: [
                {
                    company_name: "",
                    base: null,
                    declared: null,
                    percentage: null,
                    value: null,
                },
            ],
            refund_expenses: [
                {
                    company_name: "",
                    base: null,
                    declared: null,
                    percentage: null,
                    value: null,
                },
            ],

            totalValue: "",
            totalValueOp: "",
            totalValueCompany: "",
            loading: false,
            authHeader: store.getAuthHeader(),
            ptrDatas: [],
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];

        if (data != null && data.hasOwnProperty("refund_expenses")) {
            this.refund_expenses = data.refund_expenses.map((refund) => ({
                ...refund,
                value: this.store.formatMoney(refund.value),
                base: this.store.formatMoney(refund.base),
                declared: this.store.formatMoney(refund.declared),
                percentage: Number(refund.percentage.toFixed(2)) + "%",
            }));
            this.updateTotalValCompany();
        }

        if (data != null && data.hasOwnProperty("operational_expenses")) {
            this.operational_expenses = data.operational_expenses.map(
                (operational) => ({
                    ...operational,
                    value: this.store.formatMoney(operational.value),
                    base: this.store.formatMoney(operational.base),
                    declared: this.store.formatMoney(operational.declared),
                    percentage: Number(operational.percentage.toFixed(2)) + "%",
                })
            );
            this.updateTotalValOp();
        }

        if (data != null && data.hasOwnProperty("importation_expenses")) {
            this.importation_expenses = data.importation_expenses.map(
                (importation) => ({
                    ...importation,
                    value: this.store.formatCurrency(
                        importation.value.toString()
                    ),
                })
            );
            this.updateTotalValue();
        }
    },
    methods: {
        addImportationExpenses() {
            this.importation_expenses.push({
                company_name: "",
                description: "",
                justification: "",
                value: null,
            });
        },
        addOperationalExpenses() {
            this.operational_expenses.push({
                company_name: "",
                base: null,
                declared: null,
                percentage: null,
                value: null,
            });
        },
        addRefundExpenses() {
            this.refund_expenses.push({
                company_name: "",
                base: null,
                declared: null,
                percentage: null,
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};
            parsedData.refund_expenses = this.refund_expenses.map((refund) => {
                return {
                    ...refund,
                    value: this.store.formatValueToSave(refund.value),
                };
            });
            parsedData.operational_expenses = this.operational_expenses.map(
                (operational) => {
                    return {
                        ...operational,
                        base: this.store.formatValueToSave(operational.base),
                        declared: this.store.formatValueToSave(
                            operational.declared
                        ),
                        value: this.store.formatValueToSave(operational.value),
                    };
                }
            );
            parsedData.importation_expenses = this.importation_expenses.map(
                (importation) => {
                    return {
                        ...importation,
                        value: this.store.formatValueToSave(importation.value),
                    };
                }
            );

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));
            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },

        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.importation_expenses[index]) {
                this.importation_expenses[index].value = formattedValue;
            }
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.importation_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        deleteRefund(index) {
            this.importation_expenses.splice(index, 1);
            this.updateTotalValue();
        },

        calculatePercentage(base, declared) {
            if (!base || !declared) return "";
            let baseValue = this.store.parseCurrency(base);
            let declaredValue = this.store.parseCurrency(declared);
            if (baseValue === 0) return "";
            let percentage = ((declaredValue / baseValue) * 100).toFixed(2);
            return `${percentage}%`;
        },

        onInputOp(index, event) {
            let rawValue = event.target.value.replace(/\D/g, "");
            let formattedValue = this.store.formatCurrency(rawValue);
            this.operational_expenses[index] = {
                ...this.operational_expenses[index],
                base: formattedValue,
            };
            this.operational_expenses[index].percentage =
                this.calculatePercentage(
                    formattedValue,
                    this.operational_expenses[index].declared
                );
        },
        onInputDec(index, event) {
            let rawValue = event.target.value.replace(/\D/g, "");
            let formattedValue = this.store.formatCurrency(rawValue);
            this.operational_expenses[index] = {
                ...this.operational_expenses[index],
                declared: formattedValue,
            };
            this.operational_expenses[index].percentage =
                this.calculatePercentage(
                    this.operational_expenses[index].base,
                    formattedValue
                );
            this.updateTotalValOp();
        },
        updateTotalValOp() {
            let total = this.operational_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.declared);
            }, 0);
            this.totalValueOp = this.store.formatCurrency(total.toString());
        },
        deleteOperational(index) {
            this.operational_expenses.splice(index, 1);
            this.updateTotalValOp();
        },

        onInputCompany(index, event) {
            let rawValue = event.target.value.replace(/\D/g, "");
            let formattedValue = this.store.formatCurrency(rawValue);
            this.refund_expenses[index] = {
                ...this.refund_expenses[index],
                base: formattedValue,
            };
            this.refund_expenses[index].percentage = this.calculatePercentage(
                formattedValue,
                this.refund_expenses[index].declared
            );
        },
        onInputDecCompany(index, event) {
            let rawValue = event.target.value.replace(/\D/g, "");
            let formattedValue = this.store.formatCurrency(rawValue);
            this.refund_expenses[index] = {
                ...this.refund_expenses[index],
                declared: formattedValue,
            };
            this.refund_expenses[index].percentage = this.calculatePercentage(
                this.refund_expenses[index].base,
                formattedValue
            );
            this.updateTotalValCompany();
        },
        updateTotalValCompany() {
            let total = this.refund_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.declared);
            }, 0);
            this.totalValueCompany = this.store.formatCurrency(
                total.toString()
            );
        },
        deleteCompany(index) {
            this.refund_expenses.splice(index, 1);
            this.updateTotalValCompany();
        },
    },
};
</script>
