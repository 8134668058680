<template>
    <div>
        <div class="flex items-end justify-end py-4">
            <div class="mx-8">
                <LanguageSwitcher />
            </div>
        </div>
        <div class="flex items-center justify-center h-[80vh]">
            <form class="box-login" @submit.prevent="login">
                <div class="flex items-center justify-center">
                    <img src="../assets/img/logo.png" />
                    <div class="brand-size brand-ideation">Ideation</div>
                </div>
                <div class="mb-3 text-xl text-center">
                    {{ $t("your_software") }}
                </div>
                <div>
                    <div class="flex flex-col mb-4">
                        <label for="id" class="sty-label">{{
                            $t("user")
                        }}</label>
                        <input
                            type="text"
                            id="id"
                            class="sty-input-form"
                            v-model="formattedCnpjCpf"
                            @input="updateCnpjCpf"
                            @change="updateCnpjCpf"
                            autocomplete="off"
                            maxlength="18"
                        />
                    </div>
                    <div class="flex flex-col mb-4">
                        <label for="pass" class="sty-label">{{
                            $t("password")
                        }}</label>
                        <div class="flex flex-row items-center sty-input-form">
                            <input
                                :type="typeInput"
                                id="pass"
                                class="w-full bg-transparent outline-none"
                                v-model="password"
                            />
                            <Icon
                                :type="typeEye"
                                class="w-5 h-5 cursor-pointer"
                                @click="visivelPass"
                            />
                        </div>
                    </div>
                </div>

                <button class="btn-login" v-if="!loading">
                    {{ $t("login") }}
                </button>
                <button class="btn-login" v-else>
                    <div class="">
                        <Spinner
                            size="h-7 w-7 border-white"
                            class="spinner-loading"
                        />
                    </div>
                </button>

                <div class="flex justify-between my-4">
                    <label
                        class="relative inline-flex items-center cursor-pointer"
                    >
                        <input
                            type="checkbox"
                            value=""
                            class="sr-only peer"
                            @click="stayConnected"
                        />
                        <div
                            class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-sky-500"
                        ></div>
                        <span class="text-sm ms-3 stay-connect">{{
                            $t("stay_connected")
                        }}</span>
                    </label>
                    <div class="text-sm forgot-pass">
                        {{ $t("forgot_pass") }}
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Icon from "@/containers/UI/Icon.vue";

import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import { store } from "@/store/index";
import axios from "axios";
import Spinner from "@/containers/UI/Spinner.vue";

export default {
    components: {
        Icon,
        Spinner,
        LanguageSwitcher,
    },
    data() {
        return {
            store,
            typeInput: "password",
            typeEye: "eye-close",
            username: "",
            password: "",
            mask: {
                mask: "00.000.000/0000-00",
                lazy: true,
            },
            maskCpf: {
                mask: "000.000.000-00",
                lazy: true,
            },
            maskCnpj: {
                mask: "00.000.000/0000-00",
                lazy: true,
            },
            currentMask: null,

            loading: false,
            connected: false,
        };
    },
    computed: {
        formattedCnpjCpf() {
            return this.username.length > 11
                ? this.store.formatCNPJ(this.username)
                : this.store.formatCPF(this.username);
        },
    },
    created() {
        this.updateMask();
    },

    methods: {
        visivelPass() {
            if (this.typeEye == "eye-close") {
                this.typeEye = "eye-open";
                this.typeInput = "text";
            } else {
                this.typeEye = "eye-close";
                this.typeInput = "password";
            }
        },
        stayConnected() {
            this.connected = !this.connected;
        },
        updateMask() {
            const numericValue = this.username.replace(/\D/g, "");
            if (numericValue.length > 11) {
                this.currentMask = this.maskCnpj;
            } else {
                this.currentMask = this.maskCpf;
            }
        },
        updateCnpjCpf(event) {
            const inputValue = event.target.value.replace(/\D/g, "");
            this.username = inputValue;
        },
        async login() {
            this.loading = true;

            let username = this.username.replaceAll(/[.\-\/]/g, "");
            let password = this.password;

            if (!username || !password) {
                this.$toast.error(this.$t("empty_login_fields"));
                this.loading = false;
                return;
            }

            let response;
            try {
                response = await axios.post(
                    `${store.BACKEND_URL}/api/token`,
                    new URLSearchParams({
                        username,
                        password,
                    }),
                    {
                        withCredentials: false,
                        validateStatus: (status) => status < 500,
                    }
                );
                if (response.status == 200) {
                    this.loading = false;
                }
            } catch (error) {
                this.$toast.error(this.$t("something_went_wrong"));
                this.loading = false;
                console.log(error);
                return;
            }

            if (response.status != 200) {
                this.$toast.error(this.$t("failed_to_login"));
                this.loading = false;
                return;
            }

            const tokenData = response.data;
            localStorage.setItem("access-token", tokenData.access_token);
            localStorage.setItem("expiration", tokenData.expires_at);
            this.$router.push("/dashboard");
        },
    },
};
</script>
