<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-bg" v-if="openModal">
            <div class="card-modal fadeIn">
                <div class="flex justify-between">
                    <div class="text-xl capitalize mx-4 mt-2">Deletar PTR</div>
                    <div @click="closeModal">
                        <Icon type="x-circle" class="w-6 h-6 cursor-pointer" />
                    </div>
                </div>
                <div class="my-1 separete"></div>
                <div class="px-4 my-3">
                    <div class="mt-4 mb-8">
                        {{ $t("confirm_delete_ptr") }} "<span
                            class="font-bold"
                            >{{ doc.title }}</span
                        >"?
                    </div>
                    <div class="flex justify-between">
                        <div
                            class="btn-default btn-clear mx-0"
                            @click="closeModal"
                        >
                            {{ $t("cancel") }}
                        </div>
                        <div
                            class="btn-default btn-hover-delete mx-0"
                            @click="deletePtr"
                        >
                            {{ $t("delete") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";
export default {
    components: {
        Icon,
    },
    data() {
        return {
            store,
            openModal: false,
            doc: {},
        };
    },

    created() {
        this.emitter.on("confirmDeletePtr", (doc) => {
            this.openModal = true;
            this.doc = doc;
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;
        },
        async deletePtr() {
            const id = this.doc._id;

            try {
                const res = await axios.delete(
                    `${store.BACKEND_URL}/api/projects/${id}/`,
                    {
                        withCredentials: false,
                        headers: store.getAuthHeader(),
                    }
                );
                if (res.status == 204) {
                    this.$toast.success(
                        this.$t("project_deleted_successfully")
                    );

                    this.emitter.emit("reload projects", true);

                    this.closeModal();
                } else {
                    this.$toast.error(this.$t("error_deleting_project"));
                }
            } catch (err) {
                this.$toast.error(this.$t("error_deleting_project"));
            }
        },
    },
};
</script>
