<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{ $t("expenses_with_the_executing_team") }}
            </div>
            <div v-for="(item, index) in staffExpenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div
                    class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-8"
                >
                    <div class="flex flex-col col-span-2">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.institution"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("identification_of_team_participation")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.participation"
                        />
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup>max 300 {{ $t("characters") }}</sup></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("type_of_remuneration")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.type"
                        >
                            <option value="1">
                                {{ $t("direct_remuneration") }}
                            </option>
                            <option value="2">
                                {{
                                    $t(
                                        "scholarship_teacher_or_researcher_of_institution"
                                    )
                                }}
                            </option>
                            <option value="3">
                                {{
                                    $t(
                                        "scholarship_undergraduate_or_graduate_student"
                                    )
                                }}
                            </option>
                            <option value="4">
                                {{ $t("scholarship_visiting_researcher") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("remuneration_period")
                            }}<sup class="normal-case">{{
                                $t("in_months")
                            }}</sup></label
                        >
                        <input
                            type="number"
                            class="sty-input"
                            v-model="item.period"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("total_hours_dedicated_to_the_project")
                        }}</label>
                        <input
                            type="number"
                            class="sty-input"
                            v-model="item.hours"
                        />
                    </div>
                    <div class="flex flex-col col-span-1">
                        <label for="title" class="label-ptr">{{
                            $t("total_remuneration_amount")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.total"
                            @input="onInput(index, 'total', $event)"
                        />
                    </div>
                    <div class="flex flex-col col-span-1">
                        <label for="title" class="label-ptr">{{
                            $t("total_charges_and_benefits_in_the_project")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.benefits"
                            @input="onInput(index, 'benefits', $event)"
                        />
                    </div>

                    <div class="flex justify-end col-span-3">
                        <div
                            class="w-48 btn-delete btn-default"
                            @click="removeExpense(index)"
                        >
                            {{ $t("delete_expense") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-4 separete"></div>
            <div class="my-4">
                <div class="w-48 btn-default" @click="addStaffExpenses()">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t("total_amount_in_expenses_with_executing_team")
                }}</label>
                <div class="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                    <div class="flex flex-col mt-4">
                        <label for="title" class="label-ptr">{{
                            $t("total_amount_with_remunerations")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            disabled
                            v-model="totalValueRemuneration"
                        />
                    </div>
                    <div class="flex flex-col mt-4">
                        <label for="title" class="label-ptr">{{
                            $t("total_amount_with_charges_and_benefits")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            disabled
                            v-model="totalValueBenefits"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            staffExpenses: [],
            totalValueRemuneration: "",
            totalValueBenefits: "",

            loading: false,
        };
    },
    methods: {
        addStaffExpenses() {
            this.staffExpenses.push({
                institution: "",
                participation: "",
                justification: "",
                type: "",
                period: "",
                hours: "",
                total: "",
                benefits: "",
            });
        },

        handleNextPage() {
            this.nextPage();
        },

        onInput(index, field, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            this.staffExpenses[index][field] = formattedValue;
            this.updateTotalValueRemuneration();
            this.updateTotalValueBenefits();
        },
        updateTotalValueRemuneration() {
            let total = this.staffExpenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.total);
            }, 0);
            this.totalValueRemuneration = this.store.formatCurrency(
                total.toString()
            );
        },
        updateTotalValueBenefits() {
            let total = this.staffExpenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.benefits);
            }, 0);
            this.totalValueBenefits = this.store.formatCurrency(
                total.toString()
            );
        },
        removeExpense(index) {
            this.staffExpenses.splice(index, 1);
            this.updateTotalValueRemuneration();
            this.updateTotalValueBenefits();
        },
    },
};
</script>
