<template>
    <div>
        <div class="flex py-3 my-3 justify-end">
            <!-- <div class="flex flex-col">
                <div class="value-form">{{ $t("commitment") }}</div>
            </div> -->
            <button class="btn-default p-0 text-sm" @click="addCommitment">
                {{ $t("add_commitment") }}
            </button>
        </div>
        <div class="w-full">
            <div class="my-2 overflow-y-auto">
                <table class="min-w-full rounded-table">
                    <thead class="bg-thead-tbl">
                        <tr>
                            <th
                                class="px-4 py-2 cursor-pointer"
                                v-for="(column, index) in columns"
                                :key="index"
                                @click="sortBy(column)"
                            >
                                <div
                                    class="flex items-center row-auto capitalize"
                                >
                                    {{ $t(column.label) }}
                                    <span v-if="column.sortable" class="ml-1">
                                        <Icon
                                            class="w-6 h-6 color-icon"
                                            :type="
                                                sortDirection === 'desc'
                                                    ? 'arrow-up'
                                                    : 'arrow-down'
                                            "
                                        />
                                    </span>
                                </div>
                            </th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody class="b-tbody divide-y divide-gray-300">
                        <tr v-for="(item, id) in paginatedItems" :key="id">
                            <td class="px-4 py-2 size-td">
                                <input
                                    type="text"
                                    :disabled="!item.edit"
                                    v-model="item.value_commitment"
                                    @input="
                                        onInput(id, 'value_commitment', $event)
                                    "
                                    class="p-1 w-10/12 outline-none rounded m-0"
                                    :class="
                                        !item.edit
                                            ? 'border border-transparent bg-transparent'
                                            : 'border border-gray-400 bg-gray-100'
                                    "
                                />
                            </td>

                            <td class="px-4 py-2 size-td">
                                {{ item.referent_year }}
                            </td>

                            <td>
                                <div
                                    @click="editCommitment(item)"
                                    class="cursor-pointer"
                                >
                                    <Icon
                                        :type="item.edit ? 'check' : 'pencil'"
                                        class="w-5 h-5"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="mt-4">
                    <nav class="flex justify-between" v-if="items.length > 0">
                        <div>
                            <span class="mr-2">{{ $t("pages") }}:</span>
                            <button
                                v-for="pageNumber in totalPages"
                                :key="pageNumber"
                                @click="setCurrentPage(pageNumber)"
                                class="pagination"
                                :class="{
                                    'selected-page': pageNumber === currentPage,
                                }"
                            >
                                {{ pageNumber }}
                            </button>
                        </div>
                    </nav>
                    <div class="my-2 text-gray-500" v-if="items.length === 0">
                        {{ $t("no_data") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";
import axios from "axios";

export default {
    props: {
        team: Object,
    },
    components: { Icon },
    data() {
        return {
            store,
            items: [],
            columns: [
                {
                    field: "value_commitment",
                    sortable: true,
                    label: "value_commitment",
                },
                {
                    field: "referent_year",
                    sortable: true,
                    label: "referent_year",
                },
            ],
            sortField: null,
            sortDirection: "asc",

            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 5,
            currentPage: 1,
        };
    },
    computed: {
        sortedItems() {
            if (this.sortField) {
                const field = this.sortField;
                const direction = this.sortDirection === "desc" ? 1 : -1;

                return this.items.slice().sort((a, b) => {
                    if (a[field] < b[field]) return -direction;
                    if (a[field] > b[field]) return direction;
                    return 0;
                });
            }
            return this.items;
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.sortedItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.sortedItems.length / this.itemsPerPage);
        },
    },

    created() {
        this.getCommitment();
        this.emitter.on("updateCommitment", (val) => {
            this.getCommitment();
        });
    },

    methods: {
        sortBy(column) {
            if (column.sortable) {
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
                this.sortField = column.field;
            }
        },
        setCurrentPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
            }
        },
        onInput(index, field, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            this.items[index][field] = formattedValue;
        },
        async editCommitment(item) {
            this.items.forEach((member) => {
                if (member._id !== item._id) {
                    member.edit = false;
                }
            });

            item.edit = !item.edit;

            if (!item.edit) {
                if (!item.value_commitment || !item.referent_year) {
                    this.$toast.error(this.$t("error_message_commitment"));
                    item.edit = true;
                    return;
                }
                const authHeader = store.getAuthHeader();

                let body = {
                    value: parseFloat(
                        item.value_commitment
                            .replace(/[R$.\s]/g, "")
                            .replace(",", ".")
                    ),
                    year: item.referent_year,
                };

                try {
                    let res = await axios.put(
                        `${store.BACKEND_URL}/api/projects/${this.$route.params.id}/contract/`,
                        body,
                        { withCredentials: false, headers: authHeader }
                    );
                    if (res.status == 204) {
                        this.$toast.success(this.$t("successfully_changed"));
                    } else {
                        this.$toast.error(this.$t("error_changing"));
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        },

        addCommitment() {
            this.emitter.emit("openModalCommitment", true);
        },

        async getCommitment() {
            this.items = [];
            const authHeader = store.getAuthHeader();
            try {
                const res = await axios.get(
                    `${store.BACKEND_URL}/api/statistics/payments?project_id=${this.$route.params.id}`,
                    {
                        withCredentials: false,
                        headers: authHeader,
                    }
                );

                if (res.data && Object.keys(res.data).length > 0) {
                    this.items = Object.keys(res.data).map((year) => {
                        return {
                            referent_year: parseInt(year),
                            value_commitment: this.store.formatMoney(
                                res.data[year]
                            ),
                            edit: false,
                        };
                    });
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
