<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-bg" v-if="openModal">
            <div class="card-modal fadeIn">
                <div class="flex justify-between">
                    <div class="m-1 text-xl">{{ $t("reset_password") }}</div>
                    <div @click="closeModal">
                        <Icon type="x-circle" class="w-6 h-6 cursor-pointer" />
                    </div>
                </div>
                <div class="my-1 separete"></div>
                <div
                    class="grid items-center justify-start my-6 gap-4 lg:grid-cols-1"
                >
                    <div class="flex flex-col m-1 mx-1">
                        <label for="status" class="txt-label">{{
                            $t("current_password")
                        }}</label>
                        <div class="flex items-center justify-between">
                            <input
                                :type="typeInput"
                                class="w-full sty-input"
                                :class="borderError"
                                v-model="actualPass"
                            />
                            <Icon
                                :type="typeEye"
                                class="w-6 h-6 mx-6 cursor-pointer"
                                @click="visiblePass"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col m-1 mx-1">
                        <label for="status" class="txt-label">{{
                            $t("new_password")
                        }}</label>
                        <div class="flex items-center justify-between">
                            <input
                                :type="newPassType"
                                class="w-full sty-input"
                                :class="borderError"
                                v-model="newPass"
                            />
                            <Icon
                                :type="typeEyeNew"
                                class="w-6 h-6 mx-6 cursor-pointer"
                                @click="visibleNewPass"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col m-1 mx-1">
                        <label for="status" class="txt-label">{{
                            $t("confirm_new_password")
                        }}</label>
                        <div class="flex items-center justify-between">
                            <input
                                :type="typeInputConfirm"
                                class="w-full sty-input"
                                :class="borderError"
                                v-model="confirmPass"
                            />
                            <Icon
                                :type="typeConfirm"
                                class="w-6 h-6 mx-6 cursor-pointer"
                                @click="visiblePassConfirm"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between mt-4 mx-2">
                        <div class="btn-default py-2" @click="closeModal">
                            {{ $t("cancel") }}
                        </div>
                        <div
                            class="btn-default btn-master py-2"
                            @click="updatePass"
                        >
                            {{ $t("save_changes") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        Icon,
    },
    data() {
        return {
            store,
            openModal: false,

            actualPass: "",
            newPass: "",
            confirmPass: "",

            typeEye: "eye-close",
            typeInput: "password",

            newPassType: "password",
            typeEyeNew: "eye-close",

            typeInputConfirm: "password",
            typeConfirm: "eye-close",

            borderError: "",
        };
    },

    created() {
        this.emitter.on("reset password", () => {
            this.openModal = true;
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;

            this.actualPass = "";
            this.newPass = "";
            this.confirmPass = "";
        },
        visiblePass() {
            if (this.typeEye == "eye-close") {
                this.typeEye = "eye-open";
                this.typeInput = "text";
            } else {
                this.typeEye = "eye-close";
                this.typeInput = "password";
            }
        },
        visibleNewPass() {
            if (this.typeEyeNew == "eye-close") {
                this.typeEyeNew = "eye-open";
                this.newPassType = "text";
            } else {
                this.typeEyeNew = "eye-close";
                this.newPassType = "password";
            }
        },
        visiblePassConfirm() {
            if (this.typeConfirm == "eye-close") {
                this.typeConfirm = "eye-open";
                this.typeInputConfirm = "text";
            } else {
                this.typeConfirm = "eye-close";
                this.typeInputConfirm = "password";
            }
        },
        async updatePass() {
            const authHeader = store.getAuthHeader();

            if (this.actualPass.trim() == "") {
                this.$toast.error(this.$t("password_required"));
                this.borderError = "border-2 border-rose-500";
                return;
            }

            if (
                this.actualPass.trim() != "" &&
                this.newPass.trim() !== "" &&
                this.confirmPass.trim() !== ""
            ) {
                if (this.newPass === this.confirmPass) {
                    this.borderError = "";
                    const response = await axios.post(
                        `${store.BACKEND_URL}/api/users/$reset/`,
                        {
                            old_password: this.actualPass,
                            "new-password": this.newPass,
                        },
                        {
                            withCredentials: false,
                            headers: authHeader,
                        }
                    );

                    if (response.status === 204) {
                        this.actualPass = "";
                        this.newPass = "";
                        this.confirmPass = "";

                        this.$toast.success(
                            this.$t("password_changed_successfully")
                        );
                        this.closeModal();
                    } else {
                        this.$toast.error(this.$t("password_changed_error"));
                    }
                } else {
                    this.borderError = "border-2 border-rose-500";
                    this.$toast.error(this.$t("passwords_not_match"));
                }
            } else {
                this.$toast.error(this.$t("password_required"));
            }
        },
    },
};
</script>
