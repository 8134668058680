<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">{{ $t("technical_data") }}</div>
            <div class="grid grid-cols-4 gap-4">
                <div class="flex flex-col col-span-4">
                    <label for="title" class="label-ptr"
                        >{{ $t("general_goal") }}
                        <sup>500 {{ $t("characters") }}</sup></label
                    >
                    <textarea
                        rows="4"
                        class="sty-input"
                        v-model="obj.goal"
                        maxlength="500"
                    ></textarea>
                    <small
                        >{{ store.remainingCharacters(obj.goal, 500) }}
                        {{ $t("characters_remaining") }}</small
                    >
                </div>
                <div class="flex flex-col">
                    <label for="title" class="label-ptr"
                        >{{ $t("deadline") }}
                        <sup>{{ $t("months") }}</sup></label
                    >
                    <input
                        type="text"
                        maxlength="2"
                        class="sty-input"
                        v-model="obj.term"
                    />
                </div>
            </div>
            <div class="grid items-end grid-cols-4 gap-4 my-4">
                <div class="flex flex-col">
                    <div class="label-ptr">{{ $t("keywords") }}</div>
                    <div class="flex flex-col">
                        <input
                            type="text"
                            class="sty-input"
                            v-model="keyword"
                        />
                    </div>
                </div>
                <div class="p-2 btn-default" @click="addKeyWord">
                    <Icon type="plus" class="w-6 h-6" />
                </div>
            </div>
            <div class="grid grid-cols-6 gap-4 mb-4">
                <div
                    class="relative"
                    v-for="(item, index) in obj.keywords"
                    :key="index"
                >
                    <div
                        class="absolute flex items-center justify-center cursor-pointer -right-2 -top-2"
                        @click="removeKeyWord(item)"
                    >
                        <Icon
                            type="x-remove"
                            class="w-5 h-5 text-white bg-red-400 border border-gray-800 rounded-full"
                        />
                    </div>
                    <div class="card-add">{{ item }}</div>
                </div>
            </div>
            <div class="my-4 separete"></div>
            <div class="grid grid-cols-3 gap-4">
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("area")
                    }}</label>
                    <select
                        class="capitalize sty-input"
                        v-model="obj.area"
                        @change="updateThemes"
                    >
                        <option
                            :value="item.id"
                            v-for="(item, index) in areas"
                            :key="index"
                        >
                            {{ $t(item.name) }}
                        </option>
                    </select>
                </div>
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("theme")
                    }}</label>
                    <select class="capitalize sty-input" v-model="obj.theme">
                        <option
                            :value="item.name"
                            v-for="(item, index) in filteredThemes"
                            :key="index"
                        >
                            {{ $t(item.name) }}
                        </option>
                    </select>
                </div>
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("subtheme")
                    }}</label>
                    <select class="sty-input" v-model="obj.subtheme">
                        <option
                            :value="item.name"
                            v-for="(item, index) in filteredSubthemes"
                            :key="index"
                        >
                            {{ $t(item.name) }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="my-6 separete"></div>
            <div class="subtitle-ptr">{{ $t("expected_results") }}</div>
            <div
                class="grid grid-cols-3 gap-4 mt-2"
                v-for="(item, index) in obj.expected_results"
                :key="index"
            >
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("type_of_result")
                    }}</label>
                    <select class="capitalize sty-input" v-model="item.type">
                        <option value="Conhecimento produzido">
                            {{ $t("knowledge_produced") }}
                        </option>
                        <option value="Produto">{{ $t("product") }}</option>
                        <option value="Serviço">{{ $t("service") }}</option>
                        <option value="Processo">{{ $t("process") }}</option>
                        <option value="Método">{{ $t("method") }}</option>
                        <option value="Sistema">{{ $t("system") }}</option>
                        <option value="Infraestrutura">
                            {{ $t("infrastructure") }}
                        </option>
                        <option value="Outros">{{ $t("other") }}</option>
                    </select>
                </div>
                <div class="flex flex-col col-span-3">
                    <label for="title" class="label-ptr"
                        >{{ $t("description_of_the_result") }}
                        <sup>(300 {{ $t("characters") }})</sup></label
                    >
                    <textarea
                        rows="4"
                        class="sty-input"
                        v-model="item.description"
                        maxlength="300"
                    ></textarea>
                    <small
                        >{{ store.remainingCharacters(item.description, 300) }}
                        {{ $t("characters_remaining") }}</small
                    >
                </div>
                <div class="flex col-span-3 justify-end">
                    <div
                        class="flex items-center justify-center px-4 py-2 rounded-md cursor-pointer btn-delete"
                        @click="obj.expected_results.splice(index, 1)"
                    >
                        <div class="text-red-700">
                            {{ $t("delete_result") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 btn-default" @click="addResults">
                {{ $t("add_result") }}
            </div>

            <div class="my-6 separete"></div>
            <div class="subtitle-ptr">{{ $t("related_projects") }}</div>
            <div
                class="grid grid-cols-4 gap-4 my-2"
                v-for="(item, idItem) in relatedProjects"
                :key="idItem"
            >
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("project_title")
                    }}</label>
                    <input type="text" class="sty-input" v-model="item.title" />
                </div>
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("anp_number")
                    }}</label>
                    <input
                        type="text"
                        class="sty-input"
                        v-model="item.anp_project_number"
                    />
                </div>
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("current_situation")
                    }}</label>
                    <select class="capitalize sty-input" v-model="item.status">
                        <option value="Concluído">{{ $t("finished") }}</option>
                        <option value="Em progresso">
                            {{ $t("in_progress") }}
                        </option>
                        <option value="Não iniciado">
                            {{ $t("not_initiated") }}
                        </option>
                    </select>
                </div>
                <div class="flex items-end">
                    <div
                        class="flex items-center justify-center px-4 py-2 rounded-md cursor-pointer btn-delete"
                    >
                        <div class="text-red-700" @click="deleteCompany(item)">
                            <Icon type="trash" class="w-6 h-6" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-64 my-4 btn-default" @click="addProject">
                {{ $t("add_related_project") }}
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

export default {
    components: {
        Icon,
    },
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            obj: {
                goal: "",
                term: null,
                area: "",
                theme: "",
                subtheme: "",
                expected_results: [],
                keywords: [],
            },
            relatedProjects: [],

            description: "",
            maxLength: 500,
            keyword: "",

            filteredThemes: [],
            filteredSubthemes: [],
            areas: [
                {
                    id: "Exploração e produção de petróleo e gás natural - onshore e offshore",
                    name: "exploration_natural_gas",
                    theme: [
                        {
                            name: "Exploração - Horizonte pré-sal, águas profundas, bacias maduras e novas fronteiras exploratórias",
                            subtheme: [
                                {
                                    name: "minimização de resíduos - redução, reutilização e reciclagem",
                                },
                                {
                                    name: "modelagem e prevenção de impactos ambientais",
                                },
                                {
                                    name: "remediação e recuperação de áreas contaminadas e impactadas",
                                },
                                {
                                    name: "gerenciamento de águas, efluentes e emissões de poluentes regulamentados",
                                },
                                {
                                    name: "emissões de gases de efeito estufa na indústria de petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "monitoramento de áreas impactadas por atividades da indústria de petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "monitoramento e controle de instalações onshore e offshore",
                                },
                                { name: "avaliação e gerenciamento de riscos" },
                                {
                                    name: "indicadores de sms para indústria do petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "integridade de equipamentos e instalações",
                                },
                                {
                                    name: "segurança nas operações de perfuração",
                                },
                                { name: "confiabilidade humana" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Produção - Horizonte pré-sal, águas profundas, bacias maduras e novas fronteiras exploratórias",
                            subtheme: [
                                {
                                    name: "técnicas de aquisição, processamento e interpretação de dados geofísicos em escala de reservatório",
                                },
                                { name: "perfuração e completação de poços" },
                                {
                                    name: "petrofísica, perfilagem de poços e avaliação de formações",
                                },
                                { name: "geofísica de reservatório" },
                                { name: "geologia de reservatório" },
                                {
                                    name: "engenharia de reservatório (simulação de fluxo)",
                                },
                                {
                                    name: "risers, umbilicais e dutos submarinos",
                                },
                                {
                                    name: "técnicas e métodos de elevação artificial",
                                },
                                {
                                    name: "técnicas e equipamentos submarinos de bombeamento",
                                },
                                { name: "métodos e processos de escoamento" },
                                {
                                    name: "unidades flutuantes de produção, sistemas de ancoragem e amarração e posicionamento dinâmico",
                                },
                                {
                                    name: "automação, controle e instrumentação",
                                },
                                {
                                    name: "medição da produção - novas tecnologias e procedimentos",
                                },
                                {
                                    name: "caracterização e processamento de fluidos produzidos",
                                },
                                { name: "gerenciamento de água produzida" },
                                { name: "captura e estocagem de co2" },
                                { name: "geomecânica/estabilização de poços" },
                                { name: "desenvolvimento de equipamentos" },
                                { name: "impactos ambientais" },
                                {
                                    name: "estudo de confiabilidade e manutenção baseado em risco de sistemas flutuantes e de produção",
                                },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Recuperação avançada de petróleo",
                            subtheme: [
                                { name: "recuperação melhorada de petróleo" },
                                {
                                    name: "caracterização e engenharia de reservatórios",
                                },
                                { name: "injeção e gerenciamento de águas" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Engenharia de poço",
                            subtheme: [
                                { name: "equipamentos de poço e submarino" },
                                {
                                    name: "perfuração e completação - técnicas e tecnologias",
                                },
                                { name: "interface formação - poço" },
                                {
                                    name: "elevação artificial e escoamento multifásico",
                                },
                                {
                                    name: "automação, controle, instrumentação e metrologia",
                                },
                                { name: "processamento primário de fluidos" },
                                { name: "estabilidade de poços" },
                                { name: "manutenção e inspeção - segurança" },
                                { name: "outros" },
                            ],
                        },
                    ],
                },
                {
                    id: "Gás natural",
                    name: "natural_gas",
                    theme: [
                        {
                            name: "Produção e processamento",
                            subtheme: [
                                { name: "produção e condicionamento de gn" },
                                { name: "processamento de gás natural" },
                                { name: "processamento embarcado" },
                                {
                                    name: "transformação química de gás natural",
                                },
                                { name: "gás não-convencional" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Movimentação e armazenamento",
                            subtheme: [
                                {
                                    name: "transporte e armazenamento de gn e gnl",
                                },
                                {
                                    name: "processos de liquefação de gn e regaseificação de gnl",
                                },
                                { name: "transformação física de gás natural" },
                                {
                                    name: "estocagem subterrânea de gás natural ou estocagem geológica de gás natural",
                                },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Utilização",
                            subtheme: [
                                {
                                    name: "caracterização e controle de qualidade",
                                },
                                {
                                    name: "aplicações industriais, comerciais, residenciais e automotivas",
                                },
                                {
                                    name: "produção de fertilizantes nitrogenados",
                                },
                                { name: "outras aplicações" },
                                { name: "outros" },
                            ],
                        },
                    ],
                },
                {
                    id: "Abastecimento",
                    name: "supply",
                    theme: [
                        {
                            name: "Refino",
                            subtheme: [
                                { name: "processamento de petróleo" },
                                { name: "sistemas catalíticos" },
                                {
                                    name: "desenvolvimento de produtos de maior valor agregado",
                                },
                                {
                                    name: "otimização e confiabilidade de equipamentos, processos e sistemas",
                                },
                                {
                                    name: "automação, controle, instrumentação e metrologia",
                                },
                                { name: "tecnologia em asfalto" },
                                { name: "biorrefino" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Combustíveis e lubrificantes",
                            subtheme: [
                                { name: "estabilidade de combustíveis" },
                                { name: "combustíveis de aviação" },
                                { name: "óleo diesel" },
                                { name: "gasolinas" },
                                { name: "tecnologia veicular" },
                                { name: "aditivos" },
                                { name: "lubrificantes e biolubrificantes" },
                                { name: "desempenho e emissões" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Petroquímica de 1° e 2° geração",
                            subtheme: [
                                { name: "processos petroquímicos" },
                                { name: "sistemas catalíticos" },
                                {
                                    name: "matérias-primas alternativas para produção de básicos e intermediários",
                                },
                                {
                                    name: "polímeros biodegradáveis e biopolímeros",
                                },
                                { name: "reciclagem de polímeros" },
                                {
                                    name: "automação, controle e instrumentação",
                                },
                                { name: "outros" },
                            ],
                        },
                    ],
                },
                {
                    id: "Biocombustíveis",
                    name: "biofuels",
                    theme: [
                        {
                            name: "Biodiesel",
                            subtheme: [
                                { name: "produção de biodiesel" },
                                { name: "produção de oleaginosas" },
                                {
                                    name: "caracterização e controle da qualidade",
                                },
                                { name: "armazenamento" },
                                { name: "co-produtos" },
                                { name: "usos do biodiesel" },
                                {
                                    name: "automação, controle, instrumentação e metrologia",
                                },
                                { name: "sistemas catalíticos" },
                                { name: "cadeia produtiva" },
                                { name: "produção de leveduras e algas" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Bioetanol",
                            subtheme: [
                                { name: "produção de bioetanol" },
                                {
                                    name: "caracterização e controle da qualidade",
                                },
                                { name: "co-produtos" },
                                {
                                    name: "automação, controle, instrumentação e metrologia",
                                },
                                { name: "transformação química do bioetanol" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Energia a partir de outras fontes de biomassa",
                            subtheme: [
                                { name: "gaseificação de biomassa" },
                                { name: "produção de biogás" },
                                { name: "outros processamentos de biomassa" },
                                { name: "sistemas catalíticos" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Biocomustíveis avançados (2°, 3° e 4° geração)",
                            subtheme: [
                                { name: "processos de produção" },
                                {
                                    name: "matérias primas - caracterização e pré-tratamento",
                                },
                                {
                                    name: "conversão de materiais lignocelulósicos",
                                },
                                { name: "co-produtos" },
                                { name: "avaliação da sustentabilidade" },
                                { name: "sistemas catalíticos" },
                                { name: "produção de enzimas" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Bioquerosene de aviação",
                            subtheme: [
                                { name: "produção de bioquerosene" },
                                {
                                    name: "caracterização e controle da qualidade",
                                },
                                { name: "co-produtos" },
                                { name: "avaliação da sustentabilidade" },
                                { name: "outros" },
                            ],
                        },
                    ],
                },
                {
                    id: "Outras fontes de energia",
                    name: "other_energy_sources",
                    theme: [
                        {
                            name: "Hidrogênio",
                            subtheme: [
                                { name: "processos de produção de hidrogênio" },
                                { name: "célula combustível" },
                                {
                                    name: "armazenamento e distribuição de hidrogênio",
                                },
                                {
                                    name: "aplicação de hidrogênio como vetor energético",
                                },
                                { name: "sistemas catalíticos" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Energia solar",
                            subtheme: [
                                { name: "sistemas solares de aquecimento" },
                                { name: "energia solar fotovoltaica" },
                                { name: "energia solar térmica" },
                                { name: "tecnologia de sistemas solares" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Outras fontes alternativas",
                            subtheme: [
                                { name: "energia eólica" },
                                { name: "energia dos oceanos" },
                                { name: "sistemas híbridos" },
                                { name: "outros" },
                            ],
                        },
                    ],
                },
                {
                    id: "Temas transversais",
                    name: "crosscutting_themes",
                    theme: [
                        {
                            name: "Materiais",
                            subtheme: [
                                { name: "nanomateriais" },
                                { name: "corrosão e proteção" },
                                { name: "novos materiais" },
                                {
                                    name: "integridade estrutural, soldagem e caracterização de materiais",
                                },
                                { name: "tecnologia de materiais" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Segurança e meio ambiente",
                            subtheme: [
                                {
                                    name: "minimização de resíduos - redução, reutilização e reciclagem",
                                },
                                {
                                    name: "modelagem e prevenção de impactos ambientais",
                                },
                                {
                                    name: "remediação e recuperação de áreas contaminadas e impactadas",
                                },
                                {
                                    name: "gerenciamento de águas, efluentes e emissões de poluentes regulamentados",
                                },
                                {
                                    name: "emissões de gases de efeito estufa na indústria de petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "monitoramento de áreas impactadas por atividades da indústria de petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "monitoramento e controle de instalações onshore e offshore",
                                },
                                { name: "avaliação e gerenciamento de riscos" },
                                {
                                    name: "indicadores de sms para indústria do petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "integridade de equipamentos e instalações",
                                },
                                {
                                    name: "segurança nas operações de perfuração",
                                },
                                { name: "confiabilidade humana" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Distribuição, logística e transporte",
                            subtheme: [
                                { name: "tecnologia de dutos" },
                                { name: "logística" },
                                {
                                    name: "critérios de projeto de gasodutos e oleodutos",
                                },
                                {
                                    name: "estudo de confiabilidade e manutenção baseado em risco de gasodutos e oleodutos",
                                },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "Avaliação da conformidade, monitoramento e controle",
                            subtheme: [
                                {
                                    name: "automação, controle, instrumentação e metrologia",
                                },
                                {
                                    name: "metodologias e sistemas de controle da qualidade",
                                },
                                {
                                    name: "avaliação da conformidade e desempenho e certificação",
                                },
                                { name: "outros" },
                            ],
                        },
                    ],
                },
                {
                    id: "Regulação do setor de petróleo, gás natural e biocombustíveis",
                    name: "sector_regulation",
                    theme: [
                        {
                            name: "aspectos econômicos da regulação da indústria do petróleo, gás natural e biocombustíveis",
                            subtheme: [
                                {
                                    name: "economia da energia e política energética no contexto de um planejamento integrado de recursos energéticos",
                                },
                                { name: "eficiência energética" },
                                {
                                    name: "metodologias de avaliação de impacto regulatório nas indústrias de petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "metodologias de avaliação de risco e impactos socioambientais dos investimentos",
                                },
                                {
                                    name: "aspectos econômicos gerais da regulação das indústrias de petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "regulação de acesso à infraestrutura de transporte (regulação de monopólios naturais)",
                                },
                                {
                                    name: "modelos de tarifação para prestação de serviços de transporte de gás natural",
                                },
                                { name: "regulação e defesa da concorrência" },
                                { name: "outros" },
                            ],
                        },
                        {
                            name: "aspectos jurídicos da regulação da indústria do petróleo, gás natural e biocombustíveis",
                            subtheme: [
                                {
                                    name: "modelos de exploração e regimes contratuais na indústria do petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "adequação do marco legal vigente à produção de gás não convencional",
                                },
                                {
                                    name: "adequação do marco legal vigente à produção, movimentação e uso de biogás",
                                },
                                {
                                    name: "análise da fronteira de competências entre reguladores",
                                },
                                {
                                    name: "tributação na indústria do petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "direito ambiental e a regulação das indústrias de petróleo, gás natural e biocombustíveis",
                                },
                                {
                                    name: "aspectos jurídicos gerais da regulação das indústrias de petróleo, gás natural e biocombustíveis",
                                },
                                { name: "outros" },
                            ],
                        },
                    ],
                },
            ],

            loading: false,
        };
    },
    watch: {
        "obj.area": "updateThemes",
        "obj.theme": "updateSubthemes",
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];

        if (data != null && data.hasOwnProperty("technical_data")) {
            this.obj = data.technical_data;
            this.obj.area = this.store.formatText(data.technical_data.area);
            this.obj.theme = this.store.formatText(data.technical_data.theme);
            this.obj.subtheme = this.store.formatText(
                data.technical_data.subtheme
            );

            this.obj.expected_results =
                data.technical_data.expected_results.map((result) => {
                    result.type = this.store.formatText(result.type);
                    return result;
                });
        }

        if (data != null && data.hasOwnProperty("related_projects")) {
            this.relatedProjects = data.related_projects.map((result) => {
                result.status = this.store.formatText(result.status);
                return result;
            });
        }
    },
    mounted() {
        this.updateThemes(); // Initialize themes based on initial area selection if any
        this.updateSubthemes(); // Initialize subthemes based on initial theme selection if any
    },
    methods: {
        updateThemes() {
            const selectedArea = this.areas.find(
                (area) => area.id === this.obj.area
            );

            this.filteredThemes = selectedArea ? selectedArea?.theme : [];
        },
        updateSubthemes() {
            const selectedTheme = this.filteredThemes.find(
                (theme) => theme.name === this.obj.theme
            );
            this.filteredSubthemes = selectedTheme?.subtheme.map((sub) => {
                sub.name = this.store.formatText(sub.name);
                return sub;
            });
        },
        addResults() {
            this.obj.expected_results.push({
                type: "",
                description: "",
            });
        },
        addKeyWord() {
            this.obj.keywords.push(this.keyword);
            this.keyword = "";
        },
        addProject() {
            this.relatedProjects.push({
                title: "",
                anp_project_number: "",
                status: "",
            });
        },
        deleteCompany(item) {
            const index = this.relatedProjects.indexOf(item);
            if (index !== -1) {
                this.relatedProjects.splice(index, 1);
            }
        },
        removeKeyWord(item) {
            const index = this.obj.keywords.indexOf(item);
            if (index !== -1) {
                this.obj.keywords.splice(index, 1);
            }
        },

        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};

            parsedData.technical_data = this.obj;
            parsedData.related_projects = this.relatedProjects;

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
    },
};
</script>
