import axios from "axios";
import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store";

import Login from "../views/Login.vue";

import Dashboard from "@/containers/operation/Dashboard";
import Projects from "@/containers/operation/Projects";
import Management from "@/containers/operation/Management";
import RegisterPtr from "@/containers/operation/pathRegisterPtr/RegisterPtr";

import DetailsProject from "@/components/pathProjects/projects/AboutProject";

function clearAuth() {
    localStorage.removeItem("access-token");
    localStorage.removeItem("expiration");
}

const routes = [
    {
        path: "/",
        name: "login",
        component: Login,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/projects",
        name: "project",
        component: Projects,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/form",
        name: "registerptr",
        component: RegisterPtr,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/management",
        name: "Management",
        component: Management,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/projects/:id",
        name: "details",
        component: DetailsProject,
        props: true,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
            if (!to.params.id) {
                to.params.id = 0;
            }
            next();
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        const tokenExpiration = Number(localStorage.getItem("expiration"));
        if (!tokenExpiration) {
            clearAuth();
            next("/");
            return;
        }

        const currentTime = new Date().getTime();
        if (currentTime < tokenExpiration) {
            clearAuth();
            next("/");
            return;
        }

        const accessToken = localStorage.getItem("access-token");
        if (!accessToken) {
            clearAuth();
            next("/");
            return;
        }

        const headers = store.getAuthHeader();
        try {
            await axios.get(`${store.BACKEND_URL}/api/users/me/`, {
                withCredentials: false,
                headers,
            });
        } catch (e) {
            clearAuth();
            next("/");
            return;
        }
    }

    next();
});

export default router;
