<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{ $t("specific_expenses_for_supplier_qualification_project") }}
            </div>
            <div v-for="(item, index) in supply_capacity_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("type_of_expense")
                        }}</label>
                        <select class="sty-input" v-model="item.type">
                            <option
                                value="Bens e materiais - cabeça de série e lote piloto"
                            >
                                {{
                                    $t(
                                        "goods_and_materials_series_head_and_pilot_batch"
                                    )
                                }}
                            </option>
                            <option
                                value="Serviços - cabeça de série e lote piloto"
                            >
                                {{ $t("services_series_head_and_pilot_batch") }}
                            </option>
                            <option
                                value="Estudos de viabilidade técnica e econômica"
                            >
                                {{
                                    $t(
                                        "feasibility_studies_technical_and_economic"
                                    )
                                }}
                            </option>
                            <option
                                value="Equipamentos e materiais - primeiro lote"
                            >
                                {{ $t("equipment_and_materials_first_batch") }}
                            </option>
                            <option value="Equipamentos laboratoriais">
                                {{ $t("laboratory_equipment") }}
                            </option>
                            <option
                                value="Serviços - operacionalização de equipamentos"
                            >
                                {{ $t("services_equipment_operation") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("origin")
                        }}</label>
                        <select class="sty-input" v-model="item.origin">
                            <option value="Nacional">
                                {{ $t("national") }}
                            </option>
                            <option value="Importado">
                                {{ $t("imported") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("description") }}
                            <sup class="normal-case"
                                >max 80 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="80"
                            v-model="item.description"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(item.description, 80)
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup class="normal-case"
                                >max 300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("quantity") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.quantity"
                            @input="onQuantityInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("unit_price") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.unit_value"
                            @input="onInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("total_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input cursor-default"
                            placeholder="R$"
                            v-model="item.value"
                            readonly
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="removeSupply(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="my-4">
                <div
                    class="w-48 btn-default"
                    @click="addSupplyCapacityExpenses"
                >
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t(
                        "total_value_in_specific_expenses_for_supplier_qualification_project"
                    )
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValue"
                    readonly
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            supply_capacity_expenses: [],
            loading: false,
            authHeader: store.getAuthHeader(),
            ptrDatas: [],
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (data != null && data.hasOwnProperty("costs_expenses")) {
            this.supply_capacity_expenses = data.supply_capacity_expenses.map(
                (sup) => ({
                    ...sup,
                    value: this.store.formatMoney(sup.value),
                    unit_value: this.store.formatMoney(sup.unit_value),
                    origin: this.store.formatText(sup.origin),
                    type: this.store.formatText(sup.type),
                })
            );
            this.updateTotalValue();
        }
    },
    methods: {
        addSupplyCapacityExpenses() {
            this.supply_capacity_expenses.push({
                company_name: "",
                type: "",
                origin: "",
                description: "",
                justification: "",
                unit_value: null,
                quantity: null,
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};
            parsedData.supply_capacity_expenses =
                this.supply_capacity_expenses.map((sup) => {
                    return {
                        ...sup,
                        value: this.store.formatValueToSave(sup.value),
                        unit_value: this.store.formatValueToSave(
                            sup.unit_value
                        ),
                    };
                });

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));
            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.supply_capacity_expenses[index]) {
                this.supply_capacity_expenses[index].unit_value =
                    formattedValue;
                this.calculateTotalValue(index);
            }
        },
        onQuantityInput(index, event) {
            if (this.supply_capacity_expenses[index]) {
                this.supply_capacity_expenses[index].quantity =
                    event.target.value;
                this.calculateTotalValue(index);
            }
        },
        calculateTotalValue(index) {
            let item = this.supply_capacity_expenses[index];
            let quantity = parseFloat(item.quantity) || 0;
            let unitValue = this.store.parseCurrency(item.unit_value) / 100;
            let totalValue = quantity * unitValue;
            item.value = this.store.formatCurrency(
                (totalValue * 100).toString()
            );
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.supply_capacity_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        removeSupply(index) {
            this.supply_capacity_expenses.splice(index, 1);
            this.updateTotalValue();
        },
    },
};
</script>
