<template>
    <div>
        <div class="w-48 gap-3 box-lang" @click="openLang" ref="menuLang">
            <div class="flex flex-row gap-3">
                <img :src="flag.flag" width="25px" alt="" />
                {{ flag.language }}
            </div>
            <Icon
                type="arrow-down"
                class="w-6 h-6"
                :class="!open ? 'rotate-down' : 'rotate-up'"
            />
        </div>
        <div
            class="absolute w-48 overflow-hidden transition-all duration-500 divide-y box-option divide-slate-300"
            :style="{
                'max-height': open ? '1000px' : '0',
                opacity: open ? '1' : '0',
            }"
        >
            <div
                class="flex flex-row gap-3 option-flag"
                v-for="lg in lang"
                @click="switchLang(lg)"
            >
                <img :src="lg.flag" width="25px" alt="" />
                <div>{{ lg.language }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";

export default {
    components: {
        Icon,
    },
    data() {
        return {
            store,
            lang: [
                {
                    language: "Português",
                    acronym: "pt",
                    flag: require("../assets/img/brazil-.png"),
                },
                {
                    language: "English",
                    acronym: "en",
                    flag: require("../assets/img/united-states.png"),
                },
            ],
            flag: {
                language: "",
                flag: null,
            },
            open: false,
        };
    },
    mounted() {
        document.addEventListener("click", this.closeLang);
    },
    beforeDestroy() {},
    created() {
        let lang = localStorage.getItem("i18nextLng");
        if (lang == "en") {
            this.flag = {
                language: "English",
                acronym: "en",
                flag: require("../assets/img/united-states.png"),
            };
        } else {
            this.flag = {
                language: "Português",
                acronym: "pt",
                flag: require("../assets/img/brazil-.png"),
            };
        }
    },
    methods: {
        closeLang(event) {
            if (
                this.$refs.menuLang &&
                !this.$refs.menuLang.contains(event.target)
            ) {
                this.open = false;
            }
        },
        openLang() {
            this.open = !this.open;
        },
        switchLang(lg) {
            this.flag = lg;
            this.$i18next.changeLanguage(lg.acronym);
        },
    },
};
</script>
