<template>
    <div class="flex flex-row">
        <Sidebar />
        <div>
            <Navbar />
            <div class="main-page">
                <div class="page-title">{{ $t("dashboard") }}</div>
                <div class="grid xl:grid-cols-6 grid-cols-1 gap-4 py-4 mb-6">
                    <div class="bg-transparent" ref="refprojectItem">
                        <div class="text-lg font-bold text-gray-800">
                            {{ $t("project") + "s" }}
                        </div>
                        <div class="pt-1 pb-4 text-description">
                            {{ $t("select_project_description") }}
                        </div>

                        <div class="grid grid-cols-4 gap-3">
                            <div
                                class="flex items-center justify-start w-full sty-input inpt-filter"
                                :class="!mobile ? 'col-span-4' : 'col-span-3'"
                            >
                                <Icon type="search" class="mr-2" />
                                <input
                                    type="text"
                                    class="bg-transparent w-full outline-none placeholder:italic"
                                    v-model="searchProject"
                                    :placeholder="$t('search_projects')"
                                />
                                <div
                                    class="btn-default py-1 w-10 bg-transparent border-white"
                                    @click="openProjects"
                                    v-if="mobile"
                                >
                                    <Icon type="menu" class="w-6 h-6 mx-2" />
                                </div>
                            </div>
                            <div
                                class="btn-default bg-transparent w-full"
                                @click="openProjectSelected"
                                v-if="mobile"
                            >
                                <span class="mx-2"
                                    >{{ countSelected }}
                                    {{ $t("selected") }}</span
                                >
                                <Icon type="arrow-down" class="w-4 h-4" />
                            </div>
                        </div>

                        <div
                            :class="
                                mobile
                                    ? 'select-responsive overflow-hidden transition-all duration-500 ease-in-out'
                                    : ''
                            "
                            :style="
                                mobile
                                    ? {
                                          'max-height': openResponsiveSelect
                                              ? '650px'
                                              : '0',
                                          opacity: openResponsiveSelect
                                              ? '1'
                                              : '0',
                                      }
                                    : {}
                            "
                        >
                            <projectitem
                                v-for="(ts, tsId) in filterProjects"
                                :key="tsId"
                                :ts="ts"
                                @click="sendProjectId(ts)"
                                @collapse-info="collapseInfo"
                                @go-to-project="goToProject"
                                @close-list="openResponsiveSelect = false"
                            />
                        </div>

                        <div
                            v-if="mobile"
                            class="select-responsive-proj overflow-hidden transition-all duration-500 ease-in-out"
                            :style="{
                                'max-height': projectSelected ? '650px' : '0',
                                opacity: projectSelected ? '1' : '0',
                            }"
                        >
                            <projectitem
                                v-for="(ts, tsId) in projectChecks"
                                :key="tsId"
                                :ts="ts"
                                @click="sendProjectId(ts)"
                                @collapse-info="collapseInfo"
                                @go-to-project="goToProject"
                                @close-list="projectSelected = false"
                            />
                        </div>
                    </div>

                    <div class="col-span-5">
                        <div class="flex flex-col items-start pb-3">
                            <div
                                class="flex items-center justify-between text-lg cursor-pointer text-gray-800"
                                @click="collpaseDash"
                            >
                                <div :class="openDash ? 'font-bold ' : ''">
                                    {{ $t("projects_overview") }}
                                </div>
                                <Icon
                                    type="arrow-up"
                                    class="w-6 h-6 mx-4 mt-1"
                                    :class="
                                        !openDash ? 'rotate-up' : 'rotate-down'
                                    "
                                />
                            </div>
                            <div class="flex flex-row items-start w-full gap-4">
                                <div
                                    :class="!openDash ? 'p-0 m-0' : 'mt-3'"
                                    class="overflow-hidden transition-all duration-500 rounded-lg box-default"
                                    :style="{
                                        'max-height': openDash ? '1000px' : '0',
                                        opacity: openDash ? '1' : '0',
                                    }"
                                >
                                    <Bar />
                                </div>
                            </div>

                            <div
                                class="flex items-center my-5 justify-between text-lg cursor-pointer text-gray-800"
                                @click="collpaseSecondDash"
                            >
                                <div :class="openSecondDash ? 'font-bold' : ''">
                                    {{ $t("selected_projects") }}
                                </div>
                                <Icon
                                    type="arrow-up"
                                    class="w-6 h-6 mx-4 mt-1"
                                    :class="
                                        !openSecondDash
                                            ? 'rotate-up'
                                            : 'rotate-down'
                                    "
                                />
                            </div>
                            <div
                                class="overflow-hidden transition-all duration-500 w-full"
                                :class="
                                    withoutGraph
                                        ? 'xl:grid-cols-2 grid-cols-1 p-0'
                                        : 'grid-cols-1'
                                "
                                :style="{
                                    'max-height': openSecondDash
                                        ? '2000px'
                                        : '0',
                                    opacity: openSecondDash ? '1' : '0',
                                }"
                            >
                                <div class="grid gap-4 grid-cols-1">
                                    <div class="w-full rounded-lg box-default">
                                        <div class="pb-2 txt-titulo">
                                            {{ $t("available_resources") }} (R$)
                                        </div>
                                        <DashResources />
                                    </div>
                                </div>
                                <div
                                    class="w-full rounded-lg box-default"
                                    v-if="withoutGraph"
                                >
                                    <ProjectsProgress />
                                </div>

                                <div
                                    class="box-default my-4"
                                    :class="!openSecondDash ? 'p-0' : ''"
                                >
                                    <div class="pb-2 txt-titulo">
                                        {{ $t("project_deadline") }} ({{
                                            $t("months")
                                        }})
                                    </div>
                                    <DashDeadline />
                                </div>
                            </div>
                            <div
                                class="flex items-center mb-5 justify-between text-lg cursor-pointer text-gray-800"
                                @click="collpaseThirdDash"
                            >
                                <div :class="openThirdDash ? 'font-bold' : ''">
                                    {{ $t("items_projects") }}
                                </div>
                                <Icon
                                    type="arrow-up"
                                    class="w-6 h-6 mx-4 mt-1"
                                    :class="
                                        !openThirdDash
                                            ? 'rotate-up'
                                            : 'rotate-down'
                                    "
                                />
                            </div>
                            <div
                                class="flex flex-row items-start gap-4 overflow-hidden transition-all duration-500"
                                :style="{
                                    'max-height': openThirdDash
                                        ? '2000px'
                                        : '0',
                                    opacity: openThirdDash ? '1' : '0',
                                }"
                            >
                                <div class="rounded-lg box-default">
                                    <CurveSComponent />
                                </div>
                            </div>
                            <div
                                class="w-full rounded-lg my-4 box-default"
                                :style="{
                                    'max-height': openThirdDash
                                        ? '1000px'
                                        : '0',
                                    opacity: openThirdDash ? '1' : '0',
                                }"
                            >
                                <DashRealizationItems />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";

import Sidebar from "../Sidebar.vue";
import Navbar from "../Navbar.vue";

import Bar from "@/components/dashboards/projectRealization.vue";
import Doughnut from "@/components/dashboards/framingPotential.vue";
import DashResources from "@/components/dashboards/DashResources.vue";
import DashDeadline from "@/components/dashboards/DashDeadline.vue";
import ProjectsProgress from "@/components/dashboards/ProjectsProgress.vue";
import ChartPendency from "@/components/dashboards/ChartPendency.vue";
import DashRealizationItems from "@/components/dashboards/DashRealizationItems.vue";

import CurveSComponent from "@/components/dashboards/CurveSComponent.vue";

import Icon from "../UI/Icon.vue";
import axios from "axios";
import dayjs from "dayjs";

import router from "@/router";

import projectitem from "./ProjectItemCard";

export default {
    components: {
        Sidebar,
        Navbar,
        Bar,
        Doughnut,
        DashResources,
        DashDeadline,
        ProjectsProgress,
        ChartPendency,
        DashRealizationItems,
        Icon,
        CurveSComponent,

        projectitem,
    },
    data() {
        return {
            store,
            projects: [],
            openDash: true,
            openSecondDash: true,
            openThirdDash: true,
            searchProject: "",

            handleMessage: false,
            withoutGraph: false,
            sizeWidth: window.innerWidth,
            openResponsiveSelect: false,
            projectSelected: false,
            countSelected: 0,
            projectChecks: [],
            mobile: null,
        };
    },

    async beforeMount() {
        await this.getProjects();
    },

    created() {
        store.position = "dashboard";

        this.emitter.on("without graph", (val) => {
            this.withoutGraph = val.every((elements) => elements === 0);
        });
    },
    mounted() {
        // this.$toast.success(this.$t("welcome_message"));
        window.addEventListener("resize", this.handleResize);
        window.onresize = () => {
            this.sizeWidth = window.innerWidth;
        };
        this.handleResize();
        document.addEventListener("click", this.handleClickOutside);
    },
    computed: {
        filterProjects() {
            return this.projects.filter((proj) => {
                return proj.title
                    .toLowerCase()
                    .includes(this.searchProject?.toLowerCase());
            });
        },
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        handleResize() {
            if (this.sizeWidth > 1080) {
                this.mobile = false;
            } else {
                this.mobile = true;
            }
        },
        formatDate(data) {
            return dayjs(data).format("DD/MM/YYYY");
        },
        collpaseDash() {
            this.openDash = !this.openDash;
        },
        collpaseSecondDash() {
            this.openSecondDash = !this.openSecondDash;
        },
        collpaseThirdDash() {
            this.openThirdDash = !this.openThirdDash;
        },
        sendProjectId(projectId) {
            projectId.check = !projectId.check;
            this.emitter.emit("updateGraph", projectId._id);
            this.listProjectSelected();
        },
        collapseInfo(ts) {
            ts.openInfo = !ts.openInfo;
        },
        goToProject(ts) {
            let id = ts._id;
            router.push(`/projects/${id}`);
        },
        async getProjects() {
            const authHeader = store.getAuthHeader();
            if (!authHeader) {
                localStorage.clear();
                this.$router.push("/");
                return;
            }

            let res;
            try {
                res = await axios.get(`${store.BACKEND_URL}/api/projects/`, {
                    withCredentials: false,
                    headers: authHeader,
                });
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            if (res.status != 200) {
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }
            this.handleMessage = res.data.length > 0 ? false : true;

            for (const project of res.data) {
                const base = project.base;
                const technicalData = project.technical_data;
                const institutionalData = project.institutional_data;

                const title = base.title;
                const description = technicalData.goal;
                const projectStartDate = new Date(base.contract_date);
                const totalActivities = project.activities.length;

                const completedActivitiesCount = project.activities.filter(
                    (v) => v.progress == 100
                ).length;

                const status =
                    completedActivitiesCount == totalActivities
                        ? "Completo"
                        : "Em andamento";

                const totalValue = store.calculateProjectTotalExpenses(project);
                const mainExecutor = institutionalData.executor_companies.length
                    ? institutionalData.executor_companies[0].company_name
                    : "";

                this.projects.push({
                    _id: project._id,
                    title,
                    status,
                    description,
                    lastUpdate: project.updated_at,
                    startDate: projectStartDate,
                    completedActivities: completedActivitiesCount,
                    totalActivities: totalActivities,
                    check: true,
                    openInfo: false,
                    mainExecutor,
                    totalValue,
                });
                this.listProjectSelected();
            }

            this.loading = false;
        },
        listProjectSelected() {
            this.projectChecks = [];
            for (const prj of this.projects) {
                if (prj.check == true) {
                    this.projectChecks.push(prj);
                }
            }
            this.countSelected = this.projectChecks.length;
        },
        openProjects() {
            this.openResponsiveSelect = !this.openResponsiveSelect;
        },
        openProjectSelected() {
            this.projectSelected = !this.projectSelected;
        },
        handleClickOutside(event) {
            if (
                this.$refs.refprojectItem &&
                !this.$refs.refprojectItem.contains(event.target)
            ) {
                this.openResponsiveSelect = false;
                this.projectSelected = false;
            }
        },
    },
};
</script>
