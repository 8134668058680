<template>
    <div
        :class="[
            'flex flex-col mt-2 p-2 px-3 rounded-md',
            ts.openInfo ? 'bg-gray-100' : '',
        ]"
    >
        <div class="flex flex-row items-start justify-between mb-1">
            <div class="flex flex-row justify-start">
                <div>
                    <div
                        class="box-select mt-1"
                        :class="ts.check ? 'bg-slate-200' : ''"
                        @click="$emit('send-project-id', ts)"
                    >
                        <Icon
                            type="check"
                            class="w-4 h-4 text-selected font-bold"
                            v-if="ts.check"
                        />
                    </div>
                </div>
                <div
                    class="ml-2"
                    :title="ts.title"
                    :class="ts.openInfo ? 'font-bold text-selected' : ''"
                >
                    {{ ts.title }}
                </div>
            </div>
            <div @click="$emit('collapse-info', ts)">
                <Icon
                    type="arrow-down"
                    class="w-6 h-6 cursor-pointer"
                    :class="ts.openInfo ? 'rotate-up' : 'rotate-down'"
                />
            </div>
        </div>
        <div
            class="flex flex-col items-start mt-2 text-sm overflow-hidden transition-all duration-500"
            :style="{
                'max-height': ts.openInfo ? '1000px' : '0',
                opacity: ts.openInfo ? '1' : '0',
            }"
        >
            <div class="flex flex-row items-start mb-2 capitalize">
                <div class="w-24 font-semibold">{{ $t("executor") }}</div>
                <div class="text-sm w-[60%] px-1" :title="ts.mainExecutor">
                    {{ ts.mainExecutor }}
                </div>
            </div>
            <div class="flex flex-row items-center mb-2 capitalize">
                <div class="w-24 font-semibold">{{ $t("total_value") }}</div>
                <div class="text-sm w-[60%] px-1 text-right">
                    {{ store.formatMoney(ts.totalValue) }}
                </div>
            </div>
            <div
                class="w-full p-1 my-2 btn-default btn-selected"
                @click="$emit('go-to-project', ts)"
            >
                {{ $t("view_project_page") }}
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store";
import Icon from "../UI/Icon.vue";

export default {
    components: {
        Icon,
    },
    props: {
        ts: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            store,
        };
    },
};
</script>
