<template>
    <div class="txt-titulo">{{ $t("realization_project") }}</div>
    <Bar
        id="my-chart-id"
        width="1005"
        height="1300"
        :options="chartOptions"
        :data="chartData"
    />
</template>

<script>
import { store } from "@/store";
import axios from "axios";

import { Bar } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default {
    components: { Bar },
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    filler: {
                        propagate: true,
                    },
                    datalabels: {
                        anchor: "end",
                        align: "end",
                        formatter: (val) => val + "%",
                        font: {
                            size: 12,
                            lineHeight: 1.7,
                        },
                        textAlign: "center",
                        padding: {
                            bottom: 5,
                        },
                    },
                },
                scales: {
                    y: {
                        display: false,
                        suggestedMax: 120,
                    },
                    x: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                const label = this.getLabelForValue(value);
                                if (label.length > 20) {
                                    return label.substring(0, 15) + "...";
                                } else {
                                    return label;
                                }
                            },
                        },
                        grid: {
                            display: false,
                        },
                        border: {
                            display: false,
                        },
                    },
                },
            },
        };
    },

    beforeCreate() {
        this.emitter.on("updateGraph", (value) => {
            if (this.selectedProjects.includes(value)) {
                const idx = this.selectedProjects.indexOf(value);
                this.selectedProjects.splice(idx, 1);
            } else {
                this.selectedProjects.push(value);
            }
        });
    },

    async beforeMount() {
        await this.getCompletedActivities();
        this.loaded = true;
    },

    computed: {
        chartData() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            let data = {};
            for (const project of filteredProjects) {
                const percentage = Math.round(
                    (project.completed_activities / project.total_activities) *
                        100
                );
                data[project.title] = percentage;
            }

            const labels = Object.keys(data).map((v) => v.substring(0, 50));

            const datasets = [
                {
                    backgroundColor: [
                        "#6CD6AA",
                        "#ED7545",
                        "#06B6D4",
                        "#B778A2",
                        "#155E75",
                    ],
                    borderRadius: 5,
                    data: Object.values(data),
                },
            ];
            if (Object.values(data).length > 0) {
                this.emitter.emit("without graph", Object.values(data));
            }
            return {
                labels,
                fill: true,
                datasets,
            };
        },
    },

    methods: {
        async getCompletedActivities() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length != 0) {
                return;
            }

            const res = await axios.get(
                `${store.BACKEND_URL}/api/statistics/progress/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );

            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving projects progress information."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
        },
    },
};
</script>

<style>
.txt-titulo {
    margin-bottom: 1rem;
    font-weight: 600;
}
</style>
