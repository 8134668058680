<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{
                    $t(
                        "scholarships_for_students_coordinators_and_visiting_researchers"
                    )
                }}
            </div>
            <div v-for="(item, index) in scholarship_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-4 gap-4 mb-8">
                    <div class="flex flex-col col-span-2">
                        <label for="title" class="label-ptr">{{
                            $t("scholarship_institution")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="item.type"
                        >
                            <option value="Coordenador">
                                {{ $t("coordinator") }}
                            </option>
                            <option value="Pesquisador visitante">
                                {{ $t("visiting_researcher") }}
                            </option>
                            <option value="Nível técnico">
                                {{ $t("technical_level") }}
                            </option>
                            <option value="Graduação">
                                {{ $t("undergraduate_degree") }}
                            </option>
                            <option value="Mestrado">
                                {{ $t("masters_degree") }}
                            </option>
                            <option value="Doutorado">
                                {{ $t("doctorate") }}
                            </option>
                            <option value="Pós Doutorado7">
                                {{ $t("postdoctoral") }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col col-span-2">
                        <label for="title" class="label-ptr"
                            >{{ $t("number_of_scholarships") }}
                        </label>
                        <input
                            type="number"
                            class="sty-input"
                            v-model="item.quantity"
                            @input="onQuantityInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("monthly_scholarship_value") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.monthly_value"
                            placeholder="R$"
                            @input="onInput(index, $event)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("number_of_months") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.months"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("total_value")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.value"
                            readonly
                        />
                    </div>
                </div>
                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="removeScholarship(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="my-4">
                <div class="w-48 btn-default" @click="addScholarshipExpense">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t("total_amount_in_scholarships")
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValue"
                    readonly
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            scholarship_expenses: [],
            totalValue: "",
            loading: false,
            authHeader: store.getAuthHeader(),
            ptrDatas: [],
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (data != null && data.hasOwnProperty("scholarship_expenses")) {
            this.scholarship_expenses = data.scholarship_expenses.map(
                (sup) => ({
                    ...sup,
                    monthly_value: this.store.formatMoney(sup.monthly_value),
                    value: this.store.formatMoney(sup.value),
                    type: this.store.formatText(sup.type),
                })
            );
            this.updateTotalValue();
        }
    },
    methods: {
        addScholarshipExpense() {
            this.scholarship_expenses.push({
                type: "",
                monthly_value: null,
                quantity: null,
                months: null,
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};
            parsedData.scholarship_expenses = this.scholarship_expenses.map(
                (scholarship) => {
                    return {
                        ...scholarship,
                        monthly_value: scholarship.monthly_value.replace(
                            /\D/g,
                            ""
                        ),
                        value: this.store.formatValueToSave(scholarship.value),
                    };
                }
            );

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));
            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.scholarship_expenses[index]) {
                this.scholarship_expenses[index].monthly_value = formattedValue;
                this.calculateTotalValue(index);
            }
        },
        onQuantityInput(index, event) {
            if (this.scholarship_expenses[index]) {
                this.scholarship_expenses[index].quantity = event.target.value;
                this.calculateTotalValue(index);
            }
        },
        calculateTotalValue(index) {
            let item = this.scholarship_expenses[index];
            let quantity = parseFloat(item.quantity) || 0;
            let unitValue = this.store.parseCurrency(item.monthly_value) / 100;
            let totalValue = quantity * unitValue;
            item.value = this.store.formatCurrency(
                (totalValue * 100).toString()
            );
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.scholarship_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        removeScholarship(index) {
            this.scholarship_expenses.splice(index, 1);
            this.updateTotalValue();
        },
    },
};
</script>
