<template>
    <div>
        <div class="font-bold">{{ $t("profile_information") }}</div>
        <div class="flex justify-start py-6 gap-6">
            <div class="initials-profile">
                <div class="bg-initials">{{ initials }}</div>
            </div>
            <div>
                <div class="profile-name">{{ profile.name }}</div>
                <div class="flex flex-wrap color-font">
                    <div
                        class="flex flex-row items-center justify-center my-4 mr-8"
                    >
                        <Icon type="user" class="w-5 mr-1" />
                        <div class="txt-info">{{ formattedCnpjCpf }}</div>
                    </div>
                    <div
                        class="flex flex-row items-center justify-center my-4 mr-8"
                        v-if="profile.email?.length > 0"
                    >
                        <Icon type="mail" class="w-5 mr-1" />
                        <div class="txt-info">{{ profile.email }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="font-bold my-4">{{ $t("security_information") }}</div>
        <div class="grid items-center justify-start gap-4 lg:grid-cols-2">
            <div class="flex flex-col m-1 mx-1">
                <label for="status" class="txt-label">{{
                    $t("full_name")
                }}</label>
                <input
                    type="text"
                    class="w-full sty-input placeholder:italic"
                    v-model="profile.name"
                />
            </div>
            <div class="flex flex-col m-1 mx-1">
                <label for="status" class="txt-label">CPF/CNPJ</label>
                <input
                    type="text"
                    class="w-full sty-input"
                    v-model="formattedCnpjCpf"
                    maxlength="18"
                    @input="updateCnpjCpf"
                    @change="updateCnpjCpf"
                />
            </div>
            <div class="flex flex-col m-1 mx-1">
                <label for="status" class="txt-label">Email</label>
                <input
                    type="text"
                    class="w-full sty-input placeholder:italic"
                    v-model="profile.email"
                />
            </div>
            <div class="grid grid-cols-2 gap-4 items-end">
                <div class="flex flex-col mx-1">
                    <label for="status" class="txt-label">{{
                        $t("password")
                    }}</label>
                    <input
                        type="password"
                        class="w-full sty-input text-gray-400"
                        disabled
                        v-model="profile.password"
                    />
                </div>
                <div class="btn-default py-2" @click="resetPass">
                    {{ $t("reset_password") }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        Icon,
    },
    data() {
        return {
            store,
            profile: {
                cnpjCpf: "",
                email: "",
                name: "",
                password: "************",
            },
            maskCpf: {
                mask: "000.000.000-00",
                lazy: true,
            },
            maskCnpj: {
                mask: "00.000.000/0000-00",
                lazy: true,
            },
            currentMask: null,
        };
    },
    computed: {
        initials() {
            if (this.profile.name) {
                const words = this.profile.name.split(" ");
                let initials = "";
                for (let i = 0; i < words.length && i < 2; i++) {
                    initials += words[i].charAt(0).toUpperCase();
                }
                return initials;
            }
            return "";
        },
        formattedCnpjCpf() {
            return this.profile.cnpjCpf.length > 11
                ? this.store.formatCNPJ(this.profile.cnpjCpf)
                : this.store.formatCPF(this.profile.cnpjCpf);
        },
    },
    created() {
        this.updateMask();
        this.getUser();
    },
    methods: {
        resetPass() {
            this.emitter.emit("reset password", true);
        },
        updateMask() {
            const numericValue = this.profile.cnpjCpf.replace(/\D/g, "");
            if (numericValue.length > 11) {
                this.currentMask = this.maskCnpj;
            } else {
                this.currentMask = this.maskCpf;
            }
        },
        updateCnpjCpf(event) {
            const inputValue = event.target.value.replace(/\D/g, "");
            this.profile.cnpjCpf = inputValue;
        },
        async getUser() {
            const authHeader = store.getAuthHeader();
            let res;
            try {
                res = await axios.get(`${store.BACKEND_URL}/api/users/me/`, {
                    withCredentials: false,
                    headers: authHeader,
                });
            } catch (er) {
                console.log(er);
                return;
            }

            this.profile = {
                cnpjCpf: res.data.login,
                email: res.data.email,
                name: res.data.name,
                password: "************",
            };
        },
    },
};
</script>
<style>
.initials-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(46, 46, 46);
    font-size: 48px;
    font-weight: bold;
}

.bg-initials {
    background-color: #c0d0d8;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(46, 46, 46);
}

.profile-name {
    font-size: 40px;
    font-weight: 600;
}
</style>
