import { createApp } from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import { IMaskDirective } from "vue-imask";

import mitt from "mitt";

import router from "./router";
import "./assets/css/tailwind.css";

import Toast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.directive("imask", IMaskDirective);

app.use(router);
app.use(Toast);
i18n(app).mount("#app");
