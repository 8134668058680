<template>
    <div>
        <Chart
            type="bar"
            :data="chartData"
            :options="chartOptions"
            class="h-[30rem]"
        />
    </div>
</template>

<script>
import Chart from "primevue/chart";
import { store } from "@/store";
import axios from "axios";

export default {
    components: {
        Chart,
    },
    data() {
        return {
            store,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "",
                        backgroundColor: "#6CD6AA",
                        data: [],
                    },
                    {
                        label: "",
                        backgroundColor: "#CBC9CC",
                        data: [],
                    },
                ],
            },
            chartOptions: {
                borderRadius: 7,
                maxBarThickness: 70,
                barPercentage: 0.9,
                categoryPercentage: 0.4,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            callback: (value, index, values) => {
                                return this.abbreviateName(
                                    store.formatText(
                                        this.chartData.labels[index]
                                    )
                                );
                            },
                        },
                    },
                    y: {
                        grid: {
                            display: true,
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: (value) => this.abbreviateNumber(value),
                        },
                        position: "right",
                    },
                },
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            font: {
                                size: 16,
                            },
                            usePointStyle: true,
                            pointStyle: "circle",
                            boxWidth: 8,
                            boxHeight: 8,
                        },
                    },
                    filler: {
                        propagate: true,
                    },
                    datalabels: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.dataset.label || "";
                                const value = context.raw;
                                return `${label}: ${this.abbreviateNumber(
                                    value
                                )}`;
                            },
                        },
                    },
                },
            },

            projectsInfo: [],
            selectedProjects: [],
            loaded: false,
        };
    },
    beforeCreate() {
        this.emitter.on("updateGraph", async (value) => {
            if (this.selectedProjects.includes(value)) {
                this.selectedProjects = this.selectedProjects.filter(
                    (id) => id !== value
                );
            } else {
                this.selectedProjects.push(value);
            }

            await this.getProjectsDeadlines();
        });
        this.emitter.on("updateLang", (upd) => {
            this.populateChartData();
        });
    },
    async beforeMount() {
        await this.getProjectsDeadlines();

        this.selectedProjects = this.projectsInfo.map((project) => project._id);

        this.populateChartData();
        this.loaded = true;
    },
    methods: {
        async getProjectsDeadlines() {
            const authHeader = store.getAuthHeader();
            let res;
            try {
                res = await axios.get(
                    `${store.BACKEND_URL}/api/statistics/resources/`,
                    {
                        withCredentials: false,
                        headers: authHeader,
                    }
                );
            } catch (er) {
                console.log(er);
                return;
            }

            if (res.status !== 200) {
                this.$toast.error(
                    "Something went wrong retrieving projects used resources."
                );
                return;
            }

            if (this.projectsInfo.length === 0) {
                this.projectsInfo = res.data;
            }

            this.populateChartData();
        },
        populateChartData() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            const labels = filteredProjects.map((project) => project.title);
            const totalExpenses = filteredProjects.map(
                (project) => project.total_expenses
            );
            const usedExpenses = filteredProjects.map(
                (project) => project.used_expenses
            );

            this.chartData.labels = labels;
            this.chartData.datasets[0].data = totalExpenses;
            this.chartData.datasets[1].data = usedExpenses;

            this.chartData.datasets[0].label = this.$t("total_expenses");
            this.chartData.datasets[1].label = this.$t("used_expenses");
        },

        abbreviateName(name) {
            const maxLength = 20;
            const words = name.split(" ");
            let lines = [];
            let currentLine = "";

            words.forEach((word) => {
                if ((currentLine + word).length <= maxLength) {
                    currentLine += word + " ";
                } else {
                    lines.push(currentLine.trim());
                    currentLine = word + " ";
                }
            });

            if (currentLine) {
                lines.push(currentLine.trim());
            }
            return lines;
        },
        abbreviateNumber(value) {
            if (value >= 1e6) {
                return (value / 1e6).toFixed(1) + "M";
            }
            if (value >= 1e3) {
                return (value / 1e3).toFixed(1) + "K";
            }
            return value.toFixed(2);
        },
    },
};
</script>
