<template>
    <div>
        <div class="grid justify-between lg:grid-cols-2">
            <div class="flex flex-row">
                <div
                    class="flex items-center justify-start w-full mr-3 sty-input inpt-filter"
                >
                    <Icon type="search" class="mr-2" />
                    <input
                        type="text"
                        class="w-full py-1 bg-transparent outline-none placeholder:italic"
                        :placeholder="$t('search_projects')"
                        v-model="search_changes"
                    />
                </div>
            </div>
            <div class="flex justify-end">
                <label for="request-id" class="btn-master btn-default"
                    >{{ $t("file_upload") }}
                    <input
                        type="file"
                        id="request-id"
                        ref="requestRef"
                        class="hidden"
                        @change="store.handleFileChange"
                        accept=".xls, .xlsx"
                    />
                </label>
            </div>
        </div>
        <div
            class="grid items-start justify-start gap-4 my-2 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 over-proj"
            v-if="!loading"
        >
            <div class="card-project" v-for="changeReq in filterAllChange">
                <div class="grid gap-1 lg:grid-cols-3">
                    <div
                        class="col-span-2 truncate project-title"
                        :title="changeReq.title"
                    >
                        {{ changeReq.title }}
                    </div>
                    <div
                        class="text-gray-500 capitalize bg-gray-100 border border-gray-300 project-status"
                    >
                        {{ $t("view_request") }}
                    </div>
                </div>
                <div class="update-date">
                    {{ $t("updated_at") }}
                    {{ changeReq.lastUpdate }}
                </div>

                <div
                    class="break-words project-description h-[20vh]"
                    :title="changeReq.description"
                >
                    {{ changeReq.description }}
                </div>

                <div class="grid my-3 lg:grid-cols-2">
                    <div>
                        <div class="project-date">{{ $t("start_date") }}</div>
                        <div class="text-base font-bold">
                            {{ store.formatDate(changeReq.startDate) }}
                        </div>
                    </div>
                    <div>
                        <div class="project-date">{{ $t("final_date") }}</div>
                        <div class="text-base font-bold">
                            {{ store.formatDate(changeReq.lastUpdate) }}
                        </div>
                    </div>
                </div>
                <div class="grid gap-2 my-2 lg:grid-cols-2">
                    <div class="btn-card">{{ $t("refuse") }}</div>
                    <div class="btn-card btn-aprove">{{ $t("approve") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
export default {
    data() {
        return {
            store,
            allChanges: [],
            search_changes: "",
        };
    },
    computed: {
        filterAllChange() {
            return this.allChanges.filter((changeReq) => {
                return changeReq.title
                    .toLowerCase()
                    .includes(this.search_changes?.toLowerCase());
            });
        },
    },
    created() {},
};
</script>
