<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-position" v-if="openModal">
            <div class="card-modal">
                <div
                    class="flex flex-row justify-between px-3 py-2 border-separete"
                >
                    <div class="title-modal">
                        {{ $t("financial_income") }}
                    </div>
                    <div
                        class="flex items-center justify-center"
                        @click="closeModal"
                    >
                        <Icon
                            type="x-circle"
                            class="w-8 h-8 text-gray-600 cursor-pointer hover:text-gray-800"
                        />
                    </div>
                </div>
                <div class="p-3">
                    <div class="grid grid-cols-2 gap-3 card-body">
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("period")
                            }}</label>
                            <input
                                type="date"
                                class="input-modal"
                                v-model="this.item.period"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">
                                {{ $t("financial_income") }}</label
                            >
                            <input
                                type="text"
                                class="input-modal"
                                v-model="this.item.financial_income"
                                @input="onInput('financial_income', $event)"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("invested_value")
                            }}</label>
                            <input
                                type="text"
                                class="input-modal"
                                v-model="this.item.invested_value"
                                @input="onInput('invested_value', $event)"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("redeemed_value")
                            }}</label>
                            <input
                                type="text"
                                class="input-modal"
                                v-model="this.item.redeemed_value"
                                @input="onInput('redeemed_value', $event)"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("net_value")
                            }}</label>
                            <input
                                type="text"
                                class="input-modal"
                                v-model="this.item.net_value"
                                @input="onInput('net_value', $event)"
                            />
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("balance")
                            }}</label>
                            <input
                                type="text"
                                class="input-modal"
                                v-model="this.item.balance"
                                @input="onInput('balance', $event)"
                            />
                        </div>
                    </div>
                    <!-- <div class="p-3">
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("attach")
                            }}</label>
                            <input type="file" class="w-full input-modal" />
                        </div>
                    </div> -->
                    <div class="flex flex-row justify-end py-4">
                        <div class="btn-default" @click="closeModal">
                            {{ $t("cancel") }}
                        </div>
                        <div
                            class="btn-default btn-selected"
                            @click="saveCurrent"
                        >
                            {{ $t("save") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { store } from "@/store";
import Icon from "@/containers/UI/Icon.vue";

export default {
    components: {
        Icon,
    },
    props: {
        projectId: String,
    },
    data() {
        return {
            store,
            item: {
                period: "",
                financial_income: null,
                invested_value: null,
                redeemed_value: null,
                net_value: null,
                balance: null,
            },
            openModal: false,
        };
    },
    created() {
        this.emitter.on("openModalFinancial", (val) => {
            this.openModal = val;
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;

            this.item.period = "";
            this.item.financial_income = null;
            this.item.invested_value = null;
            this.item.redeemed_value = null;
            this.item.net_value = null;
            this.item.balance = null;
        },
        async saveCurrent() {
            const authHeader = store.getAuthHeader();

            const requestData = {
                period: this.item.period,
                revenue: this.store.formatValueToSave(
                    this.item.financial_income
                ),
                invested: this.store.formatValueToSave(
                    this.item.invested_value
                ),
                withdraw: this.store.formatValueToSave(
                    this.item.redeemed_value
                ),
                net_income: this.store.formatValueToSave(this.item.net_value),
                balance: this.store.formatValueToSave(this.item.balance),
            };

            const res = await axios.post(
                `${store.BACKEND_URL}/api/projects/${this.projectId}/revenues/`,
                requestData,
                { withCredentials: false, headers: authHeader }
            );

            this.emitter.emit("newFinancial", this.item);

            this.item = {
                period: "",
                financial_income: null,
                invested_value: null,
                redeemed_value: null,
                net_value: null,
                balance: null,
            };
            this.openModal = false;
        },
        onInput(field, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            this.item[field] = formattedValue;
        },
    },
};
</script>
