<template>
    <div :class="['sidebar', { closed: !openSide }]">
        <div
            class="items-center justify-between mb-4 font-sidebar"
            :class="['flex flex-row ', { 'compressed-btn': !openSide }]"
        >
            <div class="flex flex-row items-center justify-start">
                <img src="../assets/img/logo.png" width="80" class="logo" />
                <div class="uppercase" v-if="openSide">Ideation</div>
            </div>
            <div class="box-return mr-2" @click="closeSidebar">
                <Icon
                    type="arrow-left"
                    class="w-5 h-5"
                    :class="!openSide ? 'rotate-up' : 'rotate-down'"
                />
            </div>
        </div>

        <div
            :class="[
                'transition-all duration-500',
                { 'px-3': openSide, 'px-1': !openSide },
            ]"
        >
            <div
                class="option-collapse"
                :class="selected === 'dashboard' ? 'comp-active' : ''"
                @click="submenu('dashboard', '/dashboard')"
            >
                <Icon
                    type="home"
                    :class="[
                        'w-5 h-5',
                        {
                            'mr-2 ': openSide,
                            'text-selected': selected === 'dashboard',
                        },
                    ]"
                />
                <span v-if="openSide">{{ $t("dashboard") }}</span>
            </div>
            <div
                class="option-collapse"
                :class="selected === 'projects' ? 'comp-active' : ''"
                @click="submenu('projects', '/projects')"
            >
                <Icon
                    type="page-paper"
                    :class="[
                        'w-5 h-5',
                        {
                            'mr-2': openSide,
                            'text-selected': selected === 'projects',
                        },
                    ]"
                    class="text-gray-600"
                />
                <span v-if="openSide">{{ $t("projects") }}</span>
            </div>
            <div
                class="option-collapse"
                :class="selected === 'management' ? 'comp-active' : ''"
                @click="submenu('management', '/management')"
            >
                <Icon
                    type="setting"
                    :class="[
                        'w-5 h-5',
                        {
                            'mr-2': openSide,
                            'text-selected': selected === 'management',
                        },
                    ]"
                    class="text-gray-600"
                />
                <span v-if="openSide">{{ $t("management") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store";
import Icon from "./UI/Icon.vue";

export default {
    components: { Icon },
    data() {
        return {
            store,
            selected: store.position,
            sizeWidth: window.innerWidth,
            openSide: true,
        };
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        window.onresize = () => {
            this.sizeWidth = window.innerWidth;
        };
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth < 1024) {
                this.openSide = false;
                this.emitter.emit("resizeBody", false);
            } else {
                this.openSide = true;
                this.emitter.emit("resizeBody", true);
            }
        },
        submenu(id, link) {
            this.$router.push(link);
            this.selected = id;
            store.position = id;
        },
        closeSidebar() {
            this.openSide = !this.openSide;
            if (this.openSide) {
                this.emitter.emit("resizeBody", true);
            } else {
                this.emitter.emit("resizeBody", false);
            }
        },
    },
};
</script>

<style scoped>
.sidebar {
    width: 230px;
    transition: width 0.5s;
    height: 100vh;
    position: fixed;
    border-right: 1px solid v-bind("store.colors.borderInput");
}

.sidebar.closed {
    width: 80px;
}

.logo {
    transition: width 0.5s;
}

.compressed-btn {
    display: flex;
    flex-direction: column;
}

.option-collapse {
    display: flex;
    align-items: center;
}
</style>
