<template>
    <div>
        <div class="grid justify-between mb-6 lg:grid-cols-2">
            <div class="flex flex-row">
                <div
                    class="flex items-center justify-start w-full mr-3 sty-input inpt-filter"
                >
                    <Icon type="search" class="mr-2" />
                    <input
                        type="text"
                        class="w-full py-1 bg-transparent outline-none placeholder:italic"
                        :placeholder="$t('search_projects')"
                        v-model="search_project"
                    />
                </div>
            </div>
            <div class="flex justify-end">
                <label for="project-id" class="btn-master btn-default"
                    >{{ $t("file_upload") }}
                    <input
                        type="file"
                        id="project-id"
                        ref="projectRef"
                        class="hidden"
                        @change="handleFileChange"
                        accept=".xls, .xlsx"
                    />
                </label>
            </div>
        </div>
        <div
            class="grid xl:grid-cols-4 gap-4 lg:grid-cols-2 sm:grid-cols-1 over-proj"
            v-if="!loading"
        >
            <div class="card-project" v-for="project in filterAllProjects">
                <div class="grid lg:grid-cols-1">
                    <div class="flex justify-between mb-2">
                        <div
                            class="capitalize project-status"
                            :class="project.status"
                        >
                            {{ $t(project.status) }}
                        </div>
                        <div
                            class="flex items-center justify-center m-0 px-2 py-2 rounded-md cursor-pointer btn-default w-[50px] btn-hover-delete"
                            @click="deleteProject(project)"
                        >
                            <Icon type="trash" class="w-5 h-5" />
                        </div>
                    </div>
                    <div class="col-span-2 project-title">
                        {{ project.title }}
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <div class="update-date">
                        {{ $t("updated_at") }}
                        {{ store.formatDate(project.lastUpdate) }}
                    </div>
                    <div class="update-date">
                        {{ $t("start_date") }}
                        {{ store.formatDate(project.startDate) }}
                    </div>
                </div>

                <div
                    class="break-words project-description pr-6"
                    :title="project.description"
                >
                    {{ project.description }}
                </div>

                <div class="flex justify-between mb-2">
                    <div class="project-progress">
                        {{ $t("project_progress") }}:
                        <span class="text-base font-bold"
                            >{{
                                Math.round(project.activitiesPercentage)
                            }}%</span
                        >
                    </div>
                    <div class="project-progress">
                        {{ $t("finished_activities") }}:
                        <span class="text-base font-bold"
                            >{{ project.completedActivities }}/{{
                                project.totalActivities
                            }}</span
                        >
                    </div>
                </div>
                <div
                    class="btn-default w-full mt-2"
                    @click="goToDetails(project._id)"
                >
                    {{ $t("access_project") }}
                </div>
            </div>
        </div>
        <div class="h-[78vh] flex items-center justify-center" v-if="loading">
            <Spinner />
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import router from "@/router";
import axios from "axios";
import Spinner from "@/containers/UI/Spinner.vue";
import Icon from "@/containers/UI/Icon.vue";

import ModalFilter from "@/components/modal/ModalFilter";

export default {
    components: {
        Spinner,
        Icon,
        ModalFilter,
    },
    data() {
        return {
            store,
            projects: [],
            loading: true,
            search_project: "",
        };
    },
    async beforeMount() {
        await this.getProjects();
    },
    created() {
        this.emitter.on("reload projects", () => {
            this.loading = true;
            this.getProjects();
        });
    },
    computed: {
        filterAllProjects() {
            return this.projects.filter((proj) => {
                return proj.title
                    .toLowerCase()
                    .includes(this.search_project?.toLowerCase());
            });
        },
    },
    methods: {
        async handleFileChange(event) {
            this.loading = true;
            const authHeader = store.getAuthHeader();

            if (event.target.files.length > 1) {
                this.$toast.warning("Only one file can be uploaded at a time.");
                return;
            }

            const file = event.target.files[0];
            const formData = new FormData();

            formData.append("file", file);
            let res;
            try {
                res = await axios.post(
                    `${store.BACKEND_URL}/api/projects/upload/`,
                    formData,
                    {
                        withCredentials: false,
                        headers: authHeader,
                    }
                );
                if (res.status == 201) {
                    router.push(`/projects/${res.data.id}`);
                }
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.detail
                ) {
                    this.errorMessage = error.response.data.detail;

                    this.$toast.warning(
                        this.$t("check_the_sent_document_and_try_again")
                    );
                    this.emitter.emit("openModalError", [
                        true,
                        this.errorMessage,
                    ]);

                    this.loading = false;
                    return;
                } else {
                    this.errorMessage =
                        "Ocorreu um erro. Tente novamente mais tarde.";
                    // this.$toast.warning(this.errorMessage);

                    this.loading = false;
                    return;
                }
            }
        },

        goToDetails(projectId) {
            router.push(`/projects/${projectId}`);
        },

        async getProjects() {
            this.projects = [];
            const authHeader = store.getAuthHeader();
            const projectsStats = await this.getProjectsStats();
            for (const projectStats of projectsStats) {
                let projectResponse;
                try {
                    projectResponse = await axios.get(
                        `${store.BACKEND_URL}/api/projects/${projectStats._id}/`,
                        {
                            headers: authHeader,
                        }
                    );
                } catch (error) {
                    this.loading = false;
                    this.$toast.error(this.$t("retrieve_projects_error"));
                    return;
                }

                if (projectResponse.status != 200) {
                    this.$toast.error(this.$t("retrieve_projects_error"));
                    return;
                }

                const project = projectResponse.data;
                const base = project.base;

                const title = base.title;
                const description = project.technical_data.goal;
                const projectStartDate = new Date(base.contract_date);
                const totalActivities = projectStats.total_activities;

                const completedActivitiesCount =
                    projectStats.completed_activities;

                const status =
                    completedActivitiesCount == totalActivities
                        ? "complete"
                        : "in_progress";

                this.projects.push({
                    _id: project._id,
                    title,
                    status,
                    description,
                    lastUpdate: new Date(project.updated_at),
                    startDate: projectStartDate,
                    activitiesPercentage: projectStats.activitiesPercentage,
                    completedActivities: completedActivitiesCount,
                    totalActivities: totalActivities,
                });
            }
            let count = this.projects.length;
            this.emitter.emit("counter projects", count);
            this.emitter.emit("all projects", this.projects);

            this.loading = false;
        },
        async getProjectsStats() {
            const authHeader = store.getAuthHeader();
            const progressRes = await axios.get(
                `${store.BACKEND_URL}/api/statistics/progress/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );
            if (progressRes.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving project information."
                );
                return;
            }
            const resourcesRes = await axios.get(
                `${store.BACKEND_URL}/api/statistics/resources/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );
            if (resourcesRes.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving project information."
                );
                return;
            }
            const idToResource = {};
            for (const resource of resourcesRes.data) {
                idToResource[resource._id] = resource;
            }

            let joinedData = [];
            for (const projectProgress of progressRes.data) {
                const activities = {};

                for (const act of projectProgress.activities_progress) {
                    if (act._id in activities) {
                        const prevAct = activities[act._id];
                        if (act.updated_at >= prevAct.updated_at) {
                            activities[act._id] = act;
                        }
                    } else {
                        activities[act._id] = act;
                    }
                }

                const values = Object.values(activities);
                const activitiesPercentage =
                    values.reduce((acc, act) => acc + act.progress, 0) /
                    values.length;

                const projectResources = idToResource[projectProgress._id];
                joinedData.push({
                    ...projectProgress,
                    activitiesPercentage,
                    usedBudget: projectResources.used_expenses,
                    totalBudget: projectResources.total_expenses,
                });
            }
            return joinedData;
        },
        async deleteProject(project) {
            this.emitter.emit("confirmDeletePtr", project);
        },
    },
};
</script>
