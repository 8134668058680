<template>
    <div>
        <div class="grid justify-between lg:grid-cols-2">
            <div class="flex flex-row">
                <div
                    class="flex items-center justify-start w-full mr-3 sty-input inpt-filter"
                >
                    <Icon type="search" class="mr-2" />
                    <input
                        type="text"
                        class="w-full py-1 bg-transparent outline-none placeholder:italic"
                        :placeholder="$t('search_projects')"
                        v-model="search_proposals"
                    />
                </div>
                <div
                    class="bg-white justify-evenly btn-default"
                    @click="openModalFilter"
                >
                    <Icon type="filter" class="w-4 h-4" />
                    <div>{{ $t("filter") }}</div>
                </div>
                <ModalFilter />
            </div>
            <div class="flex justify-end">
                <label for="proposal-ref" class="btn-master btn-default"
                    >{{ $t("file_upload") }}
                    <input
                        type="file"
                        id="proposal-ref"
                        ref="proposalRef"
                        class="hidden"
                        @change="store.handleFileChange"
                        accept=".xls, .xlsx"
                    />
                </label>
            </div>
        </div>
        <div
            class="grid items-start justify-start gap-4 my-2 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 over-proj"
            v-if="!loading"
        >
            <div class="card-project" v-for="proposals in filterAllProposals">
                <div class="grid gap-1 lg:grid-cols-3">
                    <div
                        class="col-span-2 truncate project-title"
                        :title="proposals.title"
                    >
                        {{ proposals.title }}
                    </div>
                    <div class="capitalize project-status request">
                        {{ $t("waiting_approval") }}
                    </div>
                </div>
                <div class="update-date">
                    {{ $t("updated_at") }}
                    {{ proposals.lastUpdate }}
                </div>

                <div
                    class="break-words project-description h-[20vh]"
                    :title="proposals.description"
                >
                    {{ proposals.description }}
                </div>

                <div class="grid my-3 lg:grid-cols-2">
                    <div>
                        <div class="project-date">{{ $t("start_date") }}</div>
                        <div class="text-base font-bold">
                            {{ store.formatDate(proposals.startDate) }}
                        </div>
                    </div>
                    <div>
                        <div class="project-date">{{ $t("final_date") }}</div>
                        <div class="text-base font-bold">
                            {{ store.formatDate(proposals.lastUpdate) }}
                        </div>
                    </div>
                </div>

                <div class="grid gap-2 my-2 lg:grid-cols-2">
                    <div class="btn-card" @click="refuse(proposals._id)">
                        {{ $t("refuse") }}
                    </div>
                    <div
                        class="btn-card btn-aprove"
                        @click="aprove(proposals._id)"
                    >
                        {{ $t("approve") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { store } from "@/store";
import ModalFilter from "@/components/modal/ModalFilter";
import Icon from "@/containers/UI/Icon.vue";

export default {
    components: {
        Icon,
        ModalFilter,
    },
    data() {
        return {
            store,
            allProposals: [],
            search_proposals: "",
        };
    },
    computed: {
        filterAllProposals() {
            return this.allProposals.filter((proposals) => {
                return proposals.title
                    .toLowerCase()
                    .includes(this.search_proposals?.toLowerCase());
            });
        },
    },
    async created() {
        await this.getProjects();
    },
    methods: {
        openModalFilter() {
            this.emitter.emit("open filter", true);
        },
        inProgress() {
            this.$toast.error("Function in progress.");
        },
        async aprove(projectId) {
            const authHeader = store.getAuthHeader();

            let res;
            try {
                res = await axios(
                    `${store.BACKEND_URL}/api/projects/${projectId}/approve/`,
                    {
                        method: "PUT",
                        headers: authHeader,
                    }
                );
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            window.location.reload();
        },
        async refuse(projectId) {
            const authHeader = store.getAuthHeader();

            let res;
            try {
                res = await axios(
                    `${store.BACKEND_URL}/api/projects/${projectId}/refuse/`,
                    {
                        method: "PUT",
                        headers: authHeader,
                    }
                );
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            this.getProjects();
        },
        async getProjects() {
            this.allProposals = [];
            const authHeader = store.getAuthHeader();

            let res;
            try {
                res = await axios.get(
                    `${store.BACKEND_URL}/api/projects/?status=submitted`,
                    {
                        headers: authHeader,
                    }
                );
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            if (res.status != 200) {
                this.$toast.error(this.$t("retrieve_projects_error"));
                return;
            }

            for (const project of res.data) {
                const base = project.base;
                const technicalData = project.technical_data;

                const title = base.title;
                const description = technicalData.goal;
                const projectStartDate = new Date(base.contract_date);
                const totalActivities = project.activities.length;

                const completedActivitiesCount = project.activities.reduce(
                    (acc, activity) => {
                        return acc + (activity.progress == 100 ? 1 : 0);
                    },
                    0
                );
                const status =
                    completedActivitiesCount == totalActivities
                        ? "complete"
                        : "in_progress";

                this.allProposals.push({
                    _id: project._id,
                    title,
                    status,
                    description,
                    lastUpdate: new Date(project.updated_at),
                    startDate: projectStartDate,
                    completedActivities: completedActivitiesCount,
                    totalActivities: totalActivities,
                });
            }

            this.emitter.emit("counter proposals", this.allProposals.length);

            this.loading = false;
        },
    },
};
</script>
