<template>
    <div class="my-8">
        <div class="my-1">
            <div class="title-form">{{ $t("keywords") }}</div>
            <div class="flex flex-row">
                <div class="mr-6 value-form" v-for="ky in info.keywords">
                    {{ ky }}
                </div>
            </div>
        </div>
        <div class="my-2 separete"></div>
        <div class="my-4 italic font-bold font-md">
            {{ $t("research_entities") }}
        </div>
        <div class="grid grid-cols-4 gap-6">
            <div class="flex flex-col col-span-2">
                <div class="title-form">{{ $t("main_proponent") }}</div>
                <div class="value-form">{{ info.mainExecutor }}</div>
            </div>
            <div class="flex flex-col">
                <div class="title-form">{{ $t("main_executor") }}</div>
                <div class="value-form">{{ info.mainProponent }}</div>
            </div>

            <div class="flex flex-col">
                <div class="title-form">{{ $t("anp_number") }}</div>
                <div class="value-form">{{ info.anpCreditation }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: Object,
    },
};
</script>
<style lang=""></style>
