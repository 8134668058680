<script>
import { store } from "@/store/index";
export default {
    data() {
        return {
            store,
        };
    },
};
</script>
<style></style>
