<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-bg" v-if="openModal">
            <div class="card-modal fadeIn">
                <div class="flex justify-between">
                    <div class="m-1 text-xl">{{ $t("add_document") }}</div>
                    <div @click="closeModal">
                        <Icon type="x-circle" class="w-6 h-6 cursor-pointer" />
                    </div>
                </div>
                <div class="my-1 separete"></div>
                <div class="px-4 my-3">
                    <div class="mt-4 mb-8">
                        <input
                            type="file"
                            id="fileInput"
                            @change="handleFileUpload"
                        />

                        <div class="flex flex-col my-4">
                            <label for="title" class="label-ptr"
                                >Descrição do resultado
                                <sup>(100 caracteres)</sup></label
                            >
                            <textarea
                                id="description"
                                rows="4"
                                class="sty-input"
                                maxlength="100"
                                v-model="description"
                                @input="updateCharacterCount"
                            ></textarea>
                            <small
                                >{{ remainingCharacters }} caracteres
                                restantes</small
                            >
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <div
                            class="btn-default btn-clear mx-0"
                            @click="addFile"
                        >
                            Adicionar
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
export default {
    components: {
        Icon,
    },
    data() {
        return {
            openModal: false,
            description: "",
            maxLength: 100,
            file: null,
        };
    },
    computed: {
        remainingCharacters() {
            return this.maxLength - this.description.length;
        },
    },
    created() {
        this.emitter.on("openModalfile", () => {
            this.openModal = true;
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        handleFileUpload(event) {
            this.file = event.target.files[0];
        },
        addFile() {
            const document = {
                document: this.file,
                description: this.description,
            };

            this.emitter.emit("sendDocumentPtr", document);

            this.description = "";
            this.openModal = false;
        },
    },
};
</script>
