<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">{{ $t("general_information") }}</div>
            <div class="grid grid-cols-1 gap-4">
                <div class="flex flex-col">
                    <label for="title" class="label-ptr"
                        >{{ $t("title") }}
                        <sup class="lowercase"
                            >max 300 {{ $t("characters") }}</sup
                        >
                    </label>
                    <input type="text" class="sty-input" v-model="title" />
                    <small
                        >{{ store.remainingCharacters(title, 300) }}
                        {{ $t("characters_remaining") }}</small
                    >
                </div>
                <div class="flex flex-col">
                    <label for="title" class="label-ptr">{{
                        $t("qualification")
                    }}</label>
                    <select class="sty-input" v-model="valQualification">
                        <option :value="ql.id" v-for="ql in qualification">
                            {{ $t(ql.name) }}
                        </option>
                    </select>
                </div>
                <div class="grid grid-cols-1 gap-4">
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("purpose_work_plan")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="purpose"
                        />
                    </div>
                </div>
                <div
                    class="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4"
                >
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("previous_work_plan")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="replace_work"
                        >
                            <option value="yes">{{ $t("yes") }}</option>
                            <option value="no">{{ $t("no") }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("subject_authorization")
                        }}</label>
                        <select
                            class="capitalize sty-input"
                            v-model="previously_authorized"
                        >
                            <option value="yes">{{ $t("yes") }}</option>
                            <option value="no">{{ $t("no") }}</option>
                        </select>
                    </div>
                </div>
                <div
                    class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4"
                >
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("ANP_project")
                        }}</label>
                        <input
                            type="number"
                            class="sty-input"
                            v-model="anp_project_number"
                            :class="{
                                'bg-disabled': replace_work != 'yes',
                            }"
                            :disabled="replace_work != 'yes'"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("authorization_number")
                        }}</label>
                        <input
                            type="number"
                            class="sty-input"
                            v-model="authorization_number"
                            :class="{
                                'bg-disabled': previously_authorized != 'yes',
                            }"
                            :disabled="previously_authorized != 'yes'"
                        />
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr">{{
                            $t("date_hire_start")
                        }}</label>
                        <input
                            type="date"
                            class="sty-input"
                            v-model="contract_date"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="flex my-3"
            :class="currentIndex == 0 ? 'justify-end' : 'justify-between'"
        >
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>

            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },

    data() {
        return {
            store,
            qualification: [
                { id: "Pesquisa básica", name: "basic_search" },
                { id: "Pesquisa aplicada", name: "applied_research" },
                {
                    id: "Desenvolvimento experimenta",
                    name: "development_experiments",
                },
                {
                    id: "Pesquisa em meio ambiente",
                    name: "environmental_research",
                },
                {
                    id: "Pesquisa em ciências sociais, humanas e da vida",
                    name: "research_sciences",
                },
                { id: "Pesquisa em TIC", name: "ICT_research" },
                { id: "Protótipo ou unidade piloto", name: "prototype_unit" },
                {
                    id: "Capacitação técnica de fornecedores",
                    name: "technical_suppliers",
                },
                {
                    id: "TIB - Qualificação de produto, processo ou serviço",
                    name: "TIB_qualification",
                },
                {
                    id: "TIB - Normalização técnica",
                    name: "TIB_standardization",
                },
                {
                    id: "TIB - Treinamento e avaliação de conformidade",
                    name: "TIB_assessment",
                },
                {
                    id: "Engenharia básica não rotineira",
                    name: "non_routine_engineering",
                },
                {
                    id: "Estudo de bacias com aquisição de dados",
                    name: "basin_acquisition",
                },
                {
                    id: "Formação de Recursos Humanos",
                    name: "human_resources_training",
                },
                { id: "Infraestrutura", name: "infrastructure" },
                {
                    id: "Infraestrutura - nova edificação ou acréscimo de área",
                    name: "infrastructure_area",
                },
                {
                    id: "Apoio a instalação laboratorial de PD&I",
                    name: "support_installation",
                },
            ],

            title: "",
            purpose: "",
            replace_work: "no",
            previously_authorized: "no",
            anp_project_number: "",
            authorization_number: "",
            contract_date: "",

            valQualification: "",
            loading: false,
        };
    },

    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : null;

        if (data != null && data.hasOwnProperty("base")) {
            this.title = data.base.title;
            this.valQualification = this.store.formatText(
                data.base.qualification
            );
            this.purpose = data.base.work_purpose;
            this.replace_work = data.base.replace_work === false ? "no" : "yes";
            this.previously_authorized =
                data.base.previously_authorized === false ? "no" : "yes";
            this.anp_project_number = data.base.anp_project_number;
            this.authorization_number = data.base.authorization_number;
            this.contract_date = data.base.contract_date;
        }
    },

    methods: {
        async handleNextPage() {
            this.loading = true;

            const updatedFields = {
                version: 5,
                title: this.title,
                qualification: this.valQualification,
                work_purpose: this.purpose,
                replace_work: this.replace_work === "no" ? false : true,
                previously_authorized:
                    this.previously_authorized === "no" ? false : true,
                anp_project_number: this.anp_project_number.toString(),
                authorization_number: this.authorization_number.toString(),
                contract_date: this.contract_date,
            };

            localStorage.setItem(
                "ptrDatas",
                JSON.stringify({ base: updatedFields })
            );

            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1500);
        },
    },
};
</script>
