<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-bg" v-if="openModal">
            <div class="card-modal fadeIn">
                <div class="flex justify-between">
                    <div class="m-1 text-xl">{{ $t("add_commitment") }}</div>
                    <div @click="closeModal">
                        <Icon type="x-circle" class="w-6 h-6 cursor-pointer" />
                    </div>
                </div>
                <div class="my-1 separete"></div>
                <div class="px-4 my-3">
                    <div class="mt-4 mb-8">
                        <div class="flex flex-col my-4">
                            <label for="title" class="label-ptr"
                                >{{ $t("add_commitment") }} (R$)</label
                            >
                            <input
                                type="text"
                                class="sty-input"
                                v-model="value"
                                @input="onInput"
                            />
                        </div>
                        <div class="flex flex-col my-4">
                            <label for="title" class="label-ptr">{{
                                $t("referent_year")
                            }}</label>
                            <input
                                type="number"
                                class="sty-input"
                                v-model="year"
                            />
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <div
                            class="btn-default btn-clear mx-0"
                            @click="addCommitment"
                        >
                            {{ $t("add_commitment") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";
import { store } from "@/store";
import axios from "axios";
export default {
    components: {
        Icon,
    },
    data() {
        return {
            store,
            openModal: false,
            value: "",
            year: "",
        };
    },
    created() {
        this.emitter.on("openModalCommitment", () => {
            this.openModal = true;
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;
            this.value = "";
            this.year = "";

            this.emitter.emit("updateCommitment", true);
        },
        onInput(event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            this.value = formattedValue;
        },
        async addCommitment() {
            const authHeader = store.getAuthHeader();
            const body = {
                year: this.year,
                value: parseFloat(
                    this.value.replace(/[R$.\s]/g, "").replace(",", ".")
                ),
            };
            try {
                let res = await axios.put(
                    `${store.BACKEND_URL}/api/projects/${this.$route.params.id}/contract/`,
                    body,
                    { withCredentials: false, headers: authHeader }
                );
                if (res.status == 204) {
                    this.$toast.success(this.$t("commitment_added"));
                    this.value = "";
                    this.year = "";
                } else {
                    this.$toast.error(this.$t("add_error_please_try_again"));
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
