<template>
    <div class="txt-titulo">{{ $t("pending_number") }}</div>
    <div class="grid grid-cols-2 gap-2">
        <div class="flex items-center justify-center donuts">
            <div
                class="flex items-center justify-center absolute top-0 h-[27vh] w-[200px]"
            >
                <span class="pendenciesTotal"
                    >{{ totalPendencies }}
                    <span class="subtitle">{{ $t("pendencies") }}</span></span
                >
            </div>
            <div class="chart-container-pend">
                <Doughnut
                    id="my-chart-id"
                    :options="chartOptions"
                    :data="chartData"
                />
            </div>
        </div>
        <div
            class="legends h-[33vh] overflow-y-auto flex justify-center items-center"
        >
            <table>
                <tbody>
                    <tr v-for="(label, tsId) in labels" :key="tsId">
                        <td class="border-none">
                            <div
                                class="color-legend"
                                :style="'background-color:' + label.color"
                            ></div>
                        </td>
                        <td
                            class="pr-5 capitalize border-none"
                            :title="label.text"
                        >
                            {{
                                label.text.length > 20
                                    ? label.text.substring(0, 20) + "..."
                                    : label.text
                            }}
                        </td>
                        <td class="border-none pendencies">
                            {{ label.pendencies }} {{ $t("pendencies") }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { store } from "@/store";
import axios from "axios";

import { Doughnut } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default {
    components: { Doughnut },
    data() {
        return {
            loaded: false,
            store,
            selectedProjects: [],
            projectsInfo: [],
            labels: [],
            totalPendencies: 0,
            chartOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                        position: "right",
                    },
                    filler: {
                        propagate: true,
                    },
                    datalabels: {
                        display: false,
                    },
                },
                cutout: "70%",
                radius: "100%",
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        display: false,
                    },
                },
            },
        };
    },

    beforeCreate() {
        this.emitter.on("updateGraph", (value) => {
            if (this.selectedProjects.includes(value)) {
                const idx = this.selectedProjects.indexOf(value);
                this.selectedProjects.splice(idx, 1);
            } else {
                this.selectedProjects.push(value);
            }
        });
    },

    async beforeMount() {
        await this.getPCPendencies();
        this.loaded = true;
    },

    computed: {
        chartData() {
            const filteredProjects = this.projectsInfo.filter((project) =>
                this.selectedProjects.includes(project._id)
            );

            const backgroundColor = [
                "#6CD6AA",
                "#ED7545",
                "#06B6D4",
                "#B778A2",
                "#155E75",
            ];

            // Define uma cor cinza para valores zero
            const zeroColor = "#C0C0C0";

            this.labels = filteredProjects.map((p, idx) => {
                return {
                    text: store.toTitleCase(p.title),
                    color: backgroundColor[idx % backgroundColor.length],
                    pendencies: p.pc_pendencies,
                };
            });

            const realData = filteredProjects.map((p) => p.pc_pendencies);
            const displayData = realData.map((value) =>
                value === 0 ? 0.01 : value
            );

            this.totalPendencies = realData.reduce((acc, v) => (acc += v), 0);

            const dynamicBackgroundColor = displayData.map((value, idx) => {
                return value === 0.01
                    ? zeroColor
                    : backgroundColor[idx % backgroundColor.length];
            });

            const datasets = [
                {
                    backgroundColor: dynamicBackgroundColor,
                    borderRadius: 6,
                    data: displayData,
                    borderWidth: 4,
                },
            ];

            return {
                fill: true,
                datasets,
            };
        },
    },

    methods: {
        async getPCPendencies() {
            const authHeader = store.getAuthHeader();
            if (this.projectsInfo.length != 0) {
                return;
            }

            const res = await axios.get(
                `${store.BACKEND_URL}/api/statistics/pc-pendencies/`,
                {
                    withCredentials: false,
                    headers: authHeader,
                }
            );

            if (res.status != 200) {
                this.$toast.error(
                    "Something went wrong retrieving PC information."
                );
                return;
            }

            this.projectsInfo = res.data;
            this.selectedProjects = this.projectsInfo.map((v) => v._id);
        },
    },
};
</script>

<style>
.chart-container-pend {
    width: 300px;
    height: 300px;
}
</style>
