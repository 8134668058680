<template>
    <div>
        <div
            class="bg-modal"
            :style="{
                width: openModal ? '100%' : '0px',
                opacity: openModal ? '1' : '0',
            }"
        ></div>
        <div class="card-position" v-if="openModal">
            <div class="card-modal">
                <div
                    class="flex flex-row justify-between px-3 py-2 border-separete"
                >
                    <div class="title-modal">
                        {{ $t("request_budget_change") }}
                    </div>
                    <div
                        class="flex items-center justify-center"
                        @click="closeModal"
                    >
                        <Icon
                            type="x-circle"
                            class="w-8 h-8 text-gray-600 cursor-pointer hover:text-gray-800"
                        />
                    </div>
                </div>
                <div class="p-2">
                    <div class="grid grid-cols-2 gap-3 card-body">
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("category")
                            }}</label>
                            <select class="input-modal">
                                <option
                                    v-for="(ct, ctId) in categoryType"
                                    :value="ctId"
                                >
                                    {{ ct.title }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">Item</label>
                            <select class="input-modal">
                                <option
                                    v-for="(it, itId) in itemType"
                                    :value="itId"
                                >
                                    {{ it.title }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("quantity")
                            }}</label>
                            <input type="text" class="input-modal" />
                        </div>
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("changed_value")
                            }}</label>
                            <input type="text" class="input-modal" />
                        </div>
                    </div>
                    <div class="px-3">
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("justification")
                            }}</label>
                            <textarea
                                name=""
                                id=""
                                rows="6"
                                class="input-modal"
                            ></textarea>
                        </div>
                    </div>
                    <div class="px-3">
                        <div class="flex flex-col">
                            <label class="label-modal">{{
                                $t("description")
                            }}</label>
                            <textarea
                                name=""
                                id=""
                                rows="3"
                                class="input-modal"
                            ></textarea>
                        </div>
                    </div>
                    <div class="flex flex-row justify-end py-4">
                        <div class="btn-default" @click="closeModal">
                            {{ $t("cancel") }}
                        </div>
                        <div class="btn-default btn-selected">
                            {{ $t("save") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/containers/UI/Icon.vue";

export default {
    components: {
        Icon,
    },
    data() {
        return {
            openModal: false,
            categoryType: [{ title: "val one" }],
            itemType: [{ title: "val one" }],
        };
    },
    created() {
        this.emitter.on("openModalConsolidated", (val) => {
            this.openModal = val;
        });
    },
    methods: {
        closeModal() {
            this.openModal = false;
        },
        saveCurrent() {},
    },
};
</script>
<style></style>
