<template>
    <div>
        <div class="box-default size-box-section">
            <div class="title-form-ptr">
                {{
                    $t(
                        "expenses_related_to_direct_measurable_costs_of_RnD_activities"
                    )
                }}
            </div>
            <div v-for="(item, index) in costs_expenses" :key="index">
                <div class="subtitle-ptr">
                    {{ $t("expense") }} {{ index + 1 }}
                </div>
                <div class="grid grid-cols-3 gap-4 mb-8">
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr">{{
                            $t("executing_institution_or_company")
                        }}</label>
                        <input
                            type="text"
                            class="sty-input"
                            v-model="item.company_name"
                        />
                    </div>
                    <div class="flex flex-col col-span-3">
                        <label for="title" class="label-ptr"
                            >{{ $t("description_of_work_or_service") }}
                            <sup class="normal-case"
                                >max 300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.description"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(item.description, 300)
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col col-span-2">
                        <label for="title" class="label-ptr"
                            >{{ $t("justification") }}
                            <sup class="normal-case"
                                >max 300 {{ $t("characters") }}</sup
                            ></label
                        >
                        <textarea
                            rows="4"
                            class="sty-input"
                            maxlength="300"
                            v-model="item.justification"
                        ></textarea>
                        <small
                            >{{
                                store.remainingCharacters(
                                    item.justification,
                                    300
                                )
                            }}
                            {{ $t("characters_remaining") }}</small
                        >
                    </div>
                    <div class="flex flex-col">
                        <label for="title" class="label-ptr"
                            >{{ $t("value") }}
                        </label>
                        <input
                            type="text"
                            class="sty-input"
                            placeholder="R$"
                            v-model="item.value"
                            @input="onInput(index, $event)"
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div
                        class="w-48 btn-delete btn-default"
                        @click="deleteCostsExpenses(index)"
                    >
                        {{ $t("delete_expense") }}
                    </div>
                </div>
                <div class="my-4 separete"></div>
            </div>
            <div class="mt-4">
                <div class="w-48 btn-default" @click="addCostsExpenses">
                    {{ $t("add_expense") }}
                </div>
            </div>
            <div class="my-4 separete"></div>
            <div class="flex flex-col">
                <label for="title" class="label-ptr">{{
                    $t(
                        "total_value_in_expenses_related_to_direct_and_measurable_costs_of_RnD_activities"
                    )
                }}</label>
                <input
                    type="text"
                    class="sty-input"
                    placeholder="R$"
                    v-model="totalValue"
                    readonly
                />
            </div>
        </div>
        <div class="flex justify-between my-3">
            <div
                v-if="currentIndex > 0"
                @click="previousPage"
                class="my-1 btn-default"
            >
                {{ $t("previous") }}
            </div>
            <div
                @click="handleNextPage"
                class="my-1 btn-default btn-master"
                v-if="!loading"
            >
                {{ $t("next") + " | " + $t("save") }}
            </div>
            <div
                v-else
                class="my-1 btn-default btn-master inline-flex items-center relative"
            >
                {{ $t("saving") }}
                <div class="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from "@/store";
import axios from "axios";

export default {
    props: {
        nextPage: Function,
        previousPage: Function,
        currentIndex: Number,
    },
    data() {
        return {
            store,
            costs_expenses: [],
            totalValue: "",
            loading: false,
            authHeader: store.getAuthHeader(),
            ptrDatas: [],
        };
    },
    created() {
        let data = localStorage.getItem("ptrDatas")
            ? JSON.parse(localStorage.getItem("ptrDatas"))
            : [];
        if (data != null && data.hasOwnProperty("costs_expenses")) {
            this.costs_expenses = data.costs_expenses.map((re) => ({
                ...re,
                value: this.store.formatMoney(re.value),
            }));
            this.updateTotalValue();
        }
    },

    methods: {
        addCostsExpenses() {
            this.costs_expenses.push({
                company_name: "",
                description: "",
                justification: "",
                value: null,
            });
        },
        async handleNextPage() {
            this.loading = true;

            let parsedData = localStorage.getItem("ptrDatas")
                ? JSON.parse(localStorage.getItem("ptrDatas"))
                : {};
            parsedData.costs_expenses = this.costs_expenses.map((act) => {
                return {
                    ...act,
                    value: this.store.formatValueToSave(act.value),
                };
            });

            localStorage.setItem("ptrDatas", JSON.stringify(parsedData));
            setTimeout(() => {
                this.nextPage();
                this.loading = false;
            }, 1000);
        },
        onInput(index, event) {
            let formattedValue = this.store.formatCurrency(event.target.value);
            if (this.costs_expenses[index]) {
                this.costs_expenses[index].value = formattedValue;
            }
            this.updateTotalValue();
        },
        updateTotalValue() {
            let total = this.costs_expenses.reduce((sum, item) => {
                return sum + this.store.parseCurrency(item.value);
            }, 0);
            this.totalValue = this.store.formatCurrency(total.toString());
        },
        deleteCostsExpenses(index) {
            this.costs_expenses.splice(index, 1);
            this.updateTotalValue();
        },
    },
};
</script>
